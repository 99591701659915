import { AnyAction } from 'redux';
import { DashboardActionTypes } from '../../../features/dashboard/data-access/actions';
import { ImportFilesActionTypes } from '../../../features/import-files/data-access/actions';
import { OrderDataOutputActionTypes } from '../../../features/order-data-output/data-access/actions';
import { PurchaseLimitActionTypes } from '../../../features/purchase-limit/data-access/actions';
import { RebateAmountActionTypes } from '../../../features/rebate-amount/data-access/actions';
import { SettingAccessLogActionTypes } from '../../../features/setting-accesslog/data-access/actions';
import { SettingEventLogActionTypes } from '../../../features/setting-eventlog/data-access/actions';
import { ALERT_TYPE, MENU_HREF } from '../../constants';
import { guid, isEmpty } from '../../utils';
import { isOnline } from '../../utils/url-checker';
import { AuthActionTypes } from './../../auth';
import { NotificationActionTypes } from './actions';
import { NotificationState } from './states';
import { QrDocumentActionTypes } from '../../../features/qr-document/data-access/actions';
import { QrCodeActionTypes } from '../../../features/qr-code/data-access/actions';
import { QrAccessActionTypes } from '../../../features/qr-access/data-access/actions';
import { QrScanActionTypes } from '../../../features/qr-scan/data-access/actions';
import { QrDownloadActionTypes } from '../../../features/qr-download/data-access/actions';

const defaultState: NotificationState = {
  errors: {
    auth: {
      authorized: null,
      login: null,
      logout: null,
    },

    common: {
      error: null,
    },
  },
  messages: {
    auth: {
      registerDone: '',
    },

    common: {
      message: '',
    },
  },
  systemAlerts: [],
  isLoading: false,
  settingInfo: {
    version: '0.0.0',
    updatedDateTime: '',
    itemPerPage: 10,
    maxFileSize: 50,
  },
  fetchingDeleteMessage: false,
};

export const notificationReducer = (state = defaultState, action: AnyAction): NotificationState => {
  switch (action.type) {
    case AuthActionTypes.AUTHORIZED_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          auth: {
            ...state.errors.auth,
            authorized: {
              message: '提供された認証情報でログインできません。',
              reason: action.payload.error.toString(),
            },
          },
        },
      };
    case AuthActionTypes.LOGIN_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          auth: {
            ...state.errors.auth,
            login: {
              message: action.payload.error.response?.data?.detail || 'サーバーにログインできませんでした。',
              reason: action.payload.error.toString(),
            },
          },
        },
      };
    case AuthActionTypes.LOGOUT_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          auth: {
            ...state.errors.auth,
            logout: {
              message: 'サーバーからログアウトできませんでした。',
              reason: action.payload.error.toString(),
            },
          },
        },
      };
    case AuthActionTypes.REGISTER_SUCCESS: {
      if (!action.payload.user.isVerified) {
        return {
          ...state,
          messages: {
            ...state.messages,
            auth: {
              ...state.messages.auth,
              registerDone: `To use your account, you need to confirm the email address. \
                                 We have sent an email with a confirmation link to ${action.payload.user.email}.`,
            },
          },
        };
      }

      return {
        ...state,
      };
    }
    case AuthActionTypes.LOGOUT_SUCCESS:
      return { ...defaultState };

    case NotificationActionTypes.RESET_ERRORS:
      return {
        ...state,
        errors: {
          ...defaultState.errors,
        },
      };
    case NotificationActionTypes.SHOW_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          common: {
            error: {
              message: action.payload?.error,
              reason: '',
            },
          },
        },
      };
    case NotificationActionTypes.SHOW_INFO:
      return {
        ...state,
        messages: {
          ...state.messages,
          common: {
            message: action.payload?.info,
          },
        },
      };
    case NotificationActionTypes.RESET_MESSAGES:
      return {
        ...state,
        messages: {
          ...defaultState.messages,
        },
      };
    case NotificationActionTypes.SHOW_SYSTEM_ALERT:
      return {
        ...state,
        systemAlerts:
          action.payload.alert.sender === MENU_HREF.DASHBOARD
            ? [action.payload.alert, ...state.systemAlerts]
            : [...state.systemAlerts, action.payload.alert],
      };
    case NotificationActionTypes.CLOSE_SYSTEM_ALERT:
      return {
        ...state,
        systemAlerts: state.systemAlerts.filter((x: any) => x.messageId !== action.payload.id),
      };
    case NotificationActionTypes.RESET_SYSTEM_SUCCESS_ALERT:
      return {
        ...state,
        systemAlerts: isEmpty(action.payload.screen)
          ? []
          : state.systemAlerts.filter(
              (x) =>
                x.sender !== action.payload.sender ||
                (x.type !== ALERT_TYPE.INFORMATION && x.type !== ALERT_TYPE.SUCCESS),
            ),
      };
    case NotificationActionTypes.GET_SETTING_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settingInfo: action.payload.settingInfo,
      };
    case NotificationActionTypes.GET_SETTING_INFO:
    case NotificationActionTypes.GET_MESSAGES:
      return {
        ...state,
        fetchingDeleteMessage: false,
      };
    case NotificationActionTypes.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        systemAlerts: action.payload.message.results,
      };
    case NotificationActionTypes.DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        fetchingDeleteMessage: true,
      };
    case NotificationActionTypes.DELETE_MESSAGE_FAILURE:
      return {
        ...state,
        fetchingDeleteMessage: false,
      };
    case NotificationActionTypes.DOWNLOAD_LOG_FILE_FAILURE:
    case NotificationActionTypes.DOWNLOAD_TEMPLATE_FAILURE:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload.error,
            type: ALERT_TYPE.ERROR,
            sender: action.payload.sender,
            isLocal: true,
          },
        ],
      };

    case RebateAmountActionTypes.GET_IMPORT_LOGS:
    case PurchaseLimitActionTypes.GET_LOGS:
    case DashboardActionTypes.GET_LOGS:
    case SettingEventLogActionTypes.GET_LOGS:
    case RebateAmountActionTypes.GET_NUTRITION_LOGS:
    case RebateAmountActionTypes.GET_NUTRITION_RATES:
    case ImportFilesActionTypes.GET_LOGS:
    case PurchaseLimitActionTypes.GET_PURCHASE_LIMITS:
    case SettingAccessLogActionTypes.GET_LOGS:
    case QrDocumentActionTypes.GET_QR_DOCUMENTS:
    case QrDocumentActionTypes.DELETE_QR_DOCUMENT:
    case QrCodeActionTypes.GET_HOSPITAL_BROCHURE:
    case QrCodeActionTypes.REGISTER_HOSPITAL_BROCHURE:
    case QrCodeActionTypes.UPDATE_HOSPITAL_BROCHURE:
    case QrAccessActionTypes.GET_QR_ACCESS:
    case QrAccessActionTypes.GET_QR_ACCESS_CSV:
    case QrScanActionTypes.GET_JUMP_URL:
    case QrDownloadActionTypes.DOWNLOAD_QR:
    case QrCodeActionTypes.CHECK_PDF_FILE:
      return {
        ...state,
        isLoading: true,
      };

    case NotificationActionTypes.GET_SETTING_INFO_FAILURE:
    case RebateAmountActionTypes.GET_IMPORT_LOGS_FAILURE:
    case RebateAmountActionTypes.GET_IMPORT_LOGS_SUCCESS:
    case PurchaseLimitActionTypes.GET_LOGS_SUCCESS:
    case DashboardActionTypes.GET_LOGS_SUCCESS:
    case SettingEventLogActionTypes.GET_LOGS_SUCCESS:
    case RebateAmountActionTypes.GET_NUTRITION_LOGS_SUCCESS:
    case RebateAmountActionTypes.GET_NUTRITION_RATES_SUCCESS:
    case ImportFilesActionTypes.GET_LOGS_SUCCESS:
    case PurchaseLimitActionTypes.GET_PURCHASE_LIMITS_SUCCESS:
    case SettingAccessLogActionTypes.GET_LOGS_SUCCESS:
    case QrDocumentActionTypes.GET_QR_DOCUMENTS_SUCCESS:
    case QrCodeActionTypes.GET_HOSPITAL_BROCHURE_SUCCESS:
    case QrCodeActionTypes.GET_HOSPITAL_BROCHURE_FAILURE:
    case QrCodeActionTypes.REGISTER_HOSPITAL_BROCHURE_SUCCESS:
    case QrCodeActionTypes.REGISTER_HOSPITAL_BROCHURE_FAILURE:
    case QrCodeActionTypes.UPDATE_HOSPITAL_BROCHURE_SUCCESS:
    case QrCodeActionTypes.UPDATE_HOSPITAL_BROCHURE_FAILURE:
    case QrAccessActionTypes.GET_QR_ACCESS_SUCCESS:
    case QrAccessActionTypes.GET_QR_ACCESS_FAILURE:
    case QrAccessActionTypes.GET_QR_ACCESS_CSV_FAILURE:
    case QrAccessActionTypes.GET_QR_ACCESS_CSV_SUCCESS:
    case QrScanActionTypes.GET_JUMP_URL_FAILED:
    case QrScanActionTypes.GET_JUMP_URL_SUCCESS:
    case QrDownloadActionTypes.DOWNLOAD_QR_FAILED:
    case QrDownloadActionTypes.DOWNLOAD_QR_SUCCESS:
    case QrCodeActionTypes.CHECK_PDF_FILE_SUCCESS:
    case QrCodeActionTypes.CHECK_PDF_FILE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case SettingAccessLogActionTypes.EXPORT_LOG_SUCCESS:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: `ファイルエクスポートが完了し、${action.payload.row}行のデータを書き出しました。`,
            type: ALERT_TYPE.SUCCESS,
            sender: MENU_HREF.ACCESS_LOG,
            isLocal: true,
          },
        ],
      };
    case SettingAccessLogActionTypes.EXPORT_LOG_FAILURE:
    case SettingAccessLogActionTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload.error,
            type: ALERT_TYPE.ERROR,
            sender: MENU_HREF.ACCESS_LOG,
            isLocal: true,
          },
        ],
      };
    case SettingAccessLogActionTypes.EXPORT_LOG:
      return {
        ...state,
        systemAlerts: isOnline()
          ? [
              ...state.systemAlerts,
              {
                messageId: guid(),
                message: `ログ検索結果をファイルにエクスポート中です。エクスポートが終了するまで、再度エクスポートを実行しないでください。`,
                type: ALERT_TYPE.WARNING,
                sender: MENU_HREF.ACCESS_LOG,
                isLocal: true,
              },
            ]
          : state.systemAlerts,
      };
    case SettingEventLogActionTypes.EXPORT_LOG:
      return {
        ...state,
        systemAlerts: isOnline()
          ? [
              ...state.systemAlerts,
              {
                messageId: guid(),
                message: `ログ検索結果をファイルにエクスポート中です。エクスポートが終了するまで、再度エクスポートを実行しないでください。`,
                type: ALERT_TYPE.WARNING,
                sender: MENU_HREF.EVENT_LOG,
                isLocal: true,
              },
            ]
          : state.systemAlerts,
      };
    case SettingEventLogActionTypes.EXPORT_LOG_SUCCESS:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: `ファイルエクスポートが完了し、${action.payload.row}行のデータを書き出しました。`,
            type: ALERT_TYPE.SUCCESS,
            sender: MENU_HREF.EVENT_LOG,
            isLocal: true,
          },
        ],
      };
    case SettingEventLogActionTypes.EXPORT_LOG_FAILURE:
    case SettingEventLogActionTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload.error,
            type: ALERT_TYPE.ERROR,
            sender: MENU_HREF.EVENT_LOG,
            isLocal: true,
          },
        ],
        isLoading: false,
      };

    case ImportFilesActionTypes.IMPORT_INVENTORY_SUCCESS:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload?.message
              ? action.payload?.message
              : '在庫情報ファイルを読み込み中です。作業完了まで一覧ファイルの再読み込みは行わないでください。',
            type: ALERT_TYPE.WARNING,
            sender: MENU_HREF.IMPORT,
            isLocal: true,
          },
        ],
      };
    case ImportFilesActionTypes.IMPORT_PRODUCT_SUCCESS:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload?.message
              ? action.payload?.message
              : '商品体系４情報ファイルを読み込み中です。作業完了まで一覧ファイルの再読み込みは行わないでください。',
            type: ALERT_TYPE.WARNING,
            sender: MENU_HREF.IMPORT,
            isLocal: true,
          },
        ],
      };
    case ImportFilesActionTypes.IMPORT_BUSINESS_CALENDAR_SUCCESS:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload?.message
              ? action.payload?.message
              : '営業日カレンダーファイルを読み込み中です。作業完了まで一覧ファイルの再読み込みは行わないでください。',
            type: ALERT_TYPE.WARNING,
            sender: MENU_HREF.IMPORT,
            isLocal: true,
          },
        ],
      };
    case ImportFilesActionTypes.IMPORT_PRODUCT_FAILURE:
    case ImportFilesActionTypes.IMPORT_INVENTORY_FAILURE:
    case ImportFilesActionTypes.IMPORT_BUSINESS_CALENDAR_FAILURE:
    case ImportFilesActionTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload.error,
            type: ALERT_TYPE.ERROR,
            sender: MENU_HREF.IMPORT,
            isLocal: true,
          },
        ],
        isLoading: false,
      };

    case OrderDataOutputActionTypes.EXPORT_ORDER_SUCCESS:
      if (action.payload.data === undefined) {
        return {
          ...state,
          systemAlerts: [
            ...state.systemAlerts,
            {
              messageId: guid(),
              message: action.payload?.message
                ? action.payload?.message
                : '注文データ連携ファイルを書き込み中です。書き出しが終了するまで、再度書き出しを実行しないでください。',
              type: ALERT_TYPE.WARNING,
              sender: MENU_HREF.EXPORT,
              isLocal: true,
            },
          ],
        };
      } else {
        return state;
      }
    case OrderDataOutputActionTypes.EXPORT_REBATE: {
      return {
        ...state,
        systemAlerts: isOnline()
          ? [
              ...state.systemAlerts,
              {
                messageId: guid(),
                message: `栄養指導料支払いファイルを書き込み中です。書き出しが終了するまで、再度書き出しを実行しないでください。`,
                type: ALERT_TYPE.WARNING,
                sender: MENU_HREF.EXPORT,
                isLocal: true,
              },
            ]
          : state.systemAlerts,
        isLoading: false,
      };
    }
    case OrderDataOutputActionTypes.EXPORT_ORDER_FAILURE:
    case OrderDataOutputActionTypes.EXPORT_REBATE_FAILURE:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload.error,
            type: ALERT_TYPE.ERROR,
            sender: MENU_HREF.EXPORT,
            isLocal: true,
          },
        ],
        isLoading: false,
      };

    case PurchaseLimitActionTypes.IMPORT_PURCHASE_LIMIT_SETTING_SUCCESS:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload?.message
              ? action.payload?.message
              : `受注上限値設定ファイルを読み込み中です。作業完了まで設定ファイルの再読み込みは行わないでください。`,
            type: ALERT_TYPE.WARNING,
            sender: MENU_HREF.PURCHASE_LIMIT,
            isLocal: true,
          },
        ],
        isLoading: false,
      };
    case PurchaseLimitActionTypes.EXPORT_PURCHASE_LIMIT_SUCCESS:
      if (action.payload.message !== 'duplicated') {
        return {
          ...state,
          systemAlerts: [
            ...state.systemAlerts,
            {
              messageId: guid(),
              message: action.payload?.message
                ? action.payload?.message
                : `ファイルエクスポートが完了し、${action.payload.row}行のデータを書き出しました。`,
              type: action.payload?.message ? ALERT_TYPE.WARNING : ALERT_TYPE.SUCCESS,
              sender: MENU_HREF.PURCHASE_LIMIT,
              isLocal: true,
            },
          ],
          isLoading: false,
        };
      } else {
        return state;
      }
    case PurchaseLimitActionTypes.EXPORT_PURCHASE_LIMIT_FAILURE:
    case PurchaseLimitActionTypes.IMPORT_PURCHASE_LIMIT_SETTING_FAILURE:
    case PurchaseLimitActionTypes.GET_LOGS_FAILURE:
    case PurchaseLimitActionTypes.GET_MASTER_INFO_FAILURE:
    case PurchaseLimitActionTypes.GET_PURCHASE_LIMITS_FAILURE:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload.error,
            type: ALERT_TYPE.ERROR,
            sender: MENU_HREF.PURCHASE_LIMIT,
            isLocal: true,
          },
        ],
        isLoading: false,
      };

    case RebateAmountActionTypes.IMPORT_REBATE_AMOUNT_SUCCESS:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload?.message
              ? action.payload?.message
              : `栄養指導料率一覧ファイルを読み込み中です。作業完了まで一覧ファイルの再読み込みは行わないでください。`,
            type: ALERT_TYPE.WARNING,
            sender: MENU_HREF.REBATE_AMOUNT,
            isLocal: true,
          },
        ],
        isLoading: false,
      };
    case RebateAmountActionTypes.EXPORT_REBATE_SUCCESS:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload?.message
              ? action.payload?.message
              : `栄養指導料率設定情報のファイル書き出しが完了し、${action.payload.row}行のデータを書き出しました。`,
            type: action.payload?.message ? ALERT_TYPE.WARNING : ALERT_TYPE.SUCCESS,
            sender: MENU_HREF.REBATE_AMOUNT,
            isLocal: true,
          },
        ],
        isLoading: false,
      };
    case RebateAmountActionTypes.EXPORT_REBATE_FAILURE:
    case RebateAmountActionTypes.IMPORT_REBATE_AMOUNT_FAILURE:
    case RebateAmountActionTypes.GET_NUTRITION_LOGS_FAILURE:
    case RebateAmountActionTypes.GET_NUTRITION_RATES_FAILURE:
    case RebateAmountActionTypes.GET_MASTER_INFO_FAILURE:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload.error,
            type: ALERT_TYPE.ERROR,
            sender: MENU_HREF.REBATE_AMOUNT,
            isLocal: true,
          },
        ],
        isLoading: false,
      };

    case DashboardActionTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload.error,
            type: ALERT_TYPE.ERROR,
            sender: MENU_HREF.DASHBOARD,
            isLocal: true,
          },
        ],
        isLoading: false,
      };

    case QrDocumentActionTypes.GET_QR_DOCUMENTS_FAILURE:
    case QrDocumentActionTypes.DELETE_QR_DOCUMENT_FAILURE:
      return {
        ...state,
        systemAlerts: [
          ...state.systemAlerts,
          {
            messageId: guid(),
            message: action.payload.error,
            type: ALERT_TYPE.ERROR,
            sender: MENU_HREF.QR_DOCUMENT,
            isLocal: true,
          },
        ],
        isLoading: false,
      };

    default:
      return state;
  }
};
