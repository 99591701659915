import { AxiosRequestConfig } from 'axios';

export const axiosRequestConfiguration: AxiosRequestConfig = {
  proxy: false,
  responseType: 'json',
};

export const config = {
  baseApiUrl:
    typeof process.env['REACT_APP_API_BASE_URL'] === 'undefined'
      ? 'lsc_ext_api/api'
      : `${process.env['REACT_APP_API_BASE_URL']}/api`,
  socketUrl:
    typeof process.env['REACT_APP_SOCKET_URL'] === 'undefined'
      ? 'ws://localhost:9000/lsc_ext_batch/ws/receive-notification'
      : `${process.env['REACT_APP_SOCKET_URL']}/ws/receive-notification`,
};

export const KS_LSC_EXT_AUTH_TOKEN = 'KS_LSC_EXT_AUTH_TOKEN';
