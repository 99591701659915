import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ErrorIcon,
  ExportButton,
  PaginationControl,
  SearchIcon,
  SearchInputControl,
  SortIcon,
} from '../../../common';
import {
  CombinedState,
  EXPORT_PURCHASE_TYPE,
  isEmpty,
  MENU_HREF,
  numberWithCommas,
  PurchaseLimit,
  SearchCondition,
  SORT_TYPE,
} from '../../../core';
import { exportPurchaseLimitAsync, getPurchaseLimitsAsync } from '../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowExist: {
      color: (theme.palette.common as any).color.primary,
    },
    textTitle: {
      fontSize: '1.5rem',
    },
    errorCondition: {
      color: (theme.palette.common as any).color.textError,
    },
    buttonOutlined: {
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    container: {
      minHeight: '25em',
    },
    tableRoot: {
      width: '100%',
      minWidth: '78em',
      maxWidth: '95em',
      tableLayout: 'fixed',
    },
    sortIcon: {
      color: (theme.palette.common as any).color.primary,
    },
    customSearch: {
      width: '25%',
    },
    error: {
      color: (theme.palette.common as any).color.textError,
    },
  }),
);

export const PurchaseLimitStatusComponent = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { purchaseLimitPagination, exportPurchaseLimitFetching, purchaseLimitDuplicated, masterInfo, fetching } =
    useSelector((state: CombinedState) => state['purchaseLimit']);
  const ITEM_PER_PAGE = 10;
  const searchState = useMemo(() => (state as any)?.searchCondition || {}, [state]);
  const [page, setPage] = useState(1);
  const [sortSaleType, setSortSaleType] = useState<string>(SORT_TYPE.ASCENDING);
  const [sortPurchaseLimitType, setSortPurchaseLimitType] = useState<string>(SORT_TYPE.ASCENDING);
  const [sortPurchaseFlagType, setSortPurchaseFlagType] = useState<string>(SORT_TYPE.ASCENDING);
  const [search, setSearch] = useState<string>('');
  const [isChangeCount, setIsChangeCount] = useState<Boolean>(true);

  const onSubmit = () => {
    setPage(1);
    const condition = new SearchCondition();
    condition.filter = {
      ...searchState,
      page: 1,
      vicsCode: search,
    };

    navigate(MENU_HREF.PURCHASE_LIMIT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    setIsChangeCount(true);
    dispatch(getPurchaseLimitsAsync(condition));
  };

  const onSort = (sortItem: string) => {
    let sortType = '';
    switch (sortItem) {
      case 'sales_limit_setting_amount':
        sortType = sortSaleType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        sortItem = sortSaleType === SORT_TYPE.ASCENDING ? `-${sortItem}` : sortItem;
        setSortSaleType(sortType);
        break;
      case 'purchase_limit_flag':
        sortType = sortPurchaseFlagType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        sortItem = sortPurchaseFlagType === SORT_TYPE.ASCENDING ? `-${sortItem}` : sortItem;
        setSortPurchaseFlagType(sortType);
        break;
      case 'purchase_limit_total':
        sortType = sortPurchaseLimitType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        sortItem = sortPurchaseLimitType === SORT_TYPE.ASCENDING ? `-${sortItem}` : sortItem;
        setSortPurchaseLimitType(sortType);
        break;
    }

    setPage(1);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: 1, ordering: sortItem };

    navigate(MENU_HREF.PURCHASE_LIMIT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    setIsChangeCount(false);
    dispatch(getPurchaseLimitsAsync(condition));
  };

  const onChangePage = (page: number) => {
    setPage(page);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: page };

    navigate(MENU_HREF.PURCHASE_LIMIT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    setIsChangeCount(false);
    dispatch(getPurchaseLimitsAsync(condition));
  };

  const onChange = (text: string) => {
    setSearch(text);
  };

  const hasSearchCriteria = () => {
    return !isEmpty(searchState?.vicsCode);
  };

  const onExport = () => {
    dispatch(exportPurchaseLimitAsync(EXPORT_PURCHASE_TYPE.EX_PURCHASE_LIMIT, searchState?.vicsCode));
  };

  useEffect(() => {
    const condition = new SearchCondition();
    condition.filter = { ...condition.filter, pageSize: ITEM_PER_PAGE };
    if (!isEmpty(searchState)) {
      condition.filter = searchState;
    }

    navigate(MENU_HREF.PURCHASE_LIMIT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    dispatch(getPurchaseLimitsAsync(condition));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(searchState)) {
      switch (searchState.ordering) {
        case 'sales_limit_setting_amount':
          setSortSaleType(SORT_TYPE.ASCENDING);
          break;
        case '-sales_limit_setting_amount':
          setSortSaleType(SORT_TYPE.DESCENDING);
          break;
        case 'purchase_limit_total':
          setSortPurchaseLimitType(SORT_TYPE.ASCENDING);
          break;
        case '-purchase_limit_total':
          setSortPurchaseLimitType(SORT_TYPE.DESCENDING);
          break;
        case 'purchase_limit_flag':
          setSortPurchaseFlagType(SORT_TYPE.ASCENDING);
          break;
        case '-purchase_limit_flag':
          setSortPurchaseFlagType(SORT_TYPE.DESCENDING);
          break;
      }
    }
    setSearch(searchState?.vicsCode ?? '');
  }, [searchState]);

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Box display='flex' flexDirection='column'>
          <Typography>
            購入状況確認では、購入状況のエクスポートや病院ごとに購入上限に到達したかの確認が可能です。
          </Typography>
          <Typography>
            購入上限値計算日時：{masterInfo?.updatedDatetime ? masterInfo?.updatedDatetime : 'なし'}
          </Typography>
        </Box>
      </Stack>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Box display='flex' alignItems='center'>
            <SearchIcon fontSize='medium' viewBox={'0 0 16 16'} />
            <Typography ml={1} variant='h4' className={classes.textTitle}>
              購入状況確認
            </Typography>
          </Box>

          <Box display='flex' flexDirection='column' px={3}>
            <SearchInputControl
              customClass={classes.customSearch}
              placeholder='動物病院コード（VICS）'
              onSearch={onSubmit}
              onInputChange={onChange}
              inputTextValue={search}
            />
            {(!isChangeCount || !fetching) && hasSearchCriteria() && (
              <>
                {purchaseLimitPagination.count === 0 ? (
                  <Typography className={classes.errorCondition}>
                    指定された条件ではデータが存在しませんでした。
                  </Typography>
                ) : (
                  <Typography className={classes.rowExist}>
                    {purchaseLimitPagination.count}件のデータが存在しました。
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Stack>
        <TableContainer component={Box} px={3} classes={{ root: classes.container }}>
          <Table classes={{ root: classes.tableRoot }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width='20%' align='left'>
                  動物病院
                </TableCell>
                <TableCell align='left'>商品</TableCell>
                <TableCell width='180px'>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <Typography className='lsc-table-header' component='span'>
                      設定上限値（円）
                    </Typography>
                    <IconButton className={classes.sortIcon} onClick={() => onSort('sales_limit_setting_amount')}>
                      <SortIcon color='inherit' type={sortSaleType} viewBox={'0 0 16 16'} />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell width='180px'>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <Typography className='lsc-table-header' component='span'>
                      購入額（円）
                    </Typography>
                    <IconButton className={classes.sortIcon} onClick={() => onSort('purchase_limit_total')}>
                      <SortIcon color='inherit' type={sortPurchaseLimitType} viewBox={'0 0 16 16'} />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell width='180px'>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <Typography className='lsc-table-header' component='span'>
                      購入可否状況
                    </Typography>
                    <IconButton className={classes.sortIcon} onClick={() => onSort('purchase_limit_flag')}>
                      <SortIcon color='inherit' type={sortPurchaseFlagType} viewBox={'0 0 16 16'} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {purchaseLimitPagination.results?.map((row: PurchaseLimit) => (
                <TableRow hover key={row.purchaseLimitId}>
                  <TableCell align='left'>
                    {row?.vicsCode ? `${row.vicsCode} ${row.hospitalName ?? ''}` : row.hospitalName ?? ''}
                  </TableCell>
                  <TableCell align='left'>
                    {row?.ksGoodsCode ? `${row.ksGoodsCode} ${row.goodsName ?? ''}` : row.goodsName ?? ''}
                  </TableCell>
                  <TableCell align='right'>{numberWithCommas(row?.salesLimitSettingAmount)}</TableCell>
                  <TableCell align='right'>{numberWithCommas(row?.purchaseLimitTotal)}</TableCell>
                  <TableCell align='center'>
                    {!!row.purchaseLimitFlag === true ? (
                      <ErrorIcon color='error' fontSize='small' viewBox={'0 0 16 16'} />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box mt={2}>
            {purchaseLimitPagination.count === 0 && <Typography>検索結果のデータが表示されます。</Typography>}
            <PaginationControl
              totalRecord={purchaseLimitPagination.count}
              currentPage={page}
              itemPerPage={ITEM_PER_PAGE}
              onChange={onChangePage}
            />
          </Box>
          {purchaseLimitPagination.count !== 0 && (
            <Box mt={2}>
              <ExportButton
                variant='outlined'
                disabled={!hasSearchCriteria() || exportPurchaseLimitFetching}
                onClick={onExport}
                classes={{ outlined: classes.buttonOutlined }}
                loading={exportPurchaseLimitFetching}
              >
                CSV エクスポート
              </ExportButton>
              {!exportPurchaseLimitFetching && purchaseLimitDuplicated && (
                <Typography className={classes.error}>
                  受注上限値設定ファイルの読み込み中のためエクスポートできません。
                </Typography>
              )}
            </Box>
          )}
        </TableContainer>
      </Stack>
    </Stack>
  );
};
