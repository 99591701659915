import { useRef, useEffect, useLayoutEffect } from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';
import { validatePosition } from '../../../core';

const Rectangle = (props: any) => {
  const { shapeProps, isSelected, onSelect, onChange } = props;

  const [image] = useImage('data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAPYAAAD2AQAAAADNaUdlAAABnUlEQVR4nO2Y3Q2DMAyELWUARsrqGYkBIrn2nYGgoj7bFagKNF9eLPv8E9Gfz5SXv/zPufjT5zZs5Xfb/dWKcHvr3lUHTmnbjXfu1+C72IYZpDBLtoG/vRaHlULPaEGufmQ0hNuTfYk54sv04SHmKhF9iL+8/ND0+vvWf15+S6huJSy+8mt+boJ2cRukr2BZr8Kbh5Wu+ckP7lKFuzg8mgZLRIOhCq2X4F6ZbcNyElbzjNzsS8/hFawWU+Ec81grwhliE8aBjFBMER7K2DT0jYq35KfsnJmJMQWVaNhahbPEwS2HrI+DFbjALS36o8ESzc0anDlV4tvdhUGnCp8bf6NFiUZ+uvrTApw9HczqLBRslEpwJlc7pdC3nYI+tAxHfyESK3vtucz32bme/YUyP2nIvQbnTEYv0Tik29M/2TmfkPVR2eTSR3aO9i7Mcl9xvl/sT87P+X7K7ZaiDOf90HEthyiTxT9lOOuDJVqvDw/2peZeooUjmmfWds2X6bmeI7KXOI6YPFWCU9/oMnDRiOuKiLUK/Mfz8pf/Nf8AKSpo6AlNsA0AAAAASUVORK5CYII=');

  const shapeRef = useRef<any>();
  const trRef = useRef<any>();

  useEffect(() => {
    if (isSelected) {
      // shapeRef.current.cache();
      // we need to attach transformer manually
      trRef?.current?.setNode(shapeRef.current);
      trRef?.current?.getLayer().batchDraw();
    }
  }, [isSelected]);

  useLayoutEffect(() => {
    if (shapeRef.current) {
      shapeRef.current.x(validatePosition(shapeProps.pdfWidth, shapeProps.width, shapeRef.current.attrs.x));
      shapeRef.current.y(validatePosition(shapeProps.pdfHeight, shapeProps.height, shapeRef.current.attrs.y));
      shapeRef.current.cache();
    }
  }, [shapeProps, image, isSelected]);

  return (
    <>
      <Image
        image={image}
        onClick={onSelect}
        ref={shapeRef}
        blurRadius={10}
        {...shapeProps}
        draggable
        onDragEnd={(e: any) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e: any) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          node.width(Math.max(5, node.width() * scaleX));
          node.height(Math.max(node.height() * scaleY));

          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: node.width(),
            height: node.height(),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox: any, newBox: any) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          rotateEnabled={false}
        />
      )}
    </>
  );
};

export default Rectangle;
