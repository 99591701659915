import { OrderDataOutputActions, OrderDataOutputActionTypes } from './actions';
import { OrderDataOutputState } from './states';

const defaultState: OrderDataOutputState = {
  exportOrderFetching: false,
  orderData: undefined,
  exportRebateFetching: false,
  rebateData: undefined,
  nutritionHistory: []
};

export const orderDataOutPutReducer = (state = defaultState, action: OrderDataOutputActions): OrderDataOutputState => {
  switch (action.type) {
    case OrderDataOutputActionTypes.EXPORT_ORDER:
      return {
        ...state,
        exportOrderFetching: true,
        orderData: undefined,
      };
    case OrderDataOutputActionTypes.EXPORT_ORDER_SUCCESS:
      return {
        ...state,
        exportOrderFetching: false,
        orderData: action.payload.data,
      };
    case OrderDataOutputActionTypes.EXPORT_ORDER_FAILURE:
      return {
        ...state,
        exportOrderFetching: false,
      };

    case OrderDataOutputActionTypes.EXPORT_REBATE:
      return {
        ...state,
        exportRebateFetching: true,
        rebateData: undefined,
      };
    case OrderDataOutputActionTypes.EXPORT_REBATE_SUCCESS:
      return {
        ...state,
        exportRebateFetching: false,
        rebateData: action.payload.data,
      };
    case OrderDataOutputActionTypes.EXPORT_REBATE_FAILURE:
      return {
        ...state,
        exportRebateFetching: false,
      };

    case OrderDataOutputActionTypes.GET_NUTRITION_HISTORY_SUCCESS:
      return {
        ...state,
        nutritionHistory: action.payload.data
      }

    default:
      return state;
  }
};
