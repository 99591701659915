import { apiService } from '../../../core';

export const downloadQr = async (hbId: string, vicsCode: string) => {
  return await apiService.getFile<any>(
    '/qr/download',
    { hbId, vicsCode },
    {
      responseType: 'blob',
    },
  );
};
