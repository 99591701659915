import { ActionUnion, createAction, getApiError, ThunkAction } from '../../../core';
import { getJumpUrl } from '../service/service';

export enum QrScanActionTypes {
  GET_JUMP_URL = 'GET_JUMP_URL',
  GET_JUMP_URL_SUCCESS = 'GET_JUMP_URL_SUCCESS',
  GET_JUMP_URL_FAILED = 'GET_JUMP_URL_FAILED',
}

export const qrScanActions = {
  getJumpUrl: () => createAction(QrScanActionTypes.GET_JUMP_URL),
  getJumpUrlSuccess: (data: any) => createAction(QrScanActionTypes.GET_JUMP_URL_SUCCESS, { data }),
  getJumpUrlFailed: (error: any) =>
    createAction(QrScanActionTypes.GET_JUMP_URL_FAILED, {
      error,
    }),
};

export type QrScanActions = ActionUnion<typeof qrScanActions>;

export const getJumpUrlAsync = (hbHistoryId: string): ThunkAction => {
  return async (dispatch) => {
    dispatch(qrScanActions.getJumpUrl());
    try {
      const response: any = await getJumpUrl(hbHistoryId);
      dispatch(qrScanActions.getJumpUrlSuccess(response?.data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrScanActions.getJumpUrlFailed(errorText));
    }
  };
};
