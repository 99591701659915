import { Stack, Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CombinedState, EXPORT_REBATE_TYPE, isEmpty, MENU_HREF, SearchCondition } from '../../../../core';
import { exportRebateAsync, getNutritionRatesAsync } from '../../data-access/actions';
import { ResultTableComponent } from './result-table';
import { SearchFormComponent } from './search-form';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ExportButton, PaginationControl } from '../../../../common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonOutlined: {
      marginTop: '1em',
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
  }),
);

export const SearchNutritionComponent = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { nutritionRates, exportRebateFetching } = useSelector((state: CombinedState) => state['rebateAmount']);
  const ITEM_PER_PAGE = 10;
  const searchState = useMemo(() => (state as any)?.searchCondition || {}, [state]);
  const [page, setPage] = useState(1);
  const [isChangeCount, setIsChangeCount] = useState<Boolean>(true);

  const onSubmit = (value: any) => {
    setPage(1);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: 1, ...value };

    navigate(MENU_HREF.REBATE_AMOUNT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    setIsChangeCount(true);
    dispatch(getNutritionRatesAsync(condition));
  };

  const onSort = (sortItem: string) => {
    setPage(1);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: 1, ordering: sortItem };

    navigate(MENU_HREF.REBATE_AMOUNT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    setIsChangeCount(false);
    dispatch(getNutritionRatesAsync(condition));
  };

  const onChangePage = (page: number) => {
    setPage(page);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: page };

    navigate(MENU_HREF.REBATE_AMOUNT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    setIsChangeCount(false);
    dispatch(getNutritionRatesAsync(condition));
  };

  const onExport = () => {
    const condition = new SearchCondition();
    condition.filter = { ...searchState, type: EXPORT_REBATE_TYPE.EX_ADVISOR_FEE_RATE };
    dispatch(exportRebateAsync(condition));
  };

  useEffect(() => {
    const condition = new SearchCondition();
    condition.filter = { ...condition.filter, pageSize: ITEM_PER_PAGE };
    if (!isEmpty(searchState)) {
      condition.filter = searchState;
    } else {
      const ordering = '';
      condition.filter = { ...condition.filter, ordering };
    }

    navigate(MENU_HREF.REBATE_AMOUNT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    dispatch(getNutritionRatesAsync(condition));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Stack spacing={1.5}>
      <SearchFormComponent searchState={searchState} onSubmit={onSubmit} isChangeCount={isChangeCount} />
      <Stack>
        <ResultTableComponent ordering={searchState.ordering} onSort={onSort} />
        <PaginationControl
          totalRecord={nutritionRates.count}
          currentPage={page}
          itemPerPage={ITEM_PER_PAGE}
          onChange={onChangePage}
        />
        {nutritionRates.count > 0 && (
          <Box>
            <ExportButton
              variant='outlined'
              disabled={exportRebateFetching}
              classes={{ outlined: classes.buttonOutlined }}
              onClick={onExport}
              loading={exportRebateFetching}
            >
              エクスポート
            </ExportButton>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
