import { Box, CssBaseline, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Loading, SocketContainer, TopButtonControl } from '../controls';
import { AlertContainer } from '../controls/alert-component';
import { FooterComponent } from '../footer/footer';
import DashboardNavbar from '../nav-bar/nav-bar';
import EcvoNavbar from '../nav-bar/ecvo-nav-bar';
import { isEmpty } from 'lodash';
import { MENU_HREF } from '../../core';

interface Props {
  children?: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchToProps {}

export const MainLayout = (props: Props & DispatchToProps) => {
  const { user } = props;
  const [userInfo, setUserInfo] = useState(user);
  const location = useLocation();

  const { pathname } = useLocation();

  const isQrUrl =
    !isEmpty(matchPath(MENU_HREF.QR_DOCUMENT, pathname)) ||
    !isEmpty(matchPath(MENU_HREF.QR_ACCESS_COUNT, pathname)) ||
    !isEmpty(matchPath(MENU_HREF.QR_CODE, pathname)) ||
    !isEmpty(matchPath(MENU_HREF.QR_CODE_EDIT, pathname));

  const isNoNavUrl =
    !isEmpty(matchPath(MENU_HREF.QR_SCAN, pathname)) || !isEmpty(matchPath(MENU_HREF.QR_DOWNLOAD, pathname));

  useEffect(() => {
    setUserInfo(user);
  }, [user]);

  return (
    <Box display='flex' flexDirection='column'>
      <CssBaseline />
      {isQrUrl ? <EcvoNavbar /> : <>{user?.isActive && !isNoNavUrl && <DashboardNavbar user={userInfo} />}</>}
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: user ? 3 : 0,
          minHeight: 'calc(100vh - 52px)',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {(user || location.pathname !== '/') && <Toolbar />}
        {user && (
          <>
            {!isQrUrl && <SocketContainer />}
            <AlertContainer />
          </>
        )}
        {props.children}
        <Loading />
      </Box>
      <TopButtonControl />
      <FooterComponent />
    </Box>
  );
};
