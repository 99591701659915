import { apiService, EXPORT_PURCHASE_TYPE, PurchaseLimitPagination, SearchCondition } from '../../../core';

export const getPurchaseLimits = async (condition: SearchCondition): Promise<PurchaseLimitPagination> => {
  return await apiService.get<PurchaseLimitPagination>('/purchaseLimits', { ...condition.filter });
};

export const exportFile = async (type: EXPORT_PURCHASE_TYPE, search?: String) => {
  return await apiService.getFile<any>('/export/purchase', { type, search }, { responseType: 'blob' });
};

export const importFile = async (url: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return await apiService.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
