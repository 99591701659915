import React, { useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { PeopleIcon } from '../../../common';
import { ListComponent, SearchComponent } from '../component';

const useStyles = makeStyles(() =>
  createStyles({
    textHeader: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
  }),
);

const QrAccessComponent = () => {
  const classes = useStyles();

  const [isSearch, setIsSearch] = useState(false);

  return (
    <Grid container paddingX={2} spacing={3}>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' mt={4}>
          <PeopleIcon fontSize='small' viewBox={'0 0 16 16'} />
          <Typography ml={1} variant='h2' className={classes.textHeader}>
            資料集計
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SearchComponent isSearch={isSearch} setIsSearch={setIsSearch} />
      </Grid>
      <Grid item xs={12}>
        {isSearch && <ListComponent></ListComponent>}
      </Grid>
    </Grid>
  );
};

export const QrAccessPage = React.memo(QrAccessComponent);
