import saveAs from 'file-saver';
import { getLogs, getSetting } from '../../../common';
import {
  ActionUnion,
  createAction,
  EXPORT_PURCHASE_TYPE,
  getApiError,
  getApiSuccess,
  getFileName,
  isEmpty,
  LogInfo,
  MASTER_TYPE,
  PurchaseLimitPagination,
  SearchCondition,
  SettingInfo,
  ThunkAction,
} from '../../../core';
import { exportFile, getPurchaseLimits, importFile } from '../service/services';

export enum PurchaseLimitActionTypes {
  GET_LOGS = 'PURCHASE_LIMIT_GET_LOGS',
  GET_LOGS_SUCCESS = 'PURCHASE_LIMIT_GET_LOGS_SUCCESS',
  GET_LOGS_FAILURE = 'PURCHASE_LIMIT_GET_LOGS_FAILURE',

  GET_PURCHASE_LIMITS = 'PURCHASE_LIMIT_GET_PURCHASE_LIMITS',
  GET_PURCHASE_LIMITS_SUCCESS = 'PURCHASE_LIMIT_GET_PURCHASE_LIMITS_SUCCESS',
  GET_PURCHASE_LIMITS_FAILURE = 'PURCHASE_LIMIT_GET_PURCHASE_LIMITS_FAILURE',

  GET_MASTER_INFO = 'PURCHASE_LIMIT_GET_MASTER_INFO',
  GET_MASTER_INFO_SUCCESS = 'PURCHASE_LIMIT_GET_MASTER_INFO_SUCCESS',
  GET_MASTER_INFO_FAILURE = 'PURCHASE_LIMIT_GET_MASTER_INFO_FAILURE',

  EXPORT_PURCHASE_LIMIT = 'PURCHASE_LIMIT_EXPORT_PURCHASE_LIMIT',
  EXPORT_PURCHASE_LIMIT_SUCCESS = 'PURCHASE_LIMIT_EXPORT_PURCHASE_LIMIT_SUCCESS',
  EXPORT_PURCHASE_LIMIT_FAILURE = 'PURCHASE_LIMIT_EXPORT_PURCHASE_LIMIT_FAILURE',

  IMPORT_PURCHASE_LIMIT_SETTING = 'PURCHASE_LIMIT_IMPORT_PURCHASE_LIMIT_SETTING',
  IMPORT_PURCHASE_LIMIT_SETTING_SUCCESS = 'PURCHASE_LIMIT_IMPORT_PURCHASE_LIMIT_SETTING_SUCCESS',
  IMPORT_PURCHASE_LIMIT_SETTING_FAILURE = 'PURCHASE_LIMIT_IMPORT_PURCHASE_LIMIT_SETTING_FAILURE',
}

export const purchaseLimitActions = {
  getPurchaseLimitLogs: () => createAction(PurchaseLimitActionTypes.GET_LOGS),
  getPurchaseLimitLogsSuccess: (data: LogInfo[]) => createAction(PurchaseLimitActionTypes.GET_LOGS_SUCCESS, { data }),
  getPurchaseLimitLogsFailure: (error: any) =>
    createAction(PurchaseLimitActionTypes.GET_LOGS_FAILURE, {
      error,
    }),

  getPurchaseLimits: () => createAction(PurchaseLimitActionTypes.GET_PURCHASE_LIMITS),
  getPurchaseLimitsSuccess: (data: PurchaseLimitPagination) =>
    createAction(PurchaseLimitActionTypes.GET_PURCHASE_LIMITS_SUCCESS, { data }),
  getPurchaseLimitsFailure: (error: any) =>
    createAction(PurchaseLimitActionTypes.GET_PURCHASE_LIMITS_FAILURE, {
      error,
    }),

  exportPurchaseLimit: (type: EXPORT_PURCHASE_TYPE, search?: String) =>
    createAction(PurchaseLimitActionTypes.EXPORT_PURCHASE_LIMIT, { type, search }),
  exportPurchaseLimitSuccess: (row: number, type: EXPORT_PURCHASE_TYPE, message?: string) =>
    createAction(PurchaseLimitActionTypes.EXPORT_PURCHASE_LIMIT_SUCCESS, { row, type, message }),
  exportPurchaseLimitFailure: (error: any, type: EXPORT_PURCHASE_TYPE) =>
    createAction(PurchaseLimitActionTypes.EXPORT_PURCHASE_LIMIT_FAILURE, {
      error,
      type,
    }),

  getMasterData: () => createAction(PurchaseLimitActionTypes.GET_MASTER_INFO),
  getMasterDataSuccess: (masterInfo: SettingInfo) =>
    createAction(PurchaseLimitActionTypes.GET_MASTER_INFO_SUCCESS, { masterInfo }),
  getMasterDataFailure: (error: any) =>
    createAction(PurchaseLimitActionTypes.GET_MASTER_INFO_FAILURE, {
      error,
    }),

  importPurchaseLimitSetting: () => createAction(PurchaseLimitActionTypes.IMPORT_PURCHASE_LIMIT_SETTING),
  importPurchaseLimitSettingSuccess: (message?: string) =>
    createAction(PurchaseLimitActionTypes.IMPORT_PURCHASE_LIMIT_SETTING_SUCCESS, { message }),
  importPurchaseLimitSettingFailure: (error: any) =>
    createAction(PurchaseLimitActionTypes.IMPORT_PURCHASE_LIMIT_SETTING_FAILURE, {
      error,
    }),
};

export type PurchaseLimitActions = ActionUnion<typeof purchaseLimitActions>;

export const getPurchaseLimitLogsAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(purchaseLimitActions.getPurchaseLimitLogs());
    try {
      const data = await getLogs(condition);
      dispatch(purchaseLimitActions.getPurchaseLimitLogsSuccess(data.results));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(purchaseLimitActions.getPurchaseLimitLogsFailure(errorText));
    }
  };
};

export const getPurchaseLimitsAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(purchaseLimitActions.getPurchaseLimits());
    try {
      const data = await getPurchaseLimits(condition);
      dispatch(purchaseLimitActions.getPurchaseLimitsSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(purchaseLimitActions.getPurchaseLimitsFailure(errorText));
    }
  };
};

/**
 * export purchase limit
 * @returns
 */
export const exportPurchaseLimitAsync = (type: EXPORT_PURCHASE_TYPE, search?: String | undefined): ThunkAction => {
  return async (dispatch) => {
    dispatch(purchaseLimitActions.exportPurchaseLimit(type));
    try {
      const fileResponse = await exportFile(type, search);
      const warningMessage = await getApiSuccess(fileResponse);
      if (isEmpty(warningMessage)) {
        const rowNumber = fileResponse.headers['row-number'];
        const fileName = getFileName(fileResponse.headers['content-disposition']);
        const blob = new Blob([fileResponse.data]);
        saveAs(blob, fileName);
        dispatch(purchaseLimitActions.exportPurchaseLimitSuccess(rowNumber, type));
      } else {
        dispatch(purchaseLimitActions.exportPurchaseLimitSuccess(0, type, warningMessage));
      }
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(purchaseLimitActions.exportPurchaseLimitFailure(errorText, type));
    }
  };
};

export const getMasterInfoAsync = (): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(purchaseLimitActions.getMasterData());
    try {
      const data = await getSetting(MASTER_TYPE.EX_PURCHASE_LIMIT);
      dispatch(purchaseLimitActions.getMasterDataSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(purchaseLimitActions.getMasterDataFailure(errorText));
    }
  };
};

/**
 * import purchase limit
 * @returns
 */
export const importPurchaseLimitSettingAsync = (file: File): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(purchaseLimitActions.importPurchaseLimitSetting());
    try {
      const response: any = await importFile('/import/purchase_limit_setting', file);
      dispatch(purchaseLimitActions.importPurchaseLimitSettingSuccess(response?.message));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(purchaseLimitActions.importPurchaseLimitSettingFailure(errorText));
    }
  };
};
