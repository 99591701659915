import {
  AccordionDetails,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  Stack,
  TableCell,
  TableRow,
  Typography,
  Box,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { forwardRef, useEffect } from 'react';
import {
  DateTimePickerControl,
  ExportButton,
  getLogLevel,
  getLogTitle,
  LogLevelIcon,
  LogMessage,
} from '../../../common';
import { PiggyIcon } from '../../../common/icons';
import {
  CombinedState,
  DateTimeFormatType,
  formatDate,
  isAfterDate,
  isEmpty,
  LogInfo,
  SearchCondition,
  MENU_HREF,
  FUNCTION_CODE,
  LOG_LEVEL,
} from '../../../core';
import { useDispatch, useSelector } from 'react-redux';
import { exportRebateDataAsync, getNutritionHistory } from '../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textTitle: {
      fontSize: '1.5rem',
    },
    error: {
      color: (theme.palette.common as any).color.textError,
    },
    buttonOutlined: {
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    orderType: {
      marginTop: '0.25rem',
      paddingLeft: '0.75rem',
    },
    tableRoot: {
      width: '100%',
      minWidth: '60em',
      tableLayout: 'fixed',
      '& a': {
        color: (theme.palette.common as any).color.primary,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    reloadButton: {
      color: (theme.palette.common as any).color.outlineSecondary,
      borderColor: (theme.palette.common as any).color.outlineSecondary,
      '&:hover': {
        borderColor: (theme.palette.common as any).color.outlineSecondary,
        backgroundColor: (theme.palette.common as any).color.outlineSecondary,
        color: (theme.palette.common as any).color.gray,
      },
    },
  }),
);

const RebateOutputComponent = forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const formRef = React.useRef<any>();
  const dispatch = useDispatch();
  const { exportRebateFetching, rebateData, nutritionHistory } = useSelector(
    (state: CombinedState) => state['orderDataOutput'],
  );

  const validationSchema = yup.object({
    targetFromDate: yup
      .date()
      .nullable()
      .default(undefined)
      .typeError('表示開始・終了日のフォーマットが不正です。')
      .test('later-than-finish-date', '表示開始日時は表示終了日時より前の日時を指定してください', (value, context) => {
        if (isEmpty(context.parent.targetToDate?.toString()) || isEmpty(value?.toString())) return true;
        else {
          return !isAfterDate(value, context.parent.targetToDate);
        }
      }),
    targetToDate: yup.date().nullable().default(undefined).typeError('表示開始・終了日のフォーマットが不正です。'),
  });

  const handleSubmit = (values: any) => {
    const condition = new SearchCondition();
    condition.filter = {
      targetFromDate: values.targetFromDate
        ? `${formatDate(values.targetFromDate, DateTimeFormatType.yyyyMMdd)}000000` ?? ''
        : '',
      targetToDate: values.targetToDate
        ? `${formatDate(values.targetToDate, DateTimeFormatType.yyyyMMdd)}235959` ?? ''
        : '',
    };

    dispatch(exportRebateDataAsync(condition));
  };

  formRef.current = useFormik({
    initialValues: {
      targetFromDate: null,
      targetToDate: null,
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const onToChange = (date: Date) => {
    formRef.current.setFieldValue('targetToDate', date, true);
  };

  const onFromChange = (date: Date) => {
    formRef.current.setFieldValue('targetFromDate', date, true);
  };

  useEffect(() => {
    const condition = new SearchCondition();
    const ordering = '-log_id';
    condition.filter = {
      ...condition.filter,
      page: 1,
      pageSize: 5,
      functionCode: `${FUNCTION_CODE.NUTRITION_GUIDANCE_FEE_OBIC7_OUTPUT}`,
      logLevel: LOG_LEVEL.SUCCESS,
      ordering,
    };
    dispatch(getNutritionHistory(condition));
  }, [dispatch]);

  return (
    <Stack>
      <Box display='flex' alignItems='center'>
        <PiggyIcon fontSize='medium' viewBox={'0 0 16 16'} />
        <Typography ref={ref} ml={1} variant='h4' className={classes.textTitle}>
          栄養指導料支払ファイル書き出し
        </Typography>
      </Box>
      <Typography my={1}>栄養指導料の計算結果を CSV ファイル形式でダウンロードできます。</Typography>

      <form onSubmit={formRef.current.handleSubmit} noValidate>
        <DateTimePickerControl
          form={formRef.current}
          fromLabel='書き出し開始日'
          toLabel='書き出し終了日'
          variant='FromTo'
          onFromChange={onFromChange}
          onToChange={onToChange}
          fromName='targetFromDate'
          toName='targetToDate'
          inputFromValue={formRef.current.values.targetFromDate}
          inputToValue={formRef.current.values.targetToDate}
          inputFormat={DateTimeFormatType.yyyy_MM_dd_not_dash}
          maxDate={new Date()}
          mode='date'
        />

        <Box mt={1}>
          <ExportButton
            variant='outlined'
            type='submit'
            disabled={exportRebateFetching}
            classes={{ outlined: classes.buttonOutlined }}
            loading={exportRebateFetching}
          >
            ファイルへ書き出し
          </ExportButton>
          {rebateData !== undefined && (
            <Typography className={classes.error}>指定された条件では栄養指導料支払データが見付かりません。</Typography>
          )}
        </Box>
      </form>

      <Box display='flex' alignItems='center' mt={2}>
        <Typography ref={ref} ml={1} variant='h4' className={classes.textTitle}>
          栄養指導料支払ファイルの書き出し履歴
        </Typography>
      </Box>
      <AccordionDetails>
        <TableContainer sx={{ marginBottom: 2 }}>
          <Table classes={{ root: classes.tableRoot }}>
            <TableHead>
              <TableRow>
                <TableCell width='180px'>日時</TableCell>
                <TableCell align='left' width='250px'>
                  処理
                </TableCell>
                <TableCell align='left' width='180px'>
                  レベル
                </TableCell>
                <TableCell align='left'>概要</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nutritionHistory?.map((row: LogInfo) => (
                <TableRow hover key={row.logId}>
                  <TableCell component='th' scope='row'>
                    {row.recordDatetime}
                  </TableCell>
                  <TableCell>{getLogTitle(row)}</TableCell>
                  <TableCell>
                    <Box className='lsc-table-icon' display='flex' alignItems='center'>
                      <LogLevelIcon status={row.logLevel} />
                      <Typography ml={1} component='span'>
                        {getLogLevel(row.logLevel)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align='left'>
                    <LogMessage sender={MENU_HREF.IMPORT} log={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Stack>
  );
});

export const RebateOutput = React.memo(RebateOutputComponent);
