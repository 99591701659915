import { QrDocumentActionTypes, QrDocumentActions } from './actions';
import { QrDocumentState } from './states';

const defaultState: QrDocumentState = {
  fetching: false,
  qrDocumentPagination: {
    count: 0,
    results: [],
  },
  masterInfo: undefined,
  qrDocumentFetching: false,
  deleteQrDocumentAction: {
    fetching: false,
    action: 'init',
  },
};

export const qrDocumentReducer = (state = defaultState, action: QrDocumentActions): QrDocumentState => {
  switch (action.type) {
    case QrDocumentActionTypes.GET_QR_DOCUMENTS:
      return {
        ...state,
        fetching: true,
      };
    case QrDocumentActionTypes.GET_QR_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        qrDocumentPagination: action.payload.data,
      };
    case QrDocumentActionTypes.GET_QR_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case QrDocumentActionTypes.DELETE_QR_DOCUMENT:
      return {
        ...state,
        deleteQrDocumentAction: {
          fetching: true,
          action: 'init',
        },
      };

    case QrDocumentActionTypes.DELETE_QR_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteQrDocumentAction: {
          fetching: false,
          action: 'ok',
        },
      };

    case QrDocumentActionTypes.DELETE_QR_DOCUMENT_FAILURE:
      return {
        ...state,
        deleteQrDocumentAction: {
          fetching: false,
          action: 'failure',
        },
      };

    default:
      return state;
  }
};
