import { EXPORT_REBATE_TYPE } from '../../../core';
import { RebateAmountActions, RebateAmountActionTypes } from './actions';
import { RebateAmountState } from './states';

const defaultState: RebateAmountState = {
  fetching: false,
  importLogs: [],
  nutritionLogs: [],
  nutritionPaymentLogs: [],
  nutritionRates: {
    count: 0,
    results: [],
  },
  rebateAmountFetching: false,
  exportRebateFetching: undefined,
  exportRebateSettingFetching: undefined,
};

export const rebateAmountReducer = (state = defaultState, action: RebateAmountActions): RebateAmountState => {
  switch (action.type) {
    case RebateAmountActionTypes.GET_IMPORT_LOGS:
      return {
        ...state,
        fetching: true,
      };
    case RebateAmountActionTypes.GET_IMPORT_LOGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        importLogs: action.payload.data,
      };
    case RebateAmountActionTypes.GET_IMPORT_LOGS_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case RebateAmountActionTypes.GET_NUTRITION_LOGS:
      return {
        ...state,
        fetching: true,
      };
    case RebateAmountActionTypes.GET_NUTRITION_LOGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        nutritionLogs: action.payload.logs,
        nutritionPaymentLogs: action.payload.paymentLogs,
      };

    case RebateAmountActionTypes.GET_NUTRITION_LOGS_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case RebateAmountActionTypes.GET_NUTRITION_RATES:
      return {
        ...state,
        fetching: true,
      };
    case RebateAmountActionTypes.GET_NUTRITION_RATES_SUCCESS:
      return {
        ...state,
        fetching: false,
        nutritionRates: action.payload.data,
      };
    case RebateAmountActionTypes.GET_NUTRITION_RATES_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case RebateAmountActionTypes.GET_MASTER_INFO_SUCCESS:
      return {
        ...state,
        masterInfo: action.payload.masterInfo,
      };

    case RebateAmountActionTypes.IMPORT_REBATE_AMOUNT:
      return {
        ...state,
        rebateAmountFetching: true,
      };
    case RebateAmountActionTypes.IMPORT_REBATE_AMOUNT_SUCCESS:
    case RebateAmountActionTypes.IMPORT_REBATE_AMOUNT_FAILURE:
      return {
        ...state,
        rebateAmountFetching: false,
      };

    case RebateAmountActionTypes.EXPORT_REBATE:
      return {
        ...state,
        exportRebateFetching: action.payload.type === EXPORT_REBATE_TYPE.EX_ADVISOR_FEE_RATE,
        exportRebateSettingFetching: action.payload.type === EXPORT_REBATE_TYPE.EX_ADVISOR_FEE_RATE_SETTING,
      };
    case RebateAmountActionTypes.EXPORT_REBATE_SUCCESS:
    case RebateAmountActionTypes.EXPORT_REBATE_FAILURE:
      return {
        ...state,
        exportRebateFetching: action.payload.type === EXPORT_REBATE_TYPE.EX_ADVISOR_FEE_RATE ? false : undefined,
        exportRebateSettingFetching:
          action.payload.type === EXPORT_REBATE_TYPE.EX_ADVISOR_FEE_RATE_SETTING ? false : undefined,
      };
    default:
      return state;
  }
};
