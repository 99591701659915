import {
  Checkbox,
  Grid,
  InputBaseProps,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#000000',
      fontWeight: 600,
      marginBottom: '0.25em',
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface OptionData {
  code: string;
  value: string;
}
interface CustomInputProps {
  label?: string;
  errorText?: any;
  options: OptionData[];
}

export const MultiSelectFieldControl = React.memo(
  (props: InputBaseProps & CustomInputProps) => {
    const classes = useStyles();
    const {
      id,
      name,
      placeholder,
      value,
      onChange,
      onBlur,
      error,
      label,
      disabled,
      readOnly,
      options,
    } = props;

    const handleLostFocus = (e: any) => {
      if (typeof onChange === 'function') onChange(e);
      if (typeof onBlur === 'function') onBlur(e);
    };

    const handleChange = (e: SelectChangeEvent<String>) => {
      if (typeof onChange === 'function') onChange(e as any);
    };
    return (
      <Grid container direction='column' alignItems='flex-start'>
        {label && <Typography className={classes.label}>{label}</Typography>}

        <Select
          error={error}
          id={id}
          name={name}
          placeholder={placeholder}
          multiple
          fullWidth
          disabled={disabled}
          readOnly={readOnly}
          value={value as string}
          onChange={handleChange}
          onBlur={handleLostFocus}
          renderValue={(selected: any) => {
            const displayItems: any[] = [];
            selected?.forEach((value: string) => {
              const item = options.filter((option) => option.code === value)[0];
              if (item) {
                displayItems.push(item.value);
              }
            });
            return (
              <Tooltip
                title={
                  <Typography
                    sx={{
                      fontSize: '1rem',
                    }}
                  >
                    {displayItems?.join(', ')}
                  </Typography>
                }
                arrow
                placement='top'
              >
                <Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {displayItems?.join(', ')}
                </Typography>
              </Tooltip>
            );
          }}
          MenuProps={MenuProps}
          size='small'
        >
          {options.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              <Checkbox checked={(value as any).indexOf(option.code) > -1} />
              <ListItemText primary={option.value} />
            </MenuItem>
          ))}
        </Select>
      </Grid>
    );
  }
);
