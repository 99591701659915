
export function isBoolean(value: any): boolean {
  return typeof value === 'boolean';
}

export function isInteger(value: any): boolean {
  return typeof value === 'number' && Number.isInteger(value);
}

export function isString(value: any): boolean {
  return typeof value === 'string';
}

export function checkFilter(filter: any, fields: any): boolean {
  for (const prop in filter) {
    if (Object.prototype.hasOwnProperty.call(filter, prop)) {
      if (!(prop in fields)) {
        return false;
      };

      if (!fields[prop](filter[prop])) {
        return false;
      }
    }
  }
  return true;
}
