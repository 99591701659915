import { useState, useEffect, useRef } from 'react';
import PDFViewer from '../component/pdf-viewer.component';
import EditQRCodeDialog from '../component/edit-qr-code-dialog.component';
import {
  Box,
  Grid,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Theme,
  TextareaAutosize,
  styled,
} from '@mui/material';
import { TextFieldControl, PdfInputControl, SelectFieldControl, ModalControl } from '../../../common';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { qrCodeActions, embeddedQrCodeAsync, checkPdfFileAsync } from '../data-access/actions';
import { CombinedState, MAX_FILE_SIZE, MAX_FILE_SIZE_ERROR, MENU_HREF, isEmpty } from '../../../core';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import DownloadPDF from '../component/download-pdf.component';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const TextareaTemp = styled(TextareaAutosize)(
  ({ theme }) => `
  width: -webkit-fill-available;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

interface Props {
  form: any;
  isRegister: boolean;
  formSubmit: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      '& p': {
        textAlign: 'left',
      },
    },
    error: {
      margin: 0,
      fontSize: '0.75rem',
      marginTop: `3px !important`,
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
      color: (theme.palette.common as any).color.textError,
    },
    hoverBlue: {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #3399FF',
      },
      '&:hover .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '2px !important',
        borderColor: '#1976d2 !important',
      },
    },
    titleHeight: {
      '& input': {
        minHeight: '30px',
        fontSize: '1.2rem',
      },
    },
    editQrBtn: {
      minWidth: '80px',
      height: '40px',
      '&:hover': {
        color: 'white',
        backgroundColor: '#1976d2',
      },
    },
    actionContainer: {
      border: '1px solid #6c757d!important',
      borderRadius: '0.3rem!important',
      padding: '14px 35px',
      position: 'relative',
      height: '70px',
    },
    actionItem: {
      position: 'absolute',
      height: '40px',
      right: 20,
    },
    resizableTextField: {
      width: '100%',
      resize: 'vertical', // 'both' allows resizing in both horizontal and vertical directions
      overflow: 'auto', // 'auto' ensures that overflowing content is clipped
    },
    textAreaHeight: {
      '.MuiOutlinedInput-notchedOutline': {
        height: '153px !important',
      },
    },
    textAreaError: {
      border: '1px solid #d32f2f',
      '&:hover': {
        borderColor: (theme.palette.common as any).color.textError,
      },
      '&:focus': {
        borderColor: (theme.palette.common as any).color.textError,
        boxShadow: '0 0 0 1px #d32f2f',
      },
    },
  }),
);

export const CreateQRCodeFormComponent = (props: Props) => {
  const { form, formSubmit } = props;
  const {
    isOpenDialog,
    embeddedQrCodeInfo,
    embeddedQrCodeInfoCurrent,
    base64WithEmbedded,
    hospitalBrochure,
    categoryOptions,
    isNavigate,
    fileCheckStatus,
  } = useSelector((state: CombinedState) => state['qrCode']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date(2099, 11, 31, 23, 59, 59));
  const [releaseFlag, setReleaseFlag] = useState('1');
  const [fileName, setFileName] = useState('');
  const textareaRef = useRef<any>(null);
  const [fileBlur, setFileBlur] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [descriptionBlur, setDescriptionBlur] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);

  const loadData = (hospitalBrochure: any) => {
    setSelectedStartDate(hospitalBrochure.releaseStartDatetime);
    setSelectedEndDate(hospitalBrochure.releaseEndDatetime);
    setReleaseFlag(hospitalBrochure.releaseFlag);
    setFileName(hospitalBrochure.uploadFilename);
  };

  const setQrState = (hospitalBrochure: any) => {
    dispatch(qrCodeActions.setQrCodeState(hospitalBrochure.qrState));
    dispatch(qrCodeActions.setQrCodeStateCurrent(hospitalBrochure.qrState));
    dispatch(qrCodeActions.embeddedQrCodeSuccess(`data:application/pdf;base64,${hospitalBrochure.previewFile}`));
    if (hospitalBrochure.fileBase64) {
      dispatch(qrCodeActions.setPdfFile(`data:application/pdf;base64,${hospitalBrochure.fileBase64}`));
    }
  };

  const setFieldValue = (hospitalBrochure: any) => {
    form.setFieldValue('hbId', hospitalBrochure.hbId);
    form.setFieldValue('category', hospitalBrochure.hbCategoryId);
    form.setFieldValue('title', hospitalBrochure.title);
    form.setFieldValue('description', hospitalBrochure.description);
    form.setFieldValue('jumpUrl', hospitalBrochure.jumpUrl);
    form.setFieldValue('releaseStartDatetime', hospitalBrochure.releaseStartDatetime);
    form.setFieldValue('releaseEndDatetime', hospitalBrochure.releaseEndDatetime);
    form.setFieldValue('releaseFlag', hospitalBrochure.releaseFlag);
    if (hospitalBrochure.fileBase64) {
      form.setFieldValue('fileBase64', `data:application/pdf;base64,${hospitalBrochure.fileBase64}`);
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    form.setFieldValue(field, value);
  };

  const onReleaseFlagChange = (e: any) => {
    setReleaseFlag(e.target.value);
    form.setFieldValue('releaseFlag', e.target.value);
  };

  const handleStartDateChange = (newDate: any) => {
    setSelectedStartDate(newDate);
    form.setFieldValue('releaseStartDatetime', newDate);
  };

  const handleEndDateChange = (newDate: any) => {
    setSelectedEndDate(newDate);
    form.setFieldValue('releaseEndDatetime', newDate);
  };

  const handleOpenDialog = () => {
    dispatch(qrCodeActions.openDialog());
  };

  const handleCloseDialog = () => {
    dispatch(qrCodeActions.closeDialog());
    dispatch(qrCodeActions.setQrCodeState(embeddedQrCodeInfoCurrent));
  };

  const handleSaveDialog = () => {
    dispatch(
      embeddedQrCodeAsync(
        embeddedQrCodeInfo,
        embeddedQrCodeInfoCurrent,
        form.values.file,
        hospitalBrochure?.hbId || '',
      ),
    );
  };

  const onChangeFile = (file: any) => {
    if (file.type === 'application/pdf' && file.size <= MAX_FILE_SIZE) {
      setPdfFile(file);
      dispatch(checkPdfFileAsync(file));
    } else {
      form.setFieldValue('file', file);
      setFileName('');
      setFileBlur(true);
    }
    dispatch(qrCodeActions.setPageNumber(1));
  };

  const handleCancel = () => {
    setShowCancelDialog(false);
    navigate(MENU_HREF.QR_DOCUMENT);
  };

  const onBeforeCancel = () => {
    setShowCancelDialog(true);
  };

  const handleDescriptionTempBlur = (e: any) => {
    setDescriptionBlur(true);
  };

  useEffect(() => {
    if (fileCheckStatus.action === 'ok') {
      if (fileCheckStatus.status) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          if (fileReader.readyState === 2) {
            form.setFieldValue('fileBase64', fileReader.result);
            // set base64Original state
            dispatch(qrCodeActions.setPdfFile(fileReader.result));
            dispatch(qrCodeActions.removeEmbeddedFile());
            dispatch(qrCodeActions.setQrCodeState([]));
            dispatch(qrCodeActions.setQrCodeStateCurrent([]));
          }
        };
        if (pdfFile) {
          fileReader.readAsDataURL(pdfFile);
          form.setFieldValue('file', pdfFile);
        }
        setFileName('');
        setFileBlur(true);
      } else {
        //TODO
        form.setFieldValue('file', {}, true);
        setFileName('');
        setFileBlur(true);
        form.setFieldValue('fileBase64', null);
        // set base64Original state
        dispatch(qrCodeActions.setPdfFile(null));
        dispatch(qrCodeActions.removeEmbeddedFile());
        dispatch(qrCodeActions.setQrCodeState([]));
        dispatch(qrCodeActions.setQrCodeStateCurrent([]));
      }
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileCheckStatus]);

  useEffect(() => {
    if (isNavigate) {
      navigate(MENU_HREF.QR_DOCUMENT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavigate]);

  useEffect(() => {
    if (!isEmpty(hospitalBrochure)) {
      loadData(hospitalBrochure);
      setFieldValue(hospitalBrochure);
      setQrState(hospitalBrochure);
      if (textareaRef.current) {
        textareaRef.current.value = hospitalBrochure.description;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalBrochure]);

  useEffect(() => {
    form.setFieldValue(
      'isQrCode',
      embeddedQrCodeInfoCurrent.some((obj: any) => obj.isQrCode === true),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embeddedQrCodeInfoCurrent]);

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' rowSpacing={2}>
      <Grid container mb={2}>
        <Grid item xs={2}>
          <Box className='qr-code-label' display='flex' flexDirection='column'>
            <Typography>カテゴリ</Typography>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <SelectFieldControl
            className={`${classes.hoverBlue} ${classes.errorText}`}
            id='category'
            name='category'
            placeholder='選択してください'
            value={form.values.category}
            onChange={(e: any) => handleFieldChange('category', e.target.value)}
            onBlur={form.onBlur}
            options={categoryOptions}
            defaultValue={{ code: '0', value: '選択してください' }}
            error={form.touched.category && Boolean(form.errors.category)}
            errorText={form.touched.category && form.errors.category}
          />
        </Grid>
      </Grid>

      <Grid container mb={2}>
        <Grid item xs={2}>
          <Box className='qr-code-label' display='flex' flexDirection='column'>
            <Typography>資料タイトル</Typography>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <TextFieldControl
            className={`${classes.titleHeight} ${classes.hoverBlue} ${classes.errorText}`}
            fullWidth
            id='title'
            name='title'
            type='text'
            size='small'
            value={form.values.title}
            autoComplete='off'
            onBlur={form.handleBlur}
            onChange={(e: any) => handleFieldChange('title', e.target.value)}
            error={form.touched.title && Boolean(form.errors.title)}
            errorText={form.touched.title && form.errors.title}
            maxLength={256}
          />
          <Typography className='qr-code-help-text'>256文字以内で指定してください。</Typography>
        </Grid>
      </Grid>

      <Grid container mb={2}>
        <Grid item xs={2}>
          <Box className='qr-code-label' display='flex' flexDirection='column'>
            <Typography>ファイルアップロード</Typography>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <PdfInputControl
            id='file'
            name='file'
            accept={`.pdf`}
            allowExtensions={['pdf']}
            onChange={onChangeFile}
            error={(fileBlur || formSubmit) && Boolean(form.errors.file)}
            fileName={fileName}
            errorText={(fileBlur || formSubmit) && form.errors.file}
          />
          {!(form.errors.file && form.errors.file === MAX_FILE_SIZE_ERROR) && (
            <Typography className='qr-code-help-text'>
              アップロードできる最大ファイルサイズは10MBまでとなります。
            </Typography>
          )}
          <Typography className='qr-code-help-text'>
            <DownloadPDF
              fileError={Boolean(form.errors.file)}
              base64String={form.values.fileBase64}
              file={form.values.file}
              fileName={fileName}
            />
          </Typography>
        </Grid>
      </Grid>

      <Grid container mb={2}>
        <Grid item xs={2}>
          <Box className='qr-code-label' display='flex' flexDirection='column'>
            <Typography>QR位置調整</Typography>
          </Box>
        </Grid>
        <Grid item xs={10} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <PDFViewer
            fileError={Boolean(form.errors.file)}
            file={base64WithEmbedded ? base64WithEmbedded : form.values.fileBase64}
          />
          <Button
            disabled={!Boolean(form.errors.file) && (base64WithEmbedded || form.values.fileBase64) ? false : true}
            variant='outlined'
            onClick={handleOpenDialog}
            className={classes.editQrBtn}
          >
            編集
          </Button>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <Typography className={classes.error}>
            {!Boolean(form.errors.file) && (fileBlur || formSubmit) && form.errors.isQrCode}
          </Typography>
        </Grid>
      </Grid>

      <Grid container mb={2}>
        <Grid item xs={2}>
          <Box className='qr-code-label' display='flex' flexDirection='column'>
            <Typography>遷移先 URL</Typography>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <TextFieldControl
            className={`${classes.hoverBlue} ${classes.errorText}`}
            fullWidth
            id='jumpUrl'
            name='jumpUrl'
            type='text'
            size='small'
            placeholder='https://~~~'
            value={form.values.jumpUrl}
            autoComplete='off'
            onBlur={form.handleBlur}
            onChange={(e: any) => handleFieldChange('jumpUrl', e.target.value)}
            error={form.touched.jumpUrl && Boolean(form.errors.jumpUrl)}
            errorText={form.touched.jumpUrl && form.errors.jumpUrl}
            maxLength={500}
          />
          <Typography className='qr-code-help-text'>
            QR コードを読み込んだ際に表示されるホームページの URL を指定してください。
          </Typography>
        </Grid>
      </Grid>

      <Grid container mb={2}>
        <Grid item xs={2}>
          <Box className='qr-code-label' display='flex' flexDirection='column'>
            <Typography>説明文</Typography>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <TextareaTemp
            id='descriptionTemp'
            name='descriptionTemp'
            maxRows={5}
            minRows={5}
            onBlur={handleDescriptionTempBlur}
            ref={textareaRef}
            className={
              (formSubmit || descriptionBlur) && !form.values.description && form.errors.description
                ? classes.textAreaError
                : ''
            }
          />
          <Typography className={classes.error}>
            {(formSubmit || descriptionBlur) && !form.values.description && form.errors.description}
          </Typography>
          <Typography className='qr-code-help-text'>
            改行を含む単純なテキストを登録可能です。動物病院が確認する資料一覧に表示されますので、簡潔な内容を入力してください。
          </Typography>
        </Grid>
      </Grid>

      <Grid container mb={2}>
        <Grid item xs={2}>
          <Box className='qr-code-label' display='flex' flexDirection='column'>
            <Typography>公開開始日時</Typography>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => (
                <>
                  <TextField
                    {...props}
                    inputProps={{
                      ...props.inputProps,
                      placeholder: '年/月/日--:--',
                    }}
                    className={`${classes.hoverBlue} ${classes.errorText}`}
                    size='small'
                    error={form.touched.releaseStartDatetime && Boolean(form.errors.releaseStartDatetime)}
                    id='releaseStartDatetime'
                    name='releaseStartDatetime'
                    onBlur={form.handleBlur}
                  />
                  <Typography className={classes.error}>
                    {form.touched.releaseStartDatetime && form.errors.releaseStartDatetime}
                  </Typography>
                </>
              )}
              value={selectedStartDate}
              onChange={handleStartDateChange}
              inputFormat='yyyy/MM/dd HH:mm'
              ampm={false}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container mb={2}>
        <Grid item xs={2}>
          <Box className='qr-code-label' display='flex' flexDirection='column'>
            <Typography>公開終了日時</Typography>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => (
                <>
                  <TextField
                    {...props}
                    inputProps={{
                      ...props.inputProps,
                      placeholder: '年/月/日--:--',
                    }}
                    size='small'
                    className={`${classes.hoverBlue} ${classes.errorText}`}
                    error={form.touched.releaseEndDatetime && Boolean(form.errors.releaseEndDatetime)}
                    id='releaseEndDatetime'
                    name='releaseEndDatetime'
                    onBlur={form.handleBlur}
                  />
                  <Typography className={classes.error}>
                    {form.touched.releaseEndDatetime && form.errors.releaseEndDatetime}
                  </Typography>
                </>
              )}
              value={selectedEndDate}
              onChange={handleEndDateChange}
              inputFormat='yyyy/MM/dd HH:mm'
              ampm={false}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container mb={5}>
        <Grid item xs={2}>
          <Box className='qr-code-label' display='flex' flexDirection='column'>
            <Typography>公開設定</Typography>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <RadioGroup row name='releaseFlag' id='releaseFlag' value={releaseFlag} onChange={onReleaseFlagChange}>
            <FormControlLabel value='1' control={<Radio />} label='公開' />
            <FormControlLabel value='0' control={<Radio />} label='非公開' />
          </RadioGroup>
          <Typography className='qr-code-help-text'>
            公開から非公開にするとQRコードからのアクセスも参照できなくなりますのでご注意ください。
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.actionContainer} mb={5}>
        <Box display='flex' className={classes.actionItem}>
          <Button
            className={classes.editQrBtn}
            type='button'
            variant='outlined'
            size='medium'
            sx={{ mr: 2 }}
            onClick={onBeforeCancel}
          >
            キャンセル
          </Button>
          <Button type='submit' variant='contained' size='medium' sx={{ minWidth: 80 }}>
            登録
          </Button>
        </Box>
      </Grid>

      <EditQRCodeDialog
        isOpenDialog={isOpenDialog}
        handleCloseDialog={handleCloseDialog}
        handleSaveDialog={handleSaveDialog}
        file={form.values.fileBase64}
      />
      {showCancelDialog && (
        <ModalControl
          onClose={() => {
            setShowCancelDialog(false);
          }}
          onOk={() => handleCancel()}
          okCancel={() => {
            setShowCancelDialog(false);
          }}
          title='確認'
          content={<Typography>キャンセルしてよろしいですか？</Typography>}
        ></ModalControl>
      )}
    </Grid>
  );
};
