import * as React from 'react';
import { useDispatch } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { ALERT_TYPE, config, guid, MENU_HREF, notificationActions } from '../../core';

const SocketComponent = () => {
  const socketRef = React.useRef(false);
  const dispatch = useDispatch();

  const receiveMessage = (event: any) => {
    const message = JSON.parse(event.data);

    if (message.type === ALERT_TYPE.CUSTOM && message.sender === MENU_HREF.EXPORT) {
      message.sender === MENU_HREF.EXPORT && dispatch(notificationActions.exportOrderDataSuccess({ data: 0 }));
    } else if (message.type === ALERT_TYPE.CUSTOM && message.sender === MENU_HREF.EXPORT_NUTRITION) {
      message.sender === MENU_HREF.EXPORT_NUTRITION && dispatch(notificationActions.exportNutritionDataSuccess(0));
    } else {
      dispatch(
        notificationActions.showAlert({
          messageId: guid(),
          message: message.message,
          type: message.type,
          sender: message.sender,
          isLocal: true,
        }),
      );
    }
  };

  const { readyState } = useWebSocket(config.socketUrl, {
    onOpen: () => {
      console.log('Connected!');
    },
    onClose: () => {
      console.log('Disconnected!');
    },
    share: true,
    onMessage: receiveMessage,
    shouldReconnect: () => {
      return socketRef.current === false;
    },
    onError: (event) => {
      console.log('Error', event);
    },
    retryOnError: true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber: number) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  React.useEffect(() => {
    return () => {
      socketRef.current = true;
    };
  }, []);

  return <div hidden>{connectionStatus}</div>;
};

export const SocketContainer = React.memo(SocketComponent);
