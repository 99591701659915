import { CircularProgress, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { isEmpty, loginAsync, strTrim } from '../../../core';
import { LoginFormComponent } from '../form/login-form.component';

export const LoginComponent = () => {
  const { fetching } = useSelector((state: any) => {
    const { auth } = state;
    return {
      fetching: auth.fetching,
    };
  });
  const dispatch = useDispatch();

  const formRef = React.useRef<any>();

  const validationSchema = yup.object({
    username: yup
      .string()
      .transform((v) => (isEmpty(v) ? '' : strTrim(v)))
      .max(32, '正しいユーザー名ではありません')
      .required('ユーザー名が入力されていません'),
    password: yup
      .string()
      .transform((v) => (isEmpty(v) ? '' : strTrim(v)))
      .test('password-format-invalid', '正しいパスワードが入力されていません', (value) => {
        if (value) {
          const regex = RegExp('^[0-9a-zA-Z]{5,32}$');
          if (!regex.test(value)) return false;
        }
        return true;
      })
      .required('パスワードが入力されていません'),
  });

  const handleSubmit = (value: any) => {
    dispatch(loginAsync(value.username, value.password));
  };

  formRef.current = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <Grid
      container
      direction='row'
      alignContent='center'
      sx={{ width: '350px', margin: 'auto', height: 'calc(100vh - 52px)' }}
    >
      <Grid item xs={12} sx={{ textAlign: 'center', margin: '44px' }}>
        <img alt='共立製薬株式会社' className='mb-3' src='images/ks_logo.png' />
        <Typography variant='h3' sx={{ fontSize: '1.75rem' }}>
          LSC-EC 拡張機能
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form autoComplete='off' onSubmit={formRef.current.handleSubmit} noValidate>
          <LoginFormComponent form={formRef.current} />
          <Button
            type='submit'
            variant='contained'
            fullWidth
            size='large'
            sx={{ marginTop: '2em', marginBottom: '1em' }}
            disabled={fetching}
            startIcon={fetching ? <CircularProgress size={24} /> : undefined}
          >
            ログイン
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};
