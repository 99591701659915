import { AxiosRequestConfig } from 'axios';
import store from 'store';
import { User } from '../models';
import { checkFilter, isBoolean } from '../utils';
import { axiosRequestConfiguration, config, KS_LSC_EXT_AUTH_TOKEN } from './config';
import HttpClient from './http-client';

class ApiService extends HttpClient {
  constructor() {
    super(axiosRequestConfiguration);
  }

  public post = async <T>(path: string, data: Object = {}, option: AxiosRequestConfig = {}): Promise<T> => {
    const response = await this.instance.post(`${config.baseApiUrl}${path}`, data, option);
    return response.data;
  };

  public get = async <T>(path: string, queryParams?: any, option: AxiosRequestConfig = {}): Promise<T> => {
    const baseUrl = `${config.baseApiUrl}${path}`;
    const response = await this.instance.get(baseUrl, { params: queryParams });
    return response.data;
  };

  public getFile = async <T>(path: string, queryParams?: any, option: AxiosRequestConfig = {}): Promise<T> => {
    const baseUrl = `${config.baseApiUrl}${path}`;
    return await this.instance.get(baseUrl, { params: queryParams, ...option });
  };

  public put = async <T>(
    path: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: Object = {},
    option: AxiosRequestConfig = {},
  ): Promise<T> => {
    const response = await this.instance.put(`${config.baseApiUrl}${path}`, data, option);
    return response.data;
  };

  public delete = async <T>(path: string, option: AxiosRequestConfig = {}): Promise<T> => {
    const response = await this.instance.delete(`${config.baseApiUrl}${path}`, option);
    return response.data;
  };

  public login = async (username: string, password: string): Promise<void> => {
    const authenticationData = [
      `${encodeURIComponent('username')}=${encodeURIComponent(username)}`,
      `${encodeURIComponent('password')}=${encodeURIComponent(password)}`,
    ]
      .join('&')
      .replace(/%20/g, '+');

    let authenticationResponse = null;
    this.instance.defaults.headers.common['Authorization'] = '';
    authenticationResponse = await this.instance.post(`${config.baseApiUrl}/auth/login`, authenticationData);
    if (authenticationResponse.headers['set-cookie']) {
      const cookies = authenticationResponse.headers['set-cookie'].join(';');
      this.instance.defaults.headers.common['Cookie'] = cookies;
    }

    const token = authenticationResponse.data.key;
    store.set(KS_LSC_EXT_AUTH_TOKEN, token);
    this.instance.defaults.headers.common['Authorization'] = `Token ${token}`;
  };

  public loginEcvo = async (userId: string, accessToken: string): Promise<void> => {
    const authenticationData = { userId, accessToken };

    let authenticationResponse = null;
    this.instance.defaults.headers.common['Authorization'] = '';
    authenticationResponse = await this.instance.post(`${config.baseApiUrl}/auth/ecvo_login`, authenticationData);
    if (authenticationResponse.headers['set-cookie']) {
      const cookies = authenticationResponse.headers['set-cookie'].join(';');
      this.instance.defaults.headers.common['Cookie'] = cookies;
    }

    const token = authenticationResponse.data.key;
    store.set(KS_LSC_EXT_AUTH_TOKEN, token);
    this.instance.defaults.headers.common['Authorization'] = `Token ${token}`;
  };

  public resetAuthorization = () => {
    store.remove(KS_LSC_EXT_AUTH_TOKEN);
    this.instance.defaults.headers.common['Authorization'] = '';
  };

  public logout = async (): Promise<void> => {
    await this.instance.post(`${config.baseApiUrl}/auth/logout`);
    this.resetAuthorization();
  };

  public authorized = async (): Promise<boolean> => {
    try {
      const token = store.get(KS_LSC_EXT_AUTH_TOKEN);
      if (token) {
        await this.getSelf();
      } else return false;
    } catch (serverError: any) {
      this.resetAuthorization();
      if (serverError.response.status === 401) {
        return false;
      }

      throw serverError;
    }

    return true;
  };

  public sendEmailConfirm = async (): Promise<void> => {
    await this.instance.post(`${config.baseApiUrl}/auth/send-email-confirm`);
  };

  public verifyEmail = async (key: string): Promise<void> => {
    const response = await this.instance.get(`${config.baseApiUrl}/auth/account-confirm-email/${key}`);
    return response.data;
  };

  public register = async (fullName: string, email: string, password1: string, password2: string): Promise<User> => {
    const data = {
      fullName,
      email,
      password1,
      password2,
    };

    const response = await this.instance.post(`${config.baseApiUrl}/auth/register`, data);
    return response.data;
  };

  public changePassword = async (oldPassword: string, newPassword1: string, newPassword2: string): Promise<void> => {
    const data = {
      old_password: oldPassword,
      new_password1: newPassword1,
      new_password2: newPassword2,
    };

    await this.instance.post(`${config.baseApiUrl}/auth/password/change`, data);
  };

  public requestPasswordReset = async (email: string): Promise<void> => {
    const data = {
      email,
    };

    await this.instance.post(`${config.baseApiUrl}/auth/password/reset`, data);
  };

  public resetPassword = async (
    newPassword1: string,
    newPassword2: string,
    uid: string,
    token: string,
  ): Promise<void> => {
    const data = {
      new_password1: newPassword1,
      new_password2: newPassword2,
      uid,
      token,
    };
    await this.instance.post(`${config.baseApiUrl}/auth/password/reset/confirm`, data);
  };

  public getUsers = async (filter: any = {}): Promise<User | User[]> => {
    checkFilter(filter, {
      self: isBoolean,
    });

    let users = null;
    if ('self' in filter && filter.self) {
      users = await this.getSelf();
    } else {
      users = await this.getUserList();
    }

    return users;
  };

  public downloadFile = async (path: string, queryParams?: any, option: AxiosRequestConfig = {}) => {
    const baseUrl = `${config.baseApiUrl}${path}`;
    return await this.instance.get(baseUrl, { params: queryParams, responseType: 'blob' });
  };

  public uploadImage = async (imageFiles: File[]): Promise<any> => {
    const formData = new FormData();
    // const clientFile = {
    //   client_files: imageFiles,
    // };

    imageFiles.forEach((file, idx) => {
      formData.append(
        `file`,
        // new File([file], md5(file.name) + '.' + file.type.split('/')[1])
        file,
      );
    });

    // for (const [key, value] of Object.entries(clientFile)) {
    //   if (Array.isArray(value)) {
    //     value.forEach((element, idx) => {
    //       formData.append(
    //         `${key}[${idx}]`,
    //         new File(
    //           [element],
    //           md5(element.name) + '.' + element.type.split('/')[1]
    //         )
    //       );
    //     });
    //   } else {
    //     formData.set(key, value as any);
    //   }
    // }
    // formData.append(
    //   'file',
    //   new File(
    //     [imageFile],
    //     md5(imageFile.name) + '.' + imageFile.type.split('/')[1]
    //   )
    // );
    const response = await this.instance.post(`${config.baseApiUrl}/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  };

  public serverRequest = async (url: string, data: any): Promise<any> => {
    const response = (await this.instance.options(url, ...data)).data;
    return response;
  };

  private getSelf = async (): Promise<User> => {
    const response = await this.instance.get(`${config.baseApiUrl}/users/self`);
    return response.data;
  };

  private getUserList = async (id = null): Promise<User | User[]> => {
    let response = null;
    if (id === null) {
      response = await this.instance.get(`${config.baseApiUrl}/users?page_size=all`);
    } else {
      response = await this.instance.get(`${config.baseApiUrl}/users/${id}`);
    }

    return response.data.results || response.data;
  };
}

export const apiService = new ApiService();
