import { DashboardActionTypes, DashboardActions } from './actions';
import { DashboardState } from './states';

const defaultState: DashboardState = {
  fetching: false,
  logs: [],
};

export const dashboardReducer = (state = defaultState, action: DashboardActions): DashboardState => {
  switch (action.type) {
    case DashboardActionTypes.GET_LOGS:
      return {
        ...state,
        fetching: true,
      };
    case DashboardActionTypes.GET_LOGS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        logs: action.payload.data,
      };
    }
    case DashboardActionTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
