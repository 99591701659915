import { Box, Button, Input, InputBaseProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';

interface FileInputProps {
  onChange: (file: File) => void;
  selectFile?: string;
  accept?: string;
  allowExtensions?: string[];
  loading?: boolean;
  fileName?: string;
  errorText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      position: 'relative',
    },
    error: {
      margin: 0,
      fontSize: '0.75rem',
      marginTop: `3px !important`,
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
      color: (theme.palette.common as any).color.textError,
    },
    singleLabel: {
      borderRadius: 0,
      borderColor: (theme.palette.common as any).color.borderGray,
      borderStyle: 'solid',
      borderWidth: 1,
      paddingLeft: '1em',
      paddingRight: '1em',
      alignItems: 'center',
      display: 'flex',
      minWidth: '25rem',
      color: `${(theme.palette.common as any).color.textPrimary}`,
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      width: '-webkit-fill-available',
    },
    singleLabelError: {
      borderRadius: 0,
      borderColor: 'red',
      borderStyle: 'solid',
      borderWidth: 1,
      paddingLeft: '1em',
      paddingRight: '1em',
      alignItems: 'center',
      display: 'flex',
      minWidth: '25rem',
      color: `${(theme.palette.common as any).color.textPrimary}`,
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      width: '-webkit-fill-available',
    },
    singleTextField: {
      height: '100%',
      left: 0,
      opacity: 0,
      outline: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      display: 'none',
    },
    chooseButton: {
      minWidth: '150px',
      backgroundColor: `${(theme.palette.common as any).color.gray} `,
      border: '1px solid',
      borderColor: `${(theme.palette.common as any).color.borderGray} `,
      color: `${(theme.palette.common as any).color.textPrimary}`,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      textTransform: 'inherit',
      borderRightWidth: 0,
      marginRight: -1,
      boxShadow: 'none',
      '&:hover, &:active': {
        backgroundColor: `${(theme.palette.common as any).color.gray}`,
        borderColor: `${(theme.palette.common as any).color.borderGray}`,
        color: (theme.palette.common as any).color.textPrimary,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: 'none',
      },
      fontWeight: 400,
      zIndex: 99,
    },
    chooseButtonError: {
      minWidth: '150px',
      backgroundColor: `${(theme.palette.common as any).color.gray} `,
      border: '1px solid red',
      color: `${(theme.palette.common as any).color.textPrimary}`,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      textTransform: 'inherit',
      borderRightWidth: 0,
      marginRight: -1,
      boxShadow: 'none',
      '&:hover, &:active': {
        backgroundColor: `${(theme.palette.common as any).color.gray}`,
        borderColor: `red`,
        color: '(theme.palette.common as any).color.textPrimary',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: 'none',
      },
      fontWeight: 400,
      zIndex: 99,
    },
  }),
);

function PdfInputComponent(props: FileInputProps & InputBaseProps) {
  const classes = useStyles();
  const { selectFile, id, name, accept, onChange, error, fileName, errorText } = props;
  const inputRef = useRef<any>(null);
  const [fileInfo, setFileInfo] = useState<any>(undefined);

  const handleChange = (event: any) => {
    const files = event.target.files || event.dataTransfer.files;
    if (files.length === 0) {
      inputRef.current.value = '';
      return false;
    } else {
      const file = files[0];
      setFileInfo(file);
      onChange(file);
      inputRef.current.value = '';
      return true;
    }
  };

  const openFileDialog = () => {
    inputRef.current && inputRef.current?.click();
  };

  return (
    <>
      <Box className={classes.content}>
        <Button
          classes={{ root: error ? classes.chooseButtonError : classes.chooseButton }}
          onClick={openFileDialog}
          variant='contained'
        >
          {selectFile ?? 'ファイルを選択'}
        </Button>
        <Input
          inputRef={inputRef}
          id={id}
          name={name}
          type='file'
          classes={{ root: classes.singleTextField }}
          inputProps={{
            accept: accept,
          }}
          onChange={handleChange}
        />
        <Typography
          className={error ? classes.singleLabelError : classes.singleLabel}
          component='span'
          onClick={openFileDialog}
        >
          {fileName ? fileName : fileInfo?.name ?? '選択されていません'}
        </Typography>
      </Box>
      {errorText && <Typography className={classes.error}>{errorText}</Typography>}
    </>
  );
}

export const PdfInputControl = React.memo(PdfInputComponent);
