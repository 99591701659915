import { QrAccessActionTypes, QrAccessActions } from './actions';
import { QrAccessState } from './states';

const defaultState: QrAccessState = {
  fetching: false,
  qrAccessPagination: {
    page: 1,
    count: 0,
    results: [],
  },
  qrAccessFetching: false,
  searchOptions: {
    postName: [],
    address1Code: [],
    reserveFlag2: [],
    hbCategories: [],
    isDownload: [],
  },
  qrAccessCsv: [],
  qrAccessCsvFetching: false,
};

export const qrAccessReducer = (state = defaultState, action: QrAccessActions): QrAccessState => {
  switch (action.type) {
    case QrAccessActionTypes.GET_QR_ACCESS:
    case QrAccessActionTypes.GET_SEARCH_OPTIONS:
      return {
        ...state,
        fetching: true,
      };
    case QrAccessActionTypes.GET_QR_ACCESS_SUCCESS:
      return {
        ...state,
        fetching: false,
        qrAccessPagination: action.payload.data,
      };
    case QrAccessActionTypes.GET_SEARCH_OPTIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        searchOptions: action.payload.data,
      };
    case QrAccessActionTypes.GET_QR_ACCESS_FAILURE:
    case QrAccessActionTypes.GET_SEARCH_OPTIONS_FAILURE:
      return {
        ...state,
        fetching: false,
      };
    case QrAccessActionTypes.GET_QR_ACCESS_CSV_LIST:
      return {
        ...state,
        qrAccessCsvFetching: true,
      };
    case QrAccessActionTypes.GET_QR_ACCESS_CSV_LIST_SUCCESS:
      return {
        ...state,
        qrAccessCsv: action.payload.data,
        qrAccessCsvFetching: false,
      };
    case QrAccessActionTypes.GET_QR_ACCESS_CSV_LIST_FAILURE:
      return {
        ...state,
        qrAccessCsvFetching: false,
      };
    case QrAccessActionTypes.DOWNLOAD_QR_ACCESS_CSV:
      return {
        ...state,
        qrAccessCsvFetching: true,
      };
    case QrAccessActionTypes.DOWNLOAD_QR_ACCESS_CSV_SUCCESS:
    case QrAccessActionTypes.DOWNLOAD_QR_ACCESS_CSV_FAILURE:
      return {
        ...state,
        qrAccessCsvFetching: false,
      };
    default:
      return state;
  }
};
