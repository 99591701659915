import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CustomDateControl, ExpandIcon, SelectFieldControl, TextFieldControl } from '../../../common';
import { DateTimeFormatType, formatDate } from '../../../core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CombinedState, MENU_HREF, SearchCondition } from '../../../core';
import jpLocale from 'date-fns/locale/ja';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessListAsync, getSearchOptionsAsync, getAccessListCsvAsync } from '../data-access/actions';
import DownloadCsvDialog from './download-csv-dialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginAccordion: {
      '&:first-of-type': {
        marginBottom: 0,
        marginTop: 0,
        border: '1px solid rgba(0,0,0,.125)',
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem',
      },
      '&:last-of-type': {
        marginBottom: 0,
        marginTop: 0,
        boxShadow: 'none',
        border: '1px solid rgba(0,0,0,.125)',
        borderTopWidth: 0,
        borderBottomLeftRadius: '0.25rem',
        borderBottomRightRadius: '0.25rem',
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'inherit',
      },
      margin: '0px !important',
    },
    headerBlueOpen: {
      backgroundColor: (theme.palette.common as any).color.backgroundCyan,
      borderColor: (theme.palette.common as any).color.borderCyan,
      color: (theme.palette.common as any).color.textBlue,
      borderTopLeftRadius: 'calc(0.25rem - 1px)',
      borderTopRightRadius: 'calc(0.25rem - 1px)',
      boxShadow: 'inset 0 -1px 0 rgba(0,0,0,.125)',
      minHeight: '48px !important',
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    headerBlueClose: {
      backgroundColor: 'inherit',
      borderColor: 'inherit',
      color: 'inherit',
      borderTopLeftRadius: 'calc(0.25rem - 1px)',
      borderTopRightRadius: 'calc(0.25rem - 1px)',
    },
    footerBlueOpen: {
      backgroundColor: (theme.palette.common as any).color.backgroundCyan,
      color: (theme.palette.common as any).color.textBlue,
      border: '1px solid rgba(0,0,0,.125)',
      borderRightWidth: 0,
      borderLeftWidth: 0,
      minHeight: '48px !important',
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    footerBlueClose: {
      backgroundColor: 'inherit',
      borderColor: 'inherit',
      color: 'inherit',
      borderBottomLeftRadius: 'calc(0.25rem - 1px)',
      borderBottomRightRadius: 'calc(0.25rem - 1px)',
    },
    linkText: {
      color: (theme.palette.common as any).color.primary,
      cursor: 'pointer',
    },
    smallSize: {
      fontSize: '70%',
    },
    formControl: {
      minWidth: '100%',
      marginTop: '0.5rem',
    },
    input: {
      '& .MuiOutlinedInput-input': {
        padding: '.375rem 2.25rem .375rem .75rem',
      },
    },
    inputDate: {
      '& .MuiInputBase-input': {
        padding: '.375rem 2.25rem .375rem .75rem',
        height: '1.75rem',
      },
      width: '100%',
    },
    containedButton: {
      fontSize: '.875rem',
      backgroundColor: (theme.palette.common as any).color.primary,
      borderColor: (theme.palette.common as any).color.primary,
      '&:hover': {
        borderColor: (theme.palette.common as any).color.hover,
        backgroundColor: (theme.palette.common as any).color.hover,
        boxShadow: '0 0 0 0.25rem rgba(49,132,253,.5)',
      },
    },
    outlineButton: {
      fontSize: '.875rem',
      padding: '0.25rem 0.5rem',
      '&:active,&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
  }),
);

export const SearchComponent = (props: any) => {
  const { isSearch, setIsSearch } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const formRef = useRef<any>();
  const searchState = useMemo(() => (state as any)?.searchCondition || {}, [state]);
  const { searchOptions, qrAccessPagination } = useSelector((state: CombinedState) => state['qrAccess']);

  const handleSubmit = (value: any) => {
    const valueValidated = validateForm(value);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: 1, ...valueValidated };
    navigate(MENU_HREF.QR_ACCESS_COUNT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });
    setIsSearch(true);
    dispatch(getAccessListAsync(condition));
  };

  const validateForm = (value: any) => {
    if (value.postName === '-1') {
      value = { ...value, postName: '' };
    }
    if (value.address1Code === '-1') {
      value = { ...value, address1Code: '' };
    }
    if (value.reserveFlag2 === '-1') {
      value = { ...value, reserveFlag2: '' };
    }
    if (value.hbCategoryId === '-1') {
      value = { ...value, hbCategoryId: '' };
    }
    if (value.isDownload === '-1') {
      value = { ...value, isDownload: '' };
    }
    if (value.createdDatetimeFrom !== null) {
      value = {
        ...value,
        createdDatetimeFrom: formatDate(value.createdDatetimeFrom, DateTimeFormatType.yyyy_MM_dd_HH_mm_ss_dash),
      };
    }
    if (value.createdDatetimeTo !== null) {
      value = {
        ...value,
        createdDatetimeTo: formatDate(value.createdDatetimeTo, DateTimeFormatType.yyyy_MM_dd_HH_mm_ss_dash),
      };
    }
    if (value.assessLogStartDatetime !== null) {
      value = {
        ...value,
        assessLogStartDatetime: formatDate(value.assessLogStartDatetime, DateTimeFormatType.yyyy_MM_dd_HH_mm_ss_dash),
      };
    }
    if (value.assessLogEndDatetime !== null) {
      value = {
        ...value,
        assessLogEndDatetime: formatDate(value.assessLogEndDatetime, DateTimeFormatType.yyyy_MM_dd_HH_mm_ss_dash),
      };
    }
    return value;
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onChangeDate = (value: any, field: string) => {
    formRef.current.setFieldValue(field, value);
  };

  formRef.current = useFormik({
    initialValues: {
      postName: '-1',
      staffsName: '',
      vicsCode: '',
      hospitalName: '',
      address1Code: '-1',
      reserveFlag2: '-1',
      hbId: '',
      hbCategoryId: '-1',
      title: '',
      isDownload: '-1',
      createdDatetimeFrom: null,
      createdDatetimeTo: null,
      assessLogStartDatetime: null,
      assessLogEndDatetime: null,
    },
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    dispatch(getSearchOptionsAsync());
    const condition = new SearchCondition();
    navigate(MENU_HREF.QR_ACCESS_COUNT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const exportCsv = () => {
    const valueValidated = validateForm(formRef.current.values);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: 1, ...valueValidated };
    navigate(MENU_HREF.QR_ACCESS_COUNT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    dispatch(getAccessListCsvAsync(condition));
  };

  const [isOpenDownloadCsv, setIsOpenDownloadCsv] = useState<boolean>(false)
  const openDownloadCsv = () => {
    setIsOpenDownloadCsv(true);
  }

  const closeDownloadCsv = () => {
    setIsOpenDownloadCsv(false);
  }

  return (
    <form onSubmit={formRef.current.handleSubmit} onReset={formRef.current.handleReset} noValidate>
      <Typography>
        検索条件
        <Typography ml={2} component='span' className={classes.smallSize}>
          ※検索条件は複数指定可能です。複数指定した場合は、AND条件（論理積）として検索を行います。
        </Typography>
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jpLocale}>
        <Accordion
          classes={{ root: classes.marginAccordion }}
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            className={expanded === 'panel1' ? classes.headerBlueOpen : classes.headerBlueClose}
            expandIcon={<ExpandIcon fontSize='small' viewBox='0 0 16 16' color='inherit' />}
            aria-controls='panel1d-content'
            id='panel1d-header'
          >
            <Typography>営業所情報</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} px={2} display='flex' flexDirection='row'>
              <Grid xs={4} item>
                <Typography>営業所名</Typography>
                <FormControl className={classes.formControl}>
                  <SelectFieldControl
                    size='small'
                    id='postName'
                    name='postName'
                    value={formRef.current.values.postName}
                    onChange={formRef.current.handleChange}
                    options={searchOptions.postName}
                    defaultValue={{ code: '-1', value: '選択してください' }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={4} item>
                <Typography>営業担当者名</Typography>
                <FormControl className={classes.formControl}>
                  <TextFieldControl
                    placeholder='営業担当者名（部分一致）'
                    classes={{ root: classes.input }}
                    id='staffsName'
                    name='staffsName'
                    size='small'
                    value={formRef.current.values.staffsName}
                    onChange={formRef.current.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion
          classes={{ root: classes.marginAccordion }}
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            className={expanded === 'panel2' ? classes.footerBlueOpen : classes.footerBlueClose}
            expandIcon={<ExpandIcon fontSize='small' viewBox='0 0 16 16' color='inherit' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography>動物病院情報</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} px={2} display='flex' flexDirection='row'>
              <Grid xs={4} item>
                <Typography>VICS コード</Typography>
                <FormControl className={classes.formControl}>
                  <TextFieldControl
                    placeholder='VICS コード'
                    classes={{ root: classes.input }}
                    id='vicsCode'
                    name='vicsCode'
                    size='small'
                    value={formRef.current.values.vicsCode}
                    onChange={formRef.current.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid xs={4} item>
                <Typography>動物病院名</Typography>
                <FormControl className={classes.formControl}>
                  <TextFieldControl
                    placeholder='動物病院名（部分一致）'
                    classes={{ root: classes.input }}
                    id='hospitalName'
                    name='hospitalName'
                    size='small'
                    value={formRef.current.values.hospitalName}
                    onChange={formRef.current.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid xs={4} item>
                <Typography>所在地</Typography>
                <FormControl className={classes.formControl}>
                  <SelectFieldControl
                    size='small'
                    id='address1Code'
                    name='address1Code'
                    value={formRef.current.values.address1Code}
                    onChange={formRef.current.handleChange}
                    options={searchOptions.address1Code}
                    defaultValue={{ code: '-1', value: '選択してください' }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={4} px={2} display='flex' flexDirection='row'>
              <Grid xs={4} item>
                <Typography mt={1}>取引種別</Typography>
                <FormControl className={classes.formControl}>
                  <SelectFieldControl
                    size='small'
                    id='reserveFlag2'
                    name='reserveFlag2'
                    value={formRef.current.values.reserveFlag2}
                    onChange={formRef.current.handleChange}
                    options={searchOptions.reserveFlag2}
                    defaultValue={{ code: '-1', value: '選択してください' }}
                  />
                </FormControl>
              </Grid>
              <Grid item></Grid>
              <Grid item></Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion
          classes={{ root: classes.marginAccordion }}
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            className={expanded === 'panel3' ? classes.footerBlueOpen : classes.footerBlueClose}
            expandIcon={<ExpandIcon fontSize='small' viewBox='0 0 16 16' color='inherit' />}
            aria-controls='panel3a-content'
            id='panel3a-header'
          >
            <Typography>資料情報</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} px={2} display='flex' flexDirection='row'>
              <Grid xs={4} item>
                <Typography>資料 ID</Typography>
                <FormControl className={classes.formControl}>
                  <TextFieldControl
                    placeholder='資料 ID'
                    classes={{ root: classes.input }}
                    id='hbId'
                    name='hbId'
                    size='small'
                    value={formRef.current.values.hbId}
                    onChange={formRef.current.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid xs={4} item>
                <Typography>カテゴリ</Typography>
                <FormControl className={classes.formControl}>
                  <SelectFieldControl
                    size='small'
                    id='hbCategoryId'
                    name='hbCategoryId'
                    value={formRef.current.values.hbCategoryId}
                    onChange={formRef.current.handleChange}
                    options={searchOptions.hbCategories}
                    defaultValue={{ code: '-1', value: '選択してください' }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={4} item>
                <Typography>資料名</Typography>
                <FormControl className={classes.formControl}>
                  <TextFieldControl
                    placeholder='資料名（部分一致）'
                    classes={{ root: classes.input }}
                    id='title'
                    name='title'
                    size='small'
                    value={formRef.current.values.title}
                    onChange={formRef.current.handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={4} px={2} display='flex' flexDirection='row'>
              <Grid xs={4} item>
                <Typography mt={1}>ダウンロード状況</Typography>
                <FormControl className={classes.formControl}>
                  <SelectFieldControl
                    size='small'
                    id='isDownload'
                    name='isDownload'
                    value={formRef.current.values.isDownload}
                    onChange={formRef.current.handleChange}
                    options={[
                      { code: '0', value: '未ダウンロード' },
                      { code: '1', value: 'ダウンロード済' },
                    ]}
                    defaultValue={{ code: '-1', value: '選択してください' }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={4} item>
                <Typography my={1}>初回ダウンロード日(開始日)</Typography>
                <CustomDateControl
                  id='createdDatetimeFrom'
                  name='createdDatetimeFrom'
                  ampm={false}
                  inputFormat={DateTimeFormatType.yyyy_MM_dd_not_dash}
                  value={formRef.current.values.createdDatetimeFrom}
                  onChange={(value: any) => onChangeDate(value, 'createdDatetimeFrom')}
                  classes={{ root: classes.inputDate }}
                />
              </Grid>
              <Grid xs={4} item>
                <Typography my={1}>初回ダウンロード日(終了日)</Typography>
                <CustomDateControl
                  id='createdDatetimeTo'
                  name='createdDatetimeTo'
                  ampm={false}
                  inputFormat={DateTimeFormatType.yyyy_MM_dd_not_dash}
                  value={formRef.current.values.createdDatetimeTo}
                  onChange={(value: any) => onChangeDate(value, 'createdDatetimeTo')}
                  classes={{ root: classes.inputDate }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion
          classes={{ root: classes.marginAccordion }}
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            className={expanded === 'panel4' ? classes.footerBlueOpen : classes.footerBlueClose}
            expandIcon={<ExpandIcon fontSize='small' viewBox='0 0 16 16' color='inherit' />}
            aria-controls='panel4a-content'
            id='panel4a-header'
          >
            <Typography>集計期間</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} px={2} display='flex' flexDirection='row'>
              <Grid xs={4} item>
                <Typography mb={1}>集計開始日</Typography>
                <CustomDateControl
                  ampm={false}
                  inputFormat={DateTimeFormatType.yyyy_MM_dd_not_dash}
                  onChange={(value: any) => onChangeDate(value, 'assessLogStartDatetime')}
                  classes={{ root: classes.inputDate }}
                  id='assessLogStartDatetime'
                  name='assessLogStartDatetime'
                  value={formRef.current.values.assessLogStartDatetime}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography mb={1}>集計終了日</Typography>
                <CustomDateControl
                  ampm={false}
                  inputFormat={DateTimeFormatType.yyyy_MM_dd_not_dash}
                  onChange={(value: any) => onChangeDate(value, 'assessLogEndDatetime')}
                  classes={{ root: classes.inputDate }}
                  id='assessLogEndDatetime'
                  name='assessLogEndDatetime'
                  value={formRef.current.values.assessLogEndDatetime}
                />
              </Grid>
              <Grid item></Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Box display='flex' mt={2} justifyContent='flex-end'>
          <Button
            type='reset'
            sx={{ marginRight: '1rem' }}
            className={classes.outlineButton}
            variant='outlined'
            onClick={formRef.current.resetForm}
          >
            リセット
          </Button>
          <Button type='submit' className={classes.containedButton} variant='contained'>
            検索
          </Button>
          <Button
            type='button'
            className={classes.outlineButton}
            variant='outlined'
            sx={{ marginLeft: '1rem' }}
            onClick={openDownloadCsv}
          >
            CSV ダウンロード
          </Button>
        </Box>

        {isSearch ? (
          <>
            {qrAccessPagination.count > 0 && (
              <Box display='flex' mt={2} justifyContent='flex-end' alignItems='center'>
                <Typography>
                  集計期間：
                  {formatDate(searchState.assessLogStartDatetime, DateTimeFormatType.yyyy_MM_dd_HH_mm) ??
                    '[集計開始日]'}
                  ～
                  {formatDate(searchState.assessLogEndDatetime, DateTimeFormatType.yyyy_MM_dd_HH_mm) ?? '[集計終了日]'}
                </Typography>
                <Button
                  sx={{ marginLeft: '1rem' }}
                  className={classes.outlineButton}
                  variant='outlined'
                  onClick={exportCsv}
                >
                  CSV 出力
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Box my={4}>
            <Typography mb={2}>検索条件が指定されていないため、検索結果はありません。</Typography>
          </Box>
        )}
        <DownloadCsvDialog
          isOpenDownloadCsv={isOpenDownloadCsv}
          closeDownloadCsv={closeDownloadCsv}
        />
      </LocalizationProvider>
    </form>
  );
};
