import { Grid, InputAdornment, InputBaseProps, TextField } from '@mui/material';
import { trim } from 'lodash';
import React from 'react';

interface CustomInputProps {
  label?: string;
  errorText?: any;
  maxLength?: number;
}

const NUMBER_REGEX = /[0-9]*/gm;
const ALPHA_NUMERIC_HYPHEN = /[0-9a-zA-Z-]*/gm;

export const TextFieldControl = React.memo((props: InputBaseProps & CustomInputProps) => {
  const {
    className,
    id,
    name,
    placeholder,
    value,
    type,
    onChange,
    onBlur,
    error,
    errorText,
    maxLength,
    endAdornment,
    label,
    readOnly,
    size,
    disabled,
    autoComplete = 'off',
  } = props;

  const handleLostFocus = (e: any) => {
    e.target.value = trim(e.target.value);
    if (typeof onChange === 'function') onChange(e);
    if (typeof onBlur === 'function') onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      if (e.target.value) {
        const regexp = new RegExp(NUMBER_REGEX);
        const extractNumber = regexp.exec(e.target.value)?.join('');
        if (extractNumber) {
          e.target.value = Number(extractNumber).toString();
        } else {
          e.target.value = '';
        }
      }
    } else if (type === 'halfWidthNumber') {
      if (e.target.value) {
        const regexp = new RegExp(NUMBER_REGEX);
        const extractNumber = regexp.exec(e.target.value)?.join('');
        if (extractNumber) {
          e.target.value = extractNumber;
        } else {
          e.target.value = '';
        }
      }
    } else if (type === 'alphaNumericHyphen') {
      if (e.target.value) {
        const regexp = new RegExp(ALPHA_NUMERIC_HYPHEN);
        const extractValue = regexp.exec(e.target.value)?.join('');
        if (extractValue) {
          e.target.value = extractValue;
        } else {
          e.target.value = '';
        }
      }
    }
    if (typeof onChange === 'function') onChange(e);
  };

  return (
    <Grid container direction='column' alignItems='flex-start'>
      <TextField
        className={className}
        fullWidth
        error={error}
        helperText={errorText}
        id={id}
        name={name}
        type={type === 'number' || type === 'halfWidthNumber' ? 'tel' : type}
        placeholder={placeholder}
        value={value}
        autoComplete={autoComplete}
        label={label}
        variant='outlined'
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleLostFocus}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          maxLength: maxLength || undefined,
          readOnly: readOnly,
        }}
        size={size}
        InputProps={{
          endAdornment: endAdornment ? <InputAdornment position='end'>{endAdornment}</InputAdornment> : undefined,
        }}
      />
    </Grid>
  );
});
