import {
  ActionUnion,
  createAction,
  getApiError,
  HospitalBrochurePagination,
  SearchCondition,
  ThunkAction,
} from '../../../core';
import { deleteHospitalBrochure, getHospitalBrochureList } from '../service/services';

export enum QrDocumentActionTypes {
  GET_QR_DOCUMENTS = 'QR_DOCUMENT_GET_QR_DOCUMENTS',
  GET_QR_DOCUMENTS_SUCCESS = 'QR_DOCUMENT_GET_QR_DOCUMENTS_SUCCESS',
  GET_QR_DOCUMENTS_FAILURE = 'QR_DOCUMENT_GET_QR_DOCUMENTS_FAILURE',

  DELETE_QR_DOCUMENT = 'QR_DOCUMENT_DELETE_QR_DOCUMENTS',
  DELETE_QR_DOCUMENT_SUCCESS = 'QR_DOCUMENT_DELETE_QR_DOCUMENTS_SUCCESS',
  DELETE_QR_DOCUMENT_FAILURE = 'QR_DOCUMENT_DELETE_QR_DOCUMENTS_FAILURE',
}

export const qrDocumentActions = {
  getQrDocuments: () => createAction(QrDocumentActionTypes.GET_QR_DOCUMENTS),
  getQrDocumentsSuccess: (data: HospitalBrochurePagination) =>
    createAction(QrDocumentActionTypes.GET_QR_DOCUMENTS_SUCCESS, { data }),
  getQrDocumentsFailure: (error: any) =>
    createAction(QrDocumentActionTypes.GET_QR_DOCUMENTS_FAILURE, {
      error,
    }),

  deleteQrDocument: () => createAction(QrDocumentActionTypes.DELETE_QR_DOCUMENT),
  deleteQrDocumentSuccess: () => createAction(QrDocumentActionTypes.DELETE_QR_DOCUMENT_SUCCESS),
  deleteQrDocumentFailure: (error: any) =>
    createAction(QrDocumentActionTypes.DELETE_QR_DOCUMENT_FAILURE, {
      error,
    }),
};

export type QrDocumentActions = ActionUnion<typeof qrDocumentActions>;

export const getQrDocumentsAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch: any): Promise<any> => {
    dispatch(qrDocumentActions.getQrDocuments());
    try {
      const data = await getHospitalBrochureList(condition);
      dispatch(qrDocumentActions.getQrDocumentsSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrDocumentActions.getQrDocumentsFailure(errorText));
    }
  };
};

export const deleteQrDocumentAsync = (hbId: String): ThunkAction => {
  return async (dispatch: any): Promise<any> => {
    dispatch(qrDocumentActions.deleteQrDocument());
    try {
      await deleteHospitalBrochure(hbId);
      dispatch(qrDocumentActions.deleteQrDocumentSuccess());
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrDocumentActions.deleteQrDocumentFailure(errorText));
    }
  };
};
