import React from 'react';
import { LOG_LEVEL } from '../../core';
import { ErrorIcon } from './error-icon.component';
import { InfoIcon } from './info-icon.component';
import { SuccessIcon } from './success-icon.component';
import { WarningIcon } from './warning-icon.component';

interface LogLevelIconProps {
  status: number;
}

export const LogLevelIcon = React.memo((props: LogLevelIconProps) => {
  const { status } = props;
  switch (status) {
    case LOG_LEVEL.SUCCESS:
      return <SuccessIcon fontSize='small' viewBox={'0 0 16 16'} />;
    case LOG_LEVEL.WARNING:
      return <WarningIcon fontSize='small' viewBox={'0 0 16 16'} />;
    case LOG_LEVEL.ERROR:
      return <ErrorIcon fontSize='small' viewBox={'0 0 16 16'} color='error' />;
    default:
      return <InfoIcon fontSize='small' viewBox={'0 0 16 16'} />;
  }
});
