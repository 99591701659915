import { apiService, config } from '../../http-client';
import { User } from '../../models';
import { ActionUnion, createAction, ThunkAction } from '../../redux';
import { isReachable } from '../../utils/url-checker';

export enum AuthActionTypes {
  AUTHORIZED_SUCCESS = 'AUTHORIZED_SUCCESS',
  AUTHORIZED_FAILED = 'AUTHORIZED_FAILED',
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',
  REGISTER = 'REGISTER',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_FAILED = 'REGISTER_FAILED',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED',
  LOGOUT = 'LOGOUT',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILED = 'LOGOUT_FAILED',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED',
  REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
  REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS',
  REQUEST_PASSWORD_RESET_FAILED = 'REQUEST_PASSWORD_RESET_FAILED',
  RESET_PASSWORD = 'RESET_PASSWORD_CONFIRM',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS',
  RESET_PASSWORD_FAILED = 'RESET_PASSWORD_CONFIRM_FAILED',
  LOAD_AUTH_ACTIONS = 'LOAD_AUTH_ACTIONS',
  LOAD_AUTH_ACTIONS_SUCCESS = 'LOAD_AUTH_ACTIONS_SUCCESS',
  LOAD_AUTH_ACTIONS_FAILED = 'LOAD_AUTH_ACTIONS_FAILED',
  SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL',
  SEND_EMAIL_CONFIRM = 'SEND_EMAIL_CONFIRM',
  SEND_EMAIL_CONFIRM_SUCCESS = 'SEND_EMAIL_CONFIRM_SUCCESS',
  SEND_EMAIL_CONFIRM_FAILED = 'SEND_EMAIL_CONFIRM_FAILED',
}

export const authActions = {
  authorizeSuccess: (user: any) => createAction(AuthActionTypes.AUTHORIZED_SUCCESS, { user }),
  authorizeFailed: (error: any) => createAction(AuthActionTypes.AUTHORIZED_FAILED, { error }),
  login: () => createAction(AuthActionTypes.LOGIN),
  loginSuccess: (user: User) => createAction(AuthActionTypes.LOGIN_SUCCESS, { user }),
  loginFailed: (error: any) => createAction(AuthActionTypes.LOGIN_FAILED, { error }),
  verifyEmail: () => createAction(AuthActionTypes.VERIFY_EMAIL),
  verifyEmailSuccess: () => createAction(AuthActionTypes.VERIFY_EMAIL_SUCCESS),
  verifyEmailFailed: (error: any) => createAction(AuthActionTypes.VERIFY_EMAIL_FAILED, { error }),
  register: () => createAction(AuthActionTypes.REGISTER),
  registerSuccess: () => createAction(AuthActionTypes.REGISTER_SUCCESS),
  registerFailed: (error: any) => createAction(AuthActionTypes.REGISTER_FAILED, { error }),
  logout: () => createAction(AuthActionTypes.LOGOUT),
  logoutSuccess: () => createAction(AuthActionTypes.LOGOUT_SUCCESS),
  logoutFailed: (error: any) => createAction(AuthActionTypes.LOGOUT_FAILED, { error }),
  changePassword: () => createAction(AuthActionTypes.CHANGE_PASSWORD),
  changePasswordSuccess: () => createAction(AuthActionTypes.CHANGE_PASSWORD_SUCCESS),
  changePasswordFailed: (error: any) => createAction(AuthActionTypes.CHANGE_PASSWORD_FAILED, { error }),
  requestPasswordReset: () => createAction(AuthActionTypes.REQUEST_PASSWORD_RESET),
  requestPasswordResetSuccess: () => createAction(AuthActionTypes.REQUEST_PASSWORD_RESET_SUCCESS),
  requestPasswordResetFailed: (error: any) => createAction(AuthActionTypes.REQUEST_PASSWORD_RESET_FAILED, { error }),
  resetPassword: () => createAction(AuthActionTypes.RESET_PASSWORD),
  resetPasswordSuccess: () => createAction(AuthActionTypes.RESET_PASSWORD_SUCCESS),
  resetPasswordFailed: (error: any) => createAction(AuthActionTypes.RESET_PASSWORD_FAILED, { error }),
  loadServerAuthActions: () => createAction(AuthActionTypes.LOAD_AUTH_ACTIONS),
  loadServerAuthActionsSuccess: (allowChangePassword: boolean, allowResetPassword: boolean) =>
    createAction(AuthActionTypes.LOAD_AUTH_ACTIONS_SUCCESS, {
      allowChangePassword,
      allowResetPassword,
    }),
  loadServerAuthActionsFailed: (error: any) => createAction(AuthActionTypes.LOAD_AUTH_ACTIONS_FAILED, { error }),
  showLoginModal: () => createAction(AuthActionTypes.SHOW_LOGIN_MODAL),
  sendEmailConfirm: () => createAction(AuthActionTypes.SEND_EMAIL_CONFIRM),
  sendEmailConfirmSuccess: () => createAction(AuthActionTypes.SEND_EMAIL_CONFIRM_SUCCESS),
  sendEmailConfirmError: (error: any) => createAction(AuthActionTypes.SEND_EMAIL_CONFIRM_FAILED),
};

export type AuthActions = ActionUnion<typeof authActions>;

export const registerAsync =
  (fullName: string, email: string, password1: string, password2: string): ThunkAction =>
  async (dispatch) => {
    dispatch(authActions.register());

    try {
      await apiService.register(fullName, email, password1, password2);

      dispatch(authActions.registerSuccess());
    } catch (error) {
      dispatch(authActions.registerFailed(error));
    }
  };

export const loginAsync =
  (username: string, password: string): ThunkAction =>
  async (dispatch) => {
    dispatch(authActions.login());

    try {
      await apiService.login(username, password);
      const user = (await apiService.getUsers({ self: true })) as User;

      dispatch(authActions.loginSuccess(user));
    } catch (error) {
      apiService.resetAuthorization();
      dispatch(authActions.loginFailed(error));
    }
  };

export const loginEcvoAsync =
  (userId: string, accessToken: string): ThunkAction =>
  async (dispatch) => {
    try {
      await apiService.loginEcvo(userId, accessToken);
      const user = (await apiService.getUsers({ self: true })) as User;

      dispatch(authActions.authorizeSuccess(user));
    } catch (error) {
      apiService.resetAuthorization();
      dispatch(authActions.authorizeFailed(error));
    }
  };

export const verifyEmailAsync =
  (key: string): ThunkAction =>
  async (dispatch) => {
    dispatch(authActions.verifyEmail());

    try {
      await apiService.verifyEmail(key);
      dispatch(authActions.verifyEmailSuccess());
    } catch (error) {
      dispatch(authActions.verifyEmailFailed(error));
    }
  };

export const logoutAsync = (): ThunkAction => async (dispatch) => {
  dispatch(authActions.logout());

  try {
    await apiService.logout();
    dispatch(authActions.logoutSuccess());
  } catch (error) {
    dispatch(authActions.logoutFailed(error));
  }
};

export const authorizedAsync = (): ThunkAction => async (dispatch) => {
  try {
    const result = await apiService.authorized();

    if (result) {
      const userInstance = (await apiService.getUsers({ self: true })) as User;
      dispatch(authActions.authorizeSuccess(userInstance));
    } else {
      dispatch(authActions.authorizeSuccess(null));
    }
  } catch (error) {
    dispatch(authActions.authorizeFailed(error));
  }
};

export const changePasswordAsync =
  (oldPassword: string, newPassword1: string, newPassword2: string): ThunkAction =>
  async (dispatch) => {
    dispatch(authActions.changePassword());

    try {
      await apiService.changePassword(oldPassword, newPassword1, newPassword2);
      dispatch(authActions.changePasswordSuccess());
    } catch (error) {
      dispatch(authActions.changePasswordFailed(error));
    }
  };

export const requestPasswordResetAsync =
  (email: string): ThunkAction =>
  async (dispatch) => {
    dispatch(authActions.requestPasswordReset());

    try {
      await apiService.requestPasswordReset(email);
      dispatch(authActions.requestPasswordResetSuccess());
    } catch (error) {
      dispatch(authActions.requestPasswordResetFailed(error));
    }
  };

export const resetPasswordAsync =
  (newPassword1: string, newPassword2: string, uid: string, token: string): ThunkAction =>
  async (dispatch) => {
    dispatch(authActions.resetPassword());

    try {
      await apiService.resetPassword(newPassword1, newPassword2, uid, token);
      dispatch(authActions.resetPasswordSuccess());
    } catch (error) {
      dispatch(authActions.resetPasswordFailed(error));
    }
  };

export const loadAuthActionsAsync = (): ThunkAction => async (dispatch) => {
  dispatch(authActions.loadServerAuthActions());

  try {
    const promises: Promise<boolean>[] = [
      isReachable(`${config.baseApiUrl}/auth/password/change`, 'OPTIONS'),
      isReachable(`${config.baseApiUrl}/auth/password/reset`, 'OPTIONS'),
    ];
    const [allowChangePassword, allowResetPassword] = await Promise.all(promises);

    dispatch(authActions.loadServerAuthActionsSuccess(allowChangePassword, allowResetPassword));
  } catch (error) {
    dispatch(authActions.loadServerAuthActionsFailed(error));
  }
};

export const sendEmailConfirmAsync = (): ThunkAction => async (dispatch) => {
  dispatch(authActions.sendEmailConfirm());

  try {
    await apiService.sendEmailConfirm();

    dispatch(authActions.sendEmailConfirmSuccess());
  } catch (error) {
    dispatch(authActions.sendEmailConfirmError(error));
  }
};
