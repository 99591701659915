import saveAs from 'file-saver';
import { getLogs } from '../../../common';
import {
  ActionUnion,
  createAction,
  getApiError,
  getFileName,
  LogInfoPagination,
  SearchCondition,
  ThunkAction,
} from '../../../core';
import { exportFile } from '../service/services';

export enum SettingEventLogActionTypes {
  GET_LOGS = 'SETTING_EVENT_LOG_GET_LOGS',
  GET_LOGS_SUCCESS = 'SETTING_EVENT_LOG_GET_LOGS_SUCCESS',
  GET_LOGS_FAILURE = 'SETTING_EVENT_LOG_GET_LOGS_FAILURE',
  EXPORT_LOG = 'SETTING_EVENT_LOG_EXPORT_LOG',
  EXPORT_LOG_SUCCESS = 'SETTING_EVENT_LOG_EXPORT_LOG_SUCCESS',
  EXPORT_LOG_FAILURE = 'SETTING_EVENT_LOG_EXPORT_LOG_FAILURE',
}

export const settingEventLogActions = {
  getEventLogs: () => createAction(SettingEventLogActionTypes.GET_LOGS),
  getEventLogsSuccess: (data: LogInfoPagination) => createAction(SettingEventLogActionTypes.GET_LOGS_SUCCESS, { data }),
  getEventLogsFailure: (error: any) =>
    createAction(SettingEventLogActionTypes.GET_LOGS_FAILURE, {
      error,
    }),
  exportLog: () => createAction(SettingEventLogActionTypes.EXPORT_LOG),
  exportLogSuccess: (row: number) => createAction(SettingEventLogActionTypes.EXPORT_LOG_SUCCESS, { row }),
  exportLogFailure: (error: any) =>
    createAction(SettingEventLogActionTypes.EXPORT_LOG_FAILURE, {
      error,
    }),
};

export type SettingEventLogActions = ActionUnion<typeof settingEventLogActions>;

/**
 * Get all event logs
 * @returns
 */
export const getEventLogsAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(settingEventLogActions.getEventLogs());
    try {
      const data = await getLogs(condition);
      dispatch(settingEventLogActions.getEventLogsSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(settingEventLogActions.getEventLogsFailure(errorText));
    }
  };
};

/**
 * export file event log
 * @returns
 */
export const exportEventLogFileAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(settingEventLogActions.exportLog());
    try {
      const fileResponse = await exportFile(condition);
      const rowNumber = fileResponse.headers['row-number'];
      const fileName = getFileName(fileResponse.headers['content-disposition']);
      const blob = new Blob([fileResponse.data]);
      saveAs(blob, fileName);
      dispatch(settingEventLogActions.exportLogSuccess(rowNumber));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(settingEventLogActions.exportLogFailure(errorText));
    }
  };
};
