import { Box, Button, InputBaseProps, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useRef } from 'react';

interface SearchInputProps {
  onSearch: () => void;
  errorText?: string;
  label?: string;
  customClass?: any;
  inputTextValue?: string;
  onInputChange?: (text: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      position: 'relative',
    },
    error: {
      margin: 0,
      fontSize: '0.75rem',
      marginTop: `3px !important`,
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
      color: (theme.palette.common as any).color.textError,
    },
    singleTextField: {
      width: '40%',
      color: `${(theme.palette.common as any).color.textPrimary}`,
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRightWidth: 0,
        },
      },
      '& .Mui-focused': {
        '& fieldset': {
          borderWidth: `1px !important`,
        },
      },
    },
    buttonRoot: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: -1,
      zIndex: 99,
    },
    buttonOutlined: {
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
  }),
);

function SearchInputComponent(props: SearchInputProps & InputBaseProps) {
  const classes = useStyles();
  const inputRef = useRef<any>(null);
  const { placeholder, name, id, error, label, customClass, onInputChange, inputTextValue, onSearch } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange && onInputChange(event.target.value);
  };

  return (
    <Box className={classes.content}>
      <TextField
        value={inputTextValue ?? ''}
        inputRef={inputRef}
        size='small'
        variant='outlined'
        fullWidth
        id={id}
        name={name}
        error={error}
        onChange={handleChange}
        classes={{ root: clsx(classes.singleTextField, customClass) }}
        placeholder={placeholder}
      />
      <Button
        onClick={onSearch}
        variant='outlined'
        classes={{ root: classes.buttonRoot, outlined: classes.buttonOutlined }}
      >
        {label ?? '表示'}
      </Button>
    </Box>
  );
}

export const SearchInputControl = React.memo(SearchInputComponent);
