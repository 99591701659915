import { saveAs } from 'file-saver';
import { ActionUnion, createAction, getApiError, getFileName, SearchCondition, ThunkAction } from '../../../core';
import {
  getHospitalBrochureList,
  getSearchOption,
  getHospitalBrochureListCsv,
  getQrAccessListCsv,
  downloadQrAccessCsv,
} from '../service/services';
import { HBAccessLogPagination, QrAccessCsv, SearchOptions } from './states';

export enum QrAccessActionTypes {
  GET_QR_ACCESS = 'QR_ACCESS_GET_QR_ACCESS',
  GET_QR_ACCESS_SUCCESS = 'QR_ACCESS_GET_QR_ACCESS_SUCCESS',
  GET_QR_ACCESS_FAILURE = 'QR_ACCESS_GET_QR_ACCESS_FAILURE',
  GET_QR_ACCESS_CSV = 'GET_QR_ACCESS_CSV',
  GET_QR_ACCESS_CSV_SUCCESS = 'GET_QR_ACCESS_CSV_SUCCESS',
  GET_QR_ACCESS_CSV_FAILURE = 'GET_QR_ACCESS_CSV_FAILURE',
  GET_SEARCH_OPTIONS = 'GET_SEARCH_OPTIONS',
  GET_SEARCH_OPTIONS_SUCCESS = 'GET_SEARCH_OPTIONS_SUCCESS',
  GET_SEARCH_OPTIONS_FAILURE = 'GET_SEARCH_OPTIONS_FAILURE',
  GET_QR_ACCESS_CSV_LIST = 'GET_QR_ACCESS_CSV_LIST',
  GET_QR_ACCESS_CSV_LIST_SUCCESS = 'GET_QR_ACCESS_CSV_LIST_SUCCESS',
  GET_QR_ACCESS_CSV_LIST_FAILURE = 'GET_QR_ACCESS_CSV_LIST_FAILURE',
  DOWNLOAD_QR_ACCESS_CSV = 'DOWNLOAD_QR_ACCESS_CSV',
  DOWNLOAD_QR_ACCESS_CSV_SUCCESS = 'DOWNLOAD_QR_ACCESS_CSV_SUCCESS',
  DOWNLOAD_QR_ACCESS_CSV_FAILURE = 'DOWNLOAD_QR_ACCESS_CSV_FAILURE',
}

export const qrAccessActions = {
  getQrAccessList: () => createAction(QrAccessActionTypes.GET_QR_ACCESS),
  getQrAccessListSuccess: (data: HBAccessLogPagination) =>
    createAction(QrAccessActionTypes.GET_QR_ACCESS_SUCCESS, { data }),
  getQrAccessListFailure: (error: any) =>
    createAction(QrAccessActionTypes.GET_QR_ACCESS_FAILURE, {
      error,
    }),
  getQrAccessListCsv: () => createAction(QrAccessActionTypes.GET_QR_ACCESS_CSV),
  getQrAccessListCsvSuccess: () => createAction(QrAccessActionTypes.GET_QR_ACCESS_CSV_SUCCESS),
  getQrAccessListCsvFailure: (error: any) =>
    createAction(QrAccessActionTypes.GET_QR_ACCESS_CSV_FAILURE, {
      error,
    }),
  getSearchOptions: () => createAction(QrAccessActionTypes.GET_SEARCH_OPTIONS),
  getSearchOptionsSuccess: (data: SearchOptions) =>
    createAction(QrAccessActionTypes.GET_SEARCH_OPTIONS_SUCCESS, { data }),
  getSearchOptionsFailure: (error: any) =>
    createAction(QrAccessActionTypes.GET_SEARCH_OPTIONS_FAILURE, {
      error,
    }),
  getQrAccessCsvList: () => createAction(QrAccessActionTypes.GET_QR_ACCESS_CSV_LIST),
  getQrAccessCsvListSuccess: (data: QrAccessCsv[]) =>
    createAction(QrAccessActionTypes.GET_QR_ACCESS_CSV_LIST_SUCCESS, { data }),
  getQrAccessCsvListFailure: (error: any) =>
    createAction(QrAccessActionTypes.GET_QR_ACCESS_CSV_LIST_FAILURE, {
      error,
    }),
  downloadQrAccessCsv: () => createAction(QrAccessActionTypes.DOWNLOAD_QR_ACCESS_CSV),
  downloadQrAccessCsvSuccess: () => createAction(QrAccessActionTypes.DOWNLOAD_QR_ACCESS_CSV_SUCCESS),
  downloadQrAccessCsvFailure: (error: any) =>
    createAction(QrAccessActionTypes.DOWNLOAD_QR_ACCESS_CSV_FAILURE, {
      error,
    }),
};

export type QrAccessActions = ActionUnion<typeof qrAccessActions>;

export const getAccessListAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch: any): Promise<any> => {
    dispatch(qrAccessActions.getQrAccessList());
    try {
      const data = await getHospitalBrochureList(condition);
      dispatch(qrAccessActions.getQrAccessListSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrAccessActions.getQrAccessListFailure(errorText));
    }
  };
};

export const getAccessListCsvAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch: any): Promise<any> => {
    dispatch(qrAccessActions.getQrAccessListCsv());
    try {
      await getHospitalBrochureListCsv(condition);
      dispatch(qrAccessActions.getQrAccessListCsvSuccess());
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrAccessActions.getQrAccessListCsvFailure(errorText));
    }
  };
};

export const getSearchOptionsAsync = (): ThunkAction => {
  return async (dispatch: any): Promise<any> => {
    dispatch(qrAccessActions.getSearchOptions());
    try {
      const data = await getSearchOption();
      dispatch(qrAccessActions.getSearchOptionsSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrAccessActions.getSearchOptionsFailure(errorText));
    }
  };
};

export const getQrAccessListCsvAsync = (): ThunkAction => {
  return async (dispatch: any): Promise<any> => {
    dispatch(qrAccessActions.getQrAccessCsvList());
    try {
      const response = await getQrAccessListCsv();
      dispatch(qrAccessActions.getQrAccessCsvListSuccess(response));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrAccessActions.getQrAccessCsvListFailure(errorText));
    }
  };
};

export const downloadCsvAsync = (id: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(qrAccessActions.downloadQrAccessCsv());
    try {
      const fileResponse = await downloadQrAccessCsv(id);
      const fileName = getFileName(fileResponse.headers['content-disposition']);
      const blob = new Blob([fileResponse.data]);
      saveAs(blob, fileName);
      dispatch(qrAccessActions.downloadQrAccessCsvSuccess());
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrAccessActions.downloadQrAccessCsvFailure(errorText));
    }
  };
};
