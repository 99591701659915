import { EXPORT_PURCHASE_TYPE } from '../../../core';
import { PurchaseLimitActions, PurchaseLimitActionTypes } from './actions';
import { PurchaseLimitState } from './states';

const defaultState: PurchaseLimitState = {
  fetching: false,
  logs: [],
  purchaseLimitPagination: {
    count: 0,
    results: [],
  },
  masterInfo: undefined,
  purchaseLimitSettingFetching: false,
  exportPurchaseLimitFetching: undefined,
  exportPurchaseLimitSettingFetching: undefined,
  purchaseLimitDuplicated: false,
  saleLimitDuplicated: false,
};

export const purchaseLimitReducer = (state = defaultState, action: PurchaseLimitActions): PurchaseLimitState => {
  switch (action.type) {
    case PurchaseLimitActionTypes.GET_LOGS:
      return {
        ...state,
        fetching: true,
      };
    case PurchaseLimitActionTypes.GET_LOGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        logs: action.payload.data,
      };
    case PurchaseLimitActionTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case PurchaseLimitActionTypes.GET_PURCHASE_LIMITS:
      return {
        ...state,
        fetching: true,
      };
    case PurchaseLimitActionTypes.GET_PURCHASE_LIMITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        purchaseLimitPagination: action.payload.data,
      };
    case PurchaseLimitActionTypes.GET_PURCHASE_LIMITS_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case PurchaseLimitActionTypes.EXPORT_PURCHASE_LIMIT:
      return {
        ...state,
        exportPurchaseLimitFetching: action.payload.type === EXPORT_PURCHASE_TYPE.EX_PURCHASE_LIMIT,
        exportPurchaseLimitSettingFetching: action.payload.type === EXPORT_PURCHASE_TYPE.EX_PURCHASE_LIMIT_SETTING,
      };
    case PurchaseLimitActionTypes.EXPORT_PURCHASE_LIMIT_SUCCESS:
      return {
        ...state,
        exportPurchaseLimitFetching: action.payload.type === EXPORT_PURCHASE_TYPE.EX_PURCHASE_LIMIT ? false : undefined,
        exportPurchaseLimitSettingFetching:
          action.payload.type === EXPORT_PURCHASE_TYPE.EX_PURCHASE_LIMIT_SETTING ? false : undefined,
        purchaseLimitDuplicated:
          action.payload.type === EXPORT_PURCHASE_TYPE.EX_PURCHASE_LIMIT && action.payload.message === 'duplicated'
            ? true
            : false,
        saleLimitDuplicated:
          action.payload.type === EXPORT_PURCHASE_TYPE.EX_PURCHASE_LIMIT_SETTING &&
          action.payload.message === 'duplicated'
            ? true
            : false,
      };
    case PurchaseLimitActionTypes.EXPORT_PURCHASE_LIMIT_FAILURE:
      return {
        ...state,
        exportPurchaseLimitFetching: action.payload.type === EXPORT_PURCHASE_TYPE.EX_PURCHASE_LIMIT ? false : undefined,
        exportPurchaseLimitSettingFetching:
          action.payload.type === EXPORT_PURCHASE_TYPE.EX_PURCHASE_LIMIT_SETTING ? false : undefined,
      };

    case PurchaseLimitActionTypes.GET_MASTER_INFO_SUCCESS:
      return {
        ...state,
        masterInfo: action.payload.masterInfo,
      };

    case PurchaseLimitActionTypes.IMPORT_PURCHASE_LIMIT_SETTING:
      return {
        ...state,
        purchaseLimitSettingFetching: true,
      };
    case PurchaseLimitActionTypes.IMPORT_PURCHASE_LIMIT_SETTING_SUCCESS:
    case PurchaseLimitActionTypes.IMPORT_PURCHASE_LIMIT_SETTING_FAILURE:
      return {
        ...state,
        purchaseLimitSettingFetching: false,
      };

    default:
      return state;
  }
};
