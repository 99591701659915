import { Grid, InputAdornment, InputBaseProps, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { trim } from 'lodash';
import React, { useState } from 'react';

interface CustomInputProps {
  label?: string;
  errorText?: any;
  maxLength?: number;
}

export const PasswordFieldControl = React.memo((props: InputBaseProps & CustomInputProps) => {
  const {
    id,
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    errorText,
    maxLength,
    label,
    readOnly,
    autoComplete,
    disabled,
  } = props;

  const [isPassword, setIsPassword] = useState(true);

  const handleLostFocus = (e: any) => {
    e.target.value = trim(e.target.value);
    if (typeof onChange === 'function') onChange(e);
    if (typeof onBlur === 'function') onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') onChange(e);
  };
  return (
    <Grid container direction='column' alignItems='flex-start'>
      <TextField
        fullWidth
        error={error}
        helperText={errorText}
        id={id}
        name={name}
        type={isPassword ? 'password' : 'text'}
        placeholder={placeholder}
        value={value}
        autoComplete={autoComplete}
        label={label}
        variant='outlined'
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleLostFocus}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          maxLength: maxLength || undefined,
          readOnly: readOnly,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' onClick={() => setIsPassword(!isPassword)} sx={{ cursor: 'pointer' }}>
              {isPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
});
