/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { qrDownloadAsync } from '../data-access/actions';
import { CombinedState, DOWNLOAD_QR_SUCCESS_MSG, MENU_HREF } from '../../../core';
import { Grid, Link, Typography } from '@mui/material';

export const QrDownloadComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const { message } = useSelector((state: CombinedState) => state['qrDownload']);

  useEffect(() => {
    const hbId = queryParams.get('hbId');
    const vicsCode = queryParams.get('vicsCode');
    if (hbId && vicsCode) {
      dispatch(qrDownloadAsync(hbId, vicsCode));
      // KS_LSCEC_P3_DEV-709 【QRコード機能】ダウンロード時に表示されるダウンロード画面について
      const appTitle = document.getElementById('app-title') ?? { innerText: '' };
      appTitle.innerText = '資料ダウンロード';
    } else {
      navigate(MENU_HREF.ROOT);
    }
  }, []);

  const onDownloadAgain = () => {
    const hbId = queryParams.get('hbId');
    const vicsCode = queryParams.get('vicsCode');
    if (hbId && vicsCode) {
      dispatch(qrDownloadAsync(hbId, vicsCode));
    }
  };

  return (
    <>
      {message && <Typography textAlign='center'>{message}</Typography>}
      <Grid container d-flex justifyContent='center'>
        {message === DOWNLOAD_QR_SUCCESS_MSG && (
          <Link component='button' variant='body2' onClick={onDownloadAgain}>
            ダウンロードが開始できない場合はこのリンクをクリックしてダウンロードを行ってください
          </Link>
        )}
      </Grid>
    </>
  );
};
