import { isEmpty } from './validations';
import { format, parse, isAfter, isDate, addDays } from 'date-fns';

export const numberWithCommas = (x: number | string, delimiter = ',') => {
  if (isEmpty(x)) return '';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
};

export const formatDate = (date: Date | string, formatString = 'yyyy-MM-dd'): string | null => {
  if (!date) return null;
  if (typeof date === 'string') {
    return format(new Date(date), formatString);
  } else if (date instanceof Date) {
    return format(date, formatString);
  }
  return null;
};

export const convertToDate = (date: string, formatString = 'yyyy-MM-dd'): Date | null => {
  if (!date) return null;
  return parse(date, formatString, new Date());
};

export const convertFileName = (logMessage: string) => {
  const regex = /\(.*\.(xlsx|xls|csv)\)/gm;
  const values = logMessage.match(regex);
  if (isEmpty(values)) return '';
  else return `${values ?? [][0]}`;
};

export const getLinkLog = (logMessage: string) => {
  const regex = /\(.*\.(xlsx|xls|csv)\)/gim;
  return logMessage.replace(regex, `<a class="log-file">${convertFileName(logMessage)}</a>`);
};

export const isAfterDate = (date: any, dateToCompare: any) => isAfter(date, dateToCompare);

export const isDateValid = (date: any) => isDate(date);

export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getFileName = (disposition: string): string => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\\.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
  let fileName: string = '';
  if (utf8FilenameRegex.test(disposition)) {
    let rawName = utf8FilenameRegex.exec(disposition) ?? [];
    fileName = decodeURIComponent(rawName[1]);
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=');
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
  }
  return fileName;
};

export const addNumberDays = (date: Date, days = 0) => {
  return addDays(date, days);
};
