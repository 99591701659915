import saveAs from 'file-saver';
import {
  ActionUnion,
  createAction,
  getApiError,
  getApiSuccess,
  getFileName,
  isEmpty,
  ThunkAction,
} from '../../../core';
import { downloadQr } from '../service/service';

export enum QrDownloadActionTypes {
  DOWNLOAD_QR = 'DOWNLOAD_QR',
  DOWNLOAD_QR_SUCCESS = 'DOWNLOAD_QR_SUCCESS',
  DOWNLOAD_QR_FAILED = 'DOWNLOAD_QR_FAILED',
}

export const qrDownloadActions = {
  downloadQr: () => createAction(QrDownloadActionTypes.DOWNLOAD_QR),
  downloadQrSuccess: () => createAction(QrDownloadActionTypes.DOWNLOAD_QR_SUCCESS),
  downloadQrFailed: (error: any) =>
    createAction(QrDownloadActionTypes.DOWNLOAD_QR_FAILED, {
      error,
    }),
};

export type QrDownloadActions = ActionUnion<typeof qrDownloadActions>;

export const qrDownloadAsync = (hbId: string, vicsCode: string): ThunkAction => {
  return async (dispatch) => {
    dispatch(qrDownloadActions.downloadQr());
    try {
      const fileResponse: any = await downloadQr(hbId, vicsCode);
      const warningMessage = await getApiSuccess(fileResponse);
      if (isEmpty(warningMessage)) {
        const fileName = getFileName(fileResponse.headers['content-disposition']);
        const blob = new Blob([fileResponse.data]);
        saveAs(blob, fileName);
        dispatch(qrDownloadActions.downloadQrSuccess());
      } else {
        dispatch(qrDownloadActions.downloadQrFailed(warningMessage));
      }
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrDownloadActions.downloadQrFailed(errorText));
    }
  };
};
