import Pagination from '@mui/material/Pagination';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';

interface PaginationProps {
  totalRecord: number;
  currentPage: number;
  itemPerPage: number;
  onChange: (currentPage: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationItem: {
      '& .MuiPaginationItem-root': {
        borderColor: (theme.palette.common as any).color.secondary,
        color: `${(theme.palette.common as any).color.primary}`,
        backgroundColor: theme.palette.primary.contrastText,
        borderLeftWidth: 0,
        borderRadius: 0,
        margin: 0,
        '&.Mui-selected': {
          backgroundColor: `${(theme.palette.common as any).color.borderGray}`,
          '&:hover': {
            zIndex: 2,
            color: (theme.palette.common as any).color.hover,
            borderColor: (theme.palette.common as any).color.secondary,
            backgroundColor: `${(theme.palette.common as any).color.borderGray}`,
          },
        },
        '&:hover': {
          zIndex: 2,
          color: (theme.palette.common as any).color.hover,
          borderColor: (theme.palette.common as any).color.secondary,
          backgroundColor: (theme.palette.common as any).color.borderGray,
        },
      },
      '& .MuiPaginationItem-ellipsis': {
        border: `1px solid ${(theme.palette.common as any).color.secondary}`,
        height: 32,
        borderLeftWidth: 0,
        borderRadius: 0,
        '&:hover': {
          zIndex: 2,
          backgroundColor: theme.palette.primary.contrastText,
        },
      },
      '& li:first-child': {
        '& button': {
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
          borderLeftWidth: 1,
        },
      },
      '& li:last-child': {
        '& button': {
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
        },
      },
    },
  }),
);

function PaginationComponent(props: PaginationProps) {
  const classes = useStyles();
  const { currentPage, onChange, totalRecord, itemPerPage } = props;
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    onChange(value);
  };

  useEffect(() => {
    const count = Math.ceil(totalRecord / Number(itemPerPage));
    setCount(count);
  }, [totalRecord, itemPerPage]);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  return count > 1 ? (
    <Pagination
      count={count}
      size='medium'
      variant='outlined'
      siblingCount={0}
      shape='rounded'
      showFirstButton
      showLastButton
      page={page}
      onChange={handleChange}
      classes={{ ul: classes.paginationItem }}
    />
  ) : (
    <></>
  );
}

export const PaginationControl = React.memo(PaginationComponent);
