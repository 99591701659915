import {
  Stack,
  Typography,
  Box,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckListIcon, getLogLevel, getLogTitle, LogLevelIcon, LogMessage } from '../../../../common';
import { CombinedState, FUNCTION_CODE, isEmpty, LogInfo, MENU_HREF, SearchCondition } from '../../../../core';
import { getImportLogsAsync } from '../../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      fontSize: '1.5rem',
    },
    tableRoot: {
      width: '100%',
      tableLayout: 'fixed',
      minWidth: '60em',
      maxWidth: '90em',
      marginBottom: '1rem',
      '& a': {
        color: (theme.palette.common as any).color.primary,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
  }),
);

export const LogComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { importLogs } = useSelector((state: CombinedState) => state['rebateAmount']);

  useEffect(() => {
    const condition = new SearchCondition();
    const ordering = '-log_id';
    condition.filter = {
      ...condition.filter,
      page: 1,
      pageSize: 5,
      functionCode: `${FUNCTION_CODE.NUTRITION_GUIDANCE_FEE_IMPORT}`,
      ordering,
    };
    dispatch(getImportLogsAsync(condition));
  }, [dispatch]);

  return (
    <Stack spacing={2}>
      <Box display='flex' alignItems='center'>
        <CheckListIcon fontSize='medium' viewBox={'0 0 16 16'} />
        <Typography ml={1} variant='h4' className={classes.textHeader}>
          ファイル読み込み履歴
        </Typography>
      </Box>

      <TableContainer component={Box} px={3}>
        <Table classes={{ root: classes.tableRoot }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width='180px'>日時</TableCell>
              <TableCell width='250px'>処理</TableCell>
              <TableCell width='180px'>レベル</TableCell>
              <TableCell>概要</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {importLogs?.map((row: LogInfo) => (
              <TableRow hover key={row.logId}>
                <TableCell component='th' scope='row'>
                  {row.recordDatetime}
                </TableCell>
                <TableCell>{getLogTitle(row)}</TableCell>
                <TableCell>
                  <Box className='lsc-table-icon' display='flex' alignItems='center'>
                    <LogLevelIcon status={row.logLevel} />
                    <Typography ml={1} component='span'>
                      {getLogLevel(row.logLevel)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <LogMessage sender={MENU_HREF.REBATE_AMOUNT} log={row} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isEmpty(importLogs) && <Typography>栄養指導料率設定ファイルの読み込みはありません。</Typography>}
      </TableContainer>
    </Stack>
  );
};
