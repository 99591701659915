/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, matchPath, useLocation } from 'react-router-dom';
import { MainLayout } from '../libs/common';
import {
  authorizedAsync,
  loginEcvoAsync,
  AuthState,
  CombinedState,
  getSettingInfoAsync,
  isEmpty,
  MENU_HREF,
  resetErrors,
  resetMessages,
  useNetwork,
} from '../libs/core';
import {
  DashboardPage,
  ImportFilePage,
  LoginPage,
  NotFoundPage,
  OutputPage,
  PurchaseLimitPage,
  RebateAmountPage,
  SettingAccessLogPage,
  SettingEventLogPage,
  QRCodePage,
  QrDocumentPage,
  QrAccessPage,
  QRScanPage,
  QrDownloadPage,
} from '../libs/features';

const useStyles = makeStyles((theme: Theme) => ({
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    width: '100vw',
    height: '100vh',
  },
  spinner: {
    width: '64px',
    height: '64px',
  },
}));

export function App() {
  const classes = useStyles();
  const { userInitialized, user, notifications } = useSelector((state: CombinedState) => {
    const auth = state['auth'] as AuthState;
    return {
      userInitialized: auth.initialized,
      user: auth.user,
      notifications: state.notifications,
    };
  });

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isOffline } = useNetwork();
  const readyForRender = userInitialized && (user == null || (user && user.isActive));

  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isQrUrl =
    !isEmpty(matchPath(MENU_HREF.QR_DOCUMENT, pathname)) ||
    !isEmpty(matchPath(MENU_HREF.QR_ACCESS_COUNT, pathname)) ||
    !isEmpty(matchPath(MENU_HREF.QR_CODE, pathname)) ||
    !isEmpty(matchPath(MENU_HREF.QR_CODE_EDIT, pathname));

  const isNoNavUrl =
    !isEmpty(matchPath(MENU_HREF.QR_SCAN, pathname)) || !isEmpty(matchPath(MENU_HREF.QR_DOWNLOAD, pathname));

  const adminRoutes = useMemo(
    () => (
      <Routes>
        <Route index element={<DashboardPage />} />
        <Route path='/dashboard' element={<DashboardPage />} />
        <Route path='/purchase-limit' element={<PurchaseLimitPage />} />
        <Route path='/rebate-amount' element={<RebateAmountPage />} />
        <Route path='/setting-eventlog' element={<SettingEventLogPage />} />
        <Route path='/setting-accesslog' element={<SettingAccessLogPage />} />
        <Route path='/output-files' element={<OutputPage />} />
        <Route path='/import-files' element={<ImportFilePage />} />
        <Route path='/qr-code' element={<QRCodePage />} />
        <Route path='/qr-code/:hb_id' element={<QRCodePage />} />
        <Route path='/qr-list' element={<QrDocumentPage />} />
        <Route path='/qr-access-count' element={<QrAccessPage />} />
        <Route path='/qr-scan' element={<QRScanPage />} />
        <Route path='/qr-download' element={<QrDownloadPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    ),
    [],
  );

  const renderHomeLayouts = () => {
    return <MainLayout user={user}>{adminRoutes}</MainLayout>;
  };

  const renderLandingLayouts = () => {
    return (
      <>
        <MainLayout user={user}>
          <Routes>
            <Route index element={<LoginPage />} />
            <Route path='/qr-scan' element={<QRScanPage />} />
            <Route path='/qr-download' element={<QrDownloadPage />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </MainLayout>
      </>
    );
  };

  const renderApp = () => {
    if (readyForRender) {
      return user && user.isActive ? renderHomeLayouts() : renderLandingLayouts();
    } else {
      return (
        <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.spinner} />
        </div>
      );
    }
  };

  useEffect(() => {
    const userId = queryParams.get('userId');
    const accessToken = queryParams.get('accessToken');
    if (isQrUrl && userId && accessToken) {
      dispatch(loginEcvoAsync(userId, accessToken));
    } else {
      dispatch(authorizedAsync());
    }

    if (!isNoNavUrl) {
      dispatch(getSettingInfoAsync());
    }
  }, [dispatch]);

  useEffect(() => {
    isOffline &&
      enqueueSnackbar('ネットワークに接続されていません。インターネットに接続してご利用ください。', {
        variant: 'error',
      });
  }, [isOffline]);

  useEffect(() => {
    // Show message success
    let shown = false;
    for (const where of Object.keys(notifications.messages)) {
      for (const what of Object.keys(notifications.messages[where])) {
        const message = notifications.messages[where][what];
        shown = shown || !!message;
        if (message) {
          enqueueSnackbar(message, { variant: 'success' });
        }
      }
    }

    if (shown) {
      dispatch(resetMessages());
    }

    // Show message error
    shown = false;
    for (const where of Object.keys(notifications.errors)) {
      for (const what of Object.keys(notifications.errors[where])) {
        const error = notifications.errors[where][what];
        shown = shown || !!error;
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      }
    }

    if (shown) {
      dispatch(resetErrors());
    }
    window.scrollTo(0, 0);
  }, [notifications?.messages, notifications?.errors, notifications.systemAlerts, dispatch, enqueueSnackbar]);

  return <>{renderApp()}</>;
}

export default App;
