import React, { useEffect } from 'react';
import { Button, Grid, Paper, Theme, Stack, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { BagCheckIcon, PiggyIcon } from '../../../common';
import { isEmpty } from '../../../core';
import { OrderOutput } from './order-output.component';
import { RebateOutput } from './rebate-output.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoverButton: {
      color: (theme.palette.common as any).color.primary,
      '&:hover': {
        borderColor: 'none',
        backgroundColor: 'white',
        color: (theme.palette.common as any).color.hover,
      },
      transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out',
    },
    linkText: {
      color: (theme.palette.common as any).color.primary,
    },
  }),
);

export default function ExportsComponent() {
  const classes = useStyles();
  const { state } = useLocation();
  const { contentId } = state || {};
  const orderRef = React.useRef<any>(null);
  const rebateRef = React.useRef<any>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const onScroll = (scrollId: string) => {
    switch (scrollId) {
      case 'rebate':
        if (rebateRef && rebateRef.current) {
          setTimeout(() => {
            rebateRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, 100);
        }
        break;
      case 'order':
        if (orderRef && orderRef.current) {
          setTimeout(() => {
            orderRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, 100);
        }
        break;
    }
  };

  useEffect(() => {
    !isEmpty(contentId) && onScroll(contentId);
    navigate(location.pathname, { replace: true });
  }, [contentId, location.pathname, navigate, state]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} mb={3}>
        <Paper sx={{ padding: 1, borderRadius: 1 }} variant='outlined' square>
          <Stack ml={2} direction='row' alignItems='center' gap={3}>
            <Button className={classes.hoverButton} onClick={() => onScroll('order')} variant='text'>
              <BagCheckIcon fontSize='small' viewBox={'0 0 16 16'} color='primary' />
              <Typography className={classes.hoverButton} color='primary' marginLeft={1}>
                注文データ連携ファイル書き出し
              </Typography>
            </Button>
            <Button className={classes.hoverButton} onClick={() => onScroll('rebate')} variant='text'>
              <PiggyIcon fontSize='small' viewBox={'0 0 16 16'} />
              <Typography className={classes.hoverButton} marginLeft={1}>
                栄養指導料支払ファイル書き出し
              </Typography>
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <OrderOutput ref={orderRef} />
      </Grid>
      <Grid item xs={12}>
        <RebateOutput ref={rebateRef} />
      </Grid>
    </Grid>
  );
}
