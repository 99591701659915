/* eslint-disable no-control-regex */
export const EMAIL_REGEX =
  /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[(~!@#$%^&*_+=`|\\(){}[\]:;"'<>,.?/)])[A-Za-z\d(~!@#$%^&*_+=`|\\(){}[\]:;"'<>,.?/)]{6,16}$/;
/**
 * Return true if the format email is correct, otherwise return false
 */
export const isEmail = (value: string) => {
  // eslint-disable-next-line no-control-regex
  const emailRegex = EMAIL_REGEX;
  return emailRegex.test(value);
};

/**
 * Return true if the format password is correct, otherwise return false
 *
 * 6 or more characters
 * 1 Uppercase characters (A-Z)
 * 1 Lowercase characters (a-z)
 * 1 Digits (0-9)
 * 1 Special characters (~!@#$%^&*_-+=`|\(){}[]:;"'<>,.?/)
 */
export const isPassword = (value: string) => {
  const passwordRegex = PASSWORD_REGEX;
  return passwordRegex.test(value);
};

/**
 * Returns true if the a value is an empty object,
 * collection, has no enumerable properties or is any type that is not considered a collection.
 * Check if the provided value is null or if its length is equal to 0.
 * EX:
 * isEmpty([]); // true
 * isEmpty({}); // true
 * isEmpty(''); // true
 * isEmpty([1, 2]); // false
 * isEmpty({ a: 1, b: 2 }); // false
 * isEmpty('text'); // false
 * isEmpty(123); // true - type is not considered a collection
 * isEmpty(true); // true - type is not considered a collection
 *
 * @param {*} val
 */
export const isEmpty = (value: any) => {
  return (
    value === undefined ||
    value === null ||
    // eslint-disable-next-line use-isnan
    Number.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

export const validateFileSize = (files: File[], size: number) => {
  let totalSize = 0;
  files.forEach((file) => {
    totalSize += file.size / 1024 / 1024;
  });

  return totalSize > size;
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

export const getApiError = async (error: any) => {
  let e = error;
  if (error.response) {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      e = JSON.parse(await error.response.data.text())?.error;
    } else {
      e = error.response.data; // data, status, headers
      if (error.response.data && error.response.data.error) {
        e = error.response.data.error; // my app specific keys override
      }
    }
  } else if (error.message) {
    e =
      error.message === 'Network Error'
        ? 'ネットワークに接続されていません。インターネットに接続してご利用ください。'
        : error.message;
  } else {
    e = 'Unknown error occurred';
  }
  return e;
};

export const getApiSuccess = async (response: any) => {
  let e = '';
  if (response) {
    if (
      response.data instanceof Blob &&
      response.data.type &&
      response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      e = JSON.parse(await response.data.text())?.message;
    } else {
      e = response.data; // data, status, headers
    }
  }
  return e;
};

export const validatePosition = (range: number, size: number, position: number) => {
  const maxPosition = range - size;
  return position < 0 ? 0 : position > maxPosition ? maxPosition : position;
};
