import { debounce, trim, isArray, join, isNumber } from 'lodash';

export const debouncedSave = (func: any, delay: number) => {
  return debounce(func, delay);
};

export const strTrim = (str: string) => {
  return trim(str);
};

export const isArrayValue = (array: any) => {
  return isArray(array);
};

export const joinString = (array: any, separator = ',') => {
  return join(array, separator);
};

export const isNumberValue = (number: any) => {
  return isNumber(number);
};
