import saveAs from 'file-saver';
import { AnyAction } from 'redux';
import {
  deleteMessage,
  getMessages,
  getSetting,
  createLogUserBehavior,
  downloadTemplate,
  downloadLogFile,
} from '../../../common';
import { MENU_HREF } from '../../constants';
import { LogInfo, Message, MessagePagination, OrderDataOutput, SearchCondition, SettingInfo } from '../../models';
import { ActionUnion, createAction, ThunkAction } from '../../redux';
import { getApiError } from '../../utils';

export enum NotificationActionTypes {
  RESET_ERRORS = 'RESET_ERRORS',
  RESET_MESSAGES = 'RESET_MESSAGES',
  SHOW_ERROR = 'SHOW_ERROR',
  SHOW_INFO = 'SHOW_INFO',

  SHOW_SYSTEM_ALERT = 'SHOW_SYSTEM_ALERT',
  CLOSE_SYSTEM_ALERT = 'CLOSE_SYSTEM_ALERT',
  RESET_SYSTEM_SUCCESS_ALERT = 'RESET_SYSTEM_SUCCESS_ALERT',

  GET_SETTING_INFO = 'GET_SETTING_INFO',
  GET_SETTING_INFO_SUCCESS = 'GET_SETTING_INFO_SUCCESS',
  GET_SETTING_INFO_FAILURE = 'GET_SETTING_INFO_FAILURE',

  GET_MESSAGES = 'GET_MESSAGES',
  GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE',

  DELETE_MESSAGE = 'DELETE_MESSAGE',
  DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS',
  DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE',

  EXPORT_DATA_EXPORT_ORDER_SUCCESS = 'EXPORT_DATA_EXPORT_ORDER_SUCCESS',
  EXPORT_DATA_EXPORT_REBATE_SUCCESS = 'EXPORT_DATA_EXPORT_REBATE_SUCCESS',
  CREATE_LOG_USER_BEHAVIOR = 'CREATE_LOG_USER_BEHAVIOR',
  CREATE_LOG_USER_BEHAVIOR_SUCCESS = 'CREATE_LOG_USER_BEHAVIOR_SUCCESS',
  CREATE_LOG_USER_BEHAVIOR_FAILURE = 'CREATE_LOG_USER_BEHAVIOR_FAILURE',

  DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE',
  DOWNLOAD_TEMPLATE_SUCCESS = 'DOWNLOAD_TEMPLATE_SUCCESS',
  DOWNLOAD_TEMPLATE_FAILURE = 'DOWNLOAD_TEMPLATE_FAILURE',

  DOWNLOAD_LOG_FILE = 'DOWNLOAD_LOG_FILE',
  DOWNLOAD_LOG_FILE_SUCCESS = 'DOWNLOAD_LOG_FILE_SUCCESS',
  DOWNLOAD_LOG_FILE_FAILURE = 'DOWNLOAD_LOG_FILE_FAILURE',
}

export function resetErrors(): AnyAction {
  const action = {
    type: NotificationActionTypes.RESET_ERRORS,
    payload: {},
  };

  return action;
}

export function resetMessages(): AnyAction {
  const action = {
    type: NotificationActionTypes.RESET_MESSAGES,
    payload: {},
  };

  return action;
}

export function showError(error: string): AnyAction {
  const action = {
    type: NotificationActionTypes.SHOW_ERROR,
    payload: { error },
  };

  return action;
}

export function showInfo(info: string): AnyAction {
  const action = {
    type: NotificationActionTypes.SHOW_INFO,
    payload: { info },
  };

  return action;
}

export const notificationActions = {
  showAlert: (alert: Message) => createAction(NotificationActionTypes.SHOW_SYSTEM_ALERT, { alert }),
  closerAlert: (id: string) => createAction(NotificationActionTypes.CLOSE_SYSTEM_ALERT, { id }),
  resetAlert: (sender: string) => createAction(NotificationActionTypes.RESET_SYSTEM_SUCCESS_ALERT, { sender }),
  getSettingInfo: () => createAction(NotificationActionTypes.GET_SETTING_INFO),
  getSettingInfoSuccess: (settingInfo: SettingInfo) =>
    createAction(NotificationActionTypes.GET_SETTING_INFO_SUCCESS, { settingInfo }),
  getSettingInfoFailure: (error: any) => createAction(NotificationActionTypes.GET_SETTING_INFO_FAILURE, { error }),
  exportOrderDataSuccess: (data: OrderDataOutput) =>
    createAction(NotificationActionTypes.EXPORT_DATA_EXPORT_ORDER_SUCCESS, { data }),
  exportNutritionDataSuccess: (data?: number) =>
    createAction(NotificationActionTypes.EXPORT_DATA_EXPORT_REBATE_SUCCESS, { data }),
  getMessages: () => createAction(NotificationActionTypes.GET_MESSAGES),
  getMessagesSuccess: (message: MessagePagination) =>
    createAction(NotificationActionTypes.GET_MESSAGES_SUCCESS, { message }),
  getMessagesFailure: (error: any) => createAction(NotificationActionTypes.GET_MESSAGES_FAILURE, { error }),
  deleteMessage: () => createAction(NotificationActionTypes.DELETE_MESSAGE),
  deleteMessageSuccess: () => createAction(NotificationActionTypes.DELETE_MESSAGE_SUCCESS),
  deleteMessageFailure: (error: any) => createAction(NotificationActionTypes.DELETE_MESSAGE_FAILURE, { error }),
  createLogUserBehavior: () => createAction(NotificationActionTypes.CREATE_LOG_USER_BEHAVIOR),
  createLogUserBehaviorSuccess: () => createAction(NotificationActionTypes.CREATE_LOG_USER_BEHAVIOR_SUCCESS),
  createLogUserBehaviorFailure: (error: any) =>
    createAction(NotificationActionTypes.CREATE_LOG_USER_BEHAVIOR_FAILURE, { error }),
  downloadTemplate: () => createAction(NotificationActionTypes.DOWNLOAD_TEMPLATE),
  downloadTemplateSuccess: () => createAction(NotificationActionTypes.DOWNLOAD_TEMPLATE_SUCCESS),
  downloadTemplateFailure: (error: any, sender: string) =>
    createAction(NotificationActionTypes.DOWNLOAD_TEMPLATE_FAILURE, { error, sender }),
  downloadLogFile: () => createAction(NotificationActionTypes.DOWNLOAD_LOG_FILE),
  downloadLogFileSuccess: () => createAction(NotificationActionTypes.DOWNLOAD_LOG_FILE_SUCCESS),
  downloadLogFileFailure: (error: any, sender: string) =>
    createAction(NotificationActionTypes.DOWNLOAD_LOG_FILE_FAILURE, { error, sender }),
};

export type NotificationActions = ActionUnion<typeof notificationActions>;

/**
 * Get system setting
 * @returns
 */
export const getSettingInfoAsync = (): ThunkAction => {
  return async (dispatch) => {
    dispatch(notificationActions.getSettingInfo());
    try {
      const data = await getSetting();
      dispatch(notificationActions.getSettingInfoSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(notificationActions.getSettingInfoFailure(errorText));
    }
  };
};

/**
 * Get all message
 * @returns
 */
export const getMessagesAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(notificationActions.getMessages());
    try {
      const data = await getMessages(condition);
      dispatch(notificationActions.getMessagesSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(notificationActions.getMessagesFailure(errorText));
    }
  };
};

/**
 * Delete message
 * @returns
 */
export const deleteMessageAsync = (messageId: string): ThunkAction => {
  return async (dispatch) => {
    dispatch(notificationActions.deleteMessage());
    try {
      await deleteMessage(messageId);
      dispatch(notificationActions.deleteMessageSuccess());
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(notificationActions.deleteMessageFailure(errorText));
    }
  };
};

/**
 * Create log user behavior
 * @returns
 */
export const createLogUserBehaviorAsync = (logs: LogInfo): ThunkAction => {
  return async (dispatch) => {
    dispatch(notificationActions.createLogUserBehavior());
    try {
      await createLogUserBehavior(logs);
      dispatch(notificationActions.createLogUserBehaviorSuccess());
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(notificationActions.createLogUserBehaviorFailure(errorText));
    }
  };
};

/**
 * Download template
 * @returns
 */
const TEMPLATE_NAME = {
  '1': '営業日カレンダー_サンプル.xlsx',
  '2': '商品体系４ＣＤ情報_サンプル.xlsx',
  '3': '栄養指導料率設定情報_サンプル.xlsx',
  '4': '購入上限設定情報_サンプル.xlsx',
  '5': '在庫情報_サンプル.xlsx',
};
const getSender = (type: '1' | '2' | '3' | '4' | '5') => {
  switch (type) {
    case '1':
    case '2':
    case '5':
      return MENU_HREF.IMPORT;
    case '3':
      return MENU_HREF.REBATE_AMOUNT;
    case '4':
      return MENU_HREF.PURCHASE_LIMIT;
  }
};
export const downloadTemplateAsync = (type: '1' | '2' | '3' | '4' | '5'): ThunkAction => {
  return async (dispatch) => {
    dispatch(notificationActions.downloadTemplate());
    try {
      const response = await downloadTemplate(type);
      if (response.status === 200) {
        const blob = new Blob([response.data]);
        saveAs(blob, TEMPLATE_NAME[type]);
        dispatch(notificationActions.downloadTemplateSuccess());
      } else return;
    } catch (error) {
      const errorText = await getApiError(error);
      const sender = getSender(type);
      dispatch(notificationActions.downloadTemplateFailure(errorText, sender));
    }
  };
};

/**
 * Download log file
 * @returns
 */
export const downloadLogFileAsync = (file: any, sender: string): ThunkAction => {
  return async (dispatch) => {
    dispatch(notificationActions.downloadLogFile());
    try {
      const response = await downloadLogFile(file);
      if (response.status === 200) {
        const blob = new Blob([response.data]);
        saveAs(blob, file.fileName);
        dispatch(notificationActions.downloadLogFileSuccess());
      } else return;
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(notificationActions.downloadLogFileFailure(errorText, sender));
    }
  };
};
