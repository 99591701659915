import AccountBoxIcon from '@mui/icons-material/AccountBox';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Grid } from '@mui/material';
import { PasswordFieldControl, TextFieldControl } from '../../../common';

interface Props {
  form: any;
}

export const LoginFormComponent = (props: Props) => {
  const { form } = props;
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' rowSpacing={2}>
      <Grid item xs={12}>
        <TextFieldControl
          fullWidth
          id='username'
          name='username'
          type='text'
          label='ユーザー名'
          value={form.values.username || ''}
          autoComplete='off'
          endAdornment={<AccountBoxIcon />}
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          error={form.touched.username && Boolean(form.errors.username)}
          errorText={form.touched.username && form.errors.username}
        />
      </Grid>
      <Grid item xs={12}>
        <PasswordFieldControl
          fullWidth
          id='password'
          name='password'
          type='password'
          label='パスワード'
          autoComplete='new-password'
          value={form.values.password || ''}
          endAdornment={<VpnKeyIcon />}
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          error={form.touched.password && Boolean(form.errors.password)}
          errorText={form.touched.password && form.errors.password}
        />
      </Grid>
    </Grid>
  );
};
