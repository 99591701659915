import { Button, CircularProgress, ButtonProps } from '@mui/material';
import React from 'react';

interface ExportButtonProps {
  loading: boolean;
}

function ExportButtonComponent(props: ExportButtonProps & ButtonProps) {
  const { loading = false, children, variant, type, disabled, classes, onClick } = props;

  return (
    <Button
      onClick={onClick}
      variant={variant}
      type={type}
      disabled={disabled}
      classes={classes}
      startIcon={loading && <CircularProgress color='inherit' size={25} />}
    >
      {children}
    </Button>
  );
}

export const ExportButton = React.memo(ExportButtonComponent);
