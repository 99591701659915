import { Grid, InputBaseProps, MenuItem, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { trim } from 'lodash';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#000000',
      fontWeight: 600,
      marginBottom: '0.25em',
    },
  }),
);

export interface OptionData {
  code: string;
  value: string;
}
interface CustomInputProps {
  label?: string;
  errorText?: any;
  defaultValue?: OptionData;
  options: OptionData[];
  onSelectedChange?: (value: string) => void;
}

export const SelectFieldControl = React.memo((props: InputBaseProps & CustomInputProps) => {
  const classes = useStyles();
  const {
    id,
    name,
    placeholder,
    value,
    defaultValue,
    onChange,
    onBlur,
    error,
    errorText,
    label,
    readOnly,
    options,
    disabled,
    onSelectedChange,
    className,
  } = props;

  const handleLostFocus = (e: any) => {
    e.target.value = trim(e.target.value);
    if (typeof onChange === 'function') onChange(e);
    if (typeof onBlur === 'function') onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') onChange(e);
    if (typeof onSelectedChange === 'function') onSelectedChange(e.target.value);
  };
  return (
    <Grid container direction='column' alignItems='flex-start'>
      {label && <Typography className={classes.label}>{label}</Typography>}

      <TextField
        fullWidth
        error={error}
        id={id}
        name={name}
        placeholder={placeholder}
        select
        value={value}
        onChange={handleChange}
        onBlur={handleLostFocus}
        size='small'
        disabled={disabled}
        helperText={errorText}
        inputProps={{
          readOnly: readOnly,
        }}
        className={className}
      >
        {defaultValue && (
          <MenuItem
            key={defaultValue?.code}
            value={defaultValue?.code}
            sx={{
              whiteSpace: 'break-spaces',
            }}
          >
            {defaultValue?.value}
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem
            key={option.code}
            value={option.code}
            sx={{
              // maxWidth: 600,
              whiteSpace: 'break-spaces',
            }}
          >
            {option.value}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
});
