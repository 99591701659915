import { SettingEventLogActions, SettingEventLogActionTypes } from './actions';
import { SettingEventLogState } from './states';

const defaultState: SettingEventLogState = {
  fetching: false,
  eventLogs: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  exportFetching: false,
};

export const settingEventLogReducer = (state = defaultState, action: SettingEventLogActions): SettingEventLogState => {
  switch (action.type) {
    case SettingEventLogActionTypes.GET_LOGS:
      return {
        ...state,
        fetching: true,
      };
    case SettingEventLogActionTypes.GET_LOGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        eventLogs: action.payload.data,
      };
    case SettingEventLogActionTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        fetching: false,
      };
    case SettingEventLogActionTypes.EXPORT_LOG:
      return {
        ...state,
        exportFetching: true,
      };
    case SettingEventLogActionTypes.EXPORT_LOG_SUCCESS:
    case SettingEventLogActionTypes.EXPORT_LOG_FAILURE:
      return {
        ...state,
        exportFetching: false,
      };
    default:
      return state;
  }
};
