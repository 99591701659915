import React, { useCallback, useEffect } from 'react';
import { Button, Grid, Paper, Theme, Stack, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { BagCheckIcon, CalendarEventIcon, ShopWindowIcon } from '../../../common';
import BusinessDayCalendar from './business-day-calendar.component';
import InventoryInformation from './inventory-information.component';
import ProductGroupInformation from './product-group-information.component';
import { isEmpty } from '../../../core';
import { useDispatch } from 'react-redux';
import { getLogsAsync } from '../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoverButton: {
      color: (theme.palette.common as any).color.primary,
      '&:hover': {
        borderColor: 'none',
        backgroundColor: 'white',
        color: (theme.palette.common as any).color.hover,
      },
      transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out',
    },
    linkText: {
      color: (theme.palette.common as any).color.primary,
    },
  }),
);

export default function ImportsComponents() {
  const classes = useStyles();
  const { state } = useLocation();
  const { contentId } = state || {};
  const productRef = React.useRef<any>(null);
  const businessRef = React.useRef<any>(null);
  const inventoryRef = React.useRef<any>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onScroll = (scrollId: string) => {
    switch (scrollId) {
      case 'product':
        if (productRef && productRef.current) {
          setTimeout(() => {
            productRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, 100);
        }
        break;
      case 'inventory':
        if (inventoryRef && inventoryRef.current) {
          setTimeout(() => {
            inventoryRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, 100);
        }
        break;
      case 'businessCalendar':
        if (businessRef && businessRef.current) {
          setTimeout(() => {
            businessRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, 100);
        }
        break;
    }
  };

  const onRefresh = useCallback(() => {
    dispatch(getLogsAsync());
  }, [dispatch]);

  useEffect(() => {
    !isEmpty(contentId) && onScroll(contentId);
    navigate(location.pathname, { replace: true });
  }, [contentId, location.pathname, navigate, state]);

  useEffect(() => {
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <Typography sx={{ marginBottom: 2 }}>
          インポートできるファイルは、EXCEL
          ファイル（.xlsx）になります。旧形式のファイルインポートは、明記されていない限り対応できませんのでご注意ください。
        </Typography>
        <Paper sx={{ padding: 1, borderRadius: 1 }} variant='outlined' square>
          <Stack ml={2} direction='row' alignItems='center' gap={3}>
            <Button className={classes.hoverButton} onClick={() => onScroll('goods')} variant='text'>
              <BagCheckIcon fontSize='small' viewBox={'0 0 16 16'} color='primary' />
              <Typography className={classes.hoverButton} color='primary' marginLeft={1}>
                商品体系４情報
              </Typography>
            </Button>
            <Button className={classes.hoverButton} onClick={() => onScroll('inventory')} variant='text'>
              <ShopWindowIcon fontSize='small' viewBox={'0 0 16 16'} />
              <Typography className={classes.hoverButton} marginLeft={1}>
                在庫情報
              </Typography>
            </Button>
            <Button className={classes.hoverButton} onClick={() => onScroll('businessCalendar')} variant='text'>
              <CalendarEventIcon fontSize='small' viewBox={'0 0 16 16'} color='primary' />
              <Typography className={classes.hoverButton} marginLeft={1}>
                営業日カレンダー
              </Typography>
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <ProductGroupInformation onRefresh={onRefresh} ref={productRef} />
      </Grid>
      <Grid item xs={12}>
        <InventoryInformation onRefresh={onRefresh} ref={inventoryRef} />
      </Grid>
      <Grid item xs={12}>
        <BusinessDayCalendar onRefresh={onRefresh} ref={businessRef} />
      </Grid>
    </Grid>
  );
}
