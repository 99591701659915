import { AnyAction } from 'redux';
import { QrDocumentActionTypes } from '../../qr-document/data-access/actions';
import { QrCodeActionTypes } from './actions';
import { EmbeddedQrCodeInfo, QrCodeState } from './states';

const defaultState: QrCodeState = {
  isNavigate: false,
  fetching: false,
  isOpenDialog: false,
  base64Original: '',
  base64WithEmbedded: '',
  currentPage: 1,
  embeddedQrCodeInfo: [],
  embeddedQrCodeInfoCurrent: [],
  hospitalBrochure: {},
  categoryOptions: [],
  fileCheckStatus: {
    status: false,
    action: 'none',
  },
};

export const qrCodeReducer = (state = defaultState, action: AnyAction): QrCodeState => {
  switch (action.type) {
    case QrCodeActionTypes.OPEN_DIALOG:
      return {
        ...state,
        isOpenDialog: true,
      };
    case QrCodeActionTypes.CLOSE_DIALOG:
      return {
        ...state,
        isOpenDialog: false,
      };
    case QrCodeActionTypes.SET_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.payload.pageNumber,
      };
    case QrCodeActionTypes.SET_PDF_SIZE_RENDER:
      const newItem: EmbeddedQrCodeInfo = {
        pdfHeightOriginal: 0,
        pdfWidthOriginal: 0,
        pdfHeightRender: action.payload.height,
        pdfWidthRender: action.payload.width,
        qrCodeHeight: 0,
        qrCodeWidth: 0,
        xPosition: 0,
        yPosition: 0,
        pageNumber: action.payload.currentPage,
        isQrCode: false,
      };
      const isExisted = state.embeddedQrCodeInfo.find((item) => item.pageNumber === newItem.pageNumber);
      const updatedItems = isExisted ? [...state.embeddedQrCodeInfo] : [...state.embeddedQrCodeInfo, newItem];
      return {
        ...state,
        embeddedQrCodeInfo: updatedItems,
      };
    case QrCodeActionTypes.GENERATE_QR_CODE:
      const currentQrCodeItem = state.embeddedQrCodeInfo.find((item) => item.pageNumber === action.payload.currentPage);
      if (currentQrCodeItem) {
        const newQrCodeItem: EmbeddedQrCodeInfo = {
          ...currentQrCodeItem,
          qrCodeHeight: 100,
          qrCodeWidth: 100,
          xPosition: 10,
          yPosition: 10,
          isQrCode: true,
        };
        const updatedQrCodeItems = state.embeddedQrCodeInfo.map((item) =>
          item.pageNumber === newQrCodeItem.pageNumber ? newQrCodeItem : item,
        );
        return {
          ...state,
          embeddedQrCodeInfo: updatedQrCodeItems,
        };
      }
      return {
        ...state,
      };
    case QrCodeActionTypes.REMOVE_QR_CODE:
      const removedQrCodeItems = state.embeddedQrCodeInfo.map((item) =>
        item.pageNumber === action.payload.currentPage ? { ...item, isQrCode: false } : item,
      );
      return {
        ...state,
        embeddedQrCodeInfo: removedQrCodeItems,
      };
    case QrCodeActionTypes.SET_QR_CODE_STATE:
      return {
        ...state,
        embeddedQrCodeInfo: action.payload.state,
      };
    case QrCodeActionTypes.SET_QR_CODE_STATE_CURRENT:
      return {
        ...state,
        embeddedQrCodeInfoCurrent: action.payload.state,
      };
    case QrCodeActionTypes.SET_QR_CODE_SIZE:
      const currentQrCodeSize = state.embeddedQrCodeInfo.find((item) => item.pageNumber === action.payload.currentPage);
      if (currentQrCodeSize) {
        const newQrCodeSize: EmbeddedQrCodeInfo = {
          ...currentQrCodeSize,
          qrCodeHeight: action.payload.qrCodeInfo.height,
          qrCodeWidth: action.payload.qrCodeInfo.width,
          xPosition: action.payload.qrCodeInfo.x,
          yPosition: action.payload.qrCodeInfo.y,
        };
        const updatedQrCodeSizes = state.embeddedQrCodeInfo.map((item) =>
          item.pageNumber === newQrCodeSize.pageNumber ? newQrCodeSize : item,
        );
        return {
          ...state,
          embeddedQrCodeInfo: updatedQrCodeSizes,
        };
      }
      return {
        ...state,
      };
    case QrCodeActionTypes.SET_PDF_FILE:
      return {
        ...state,
        base64Original: action.payload.base64Original,
      };
    case QrCodeActionTypes.REMOVE_EMBEDDED_FILE:
      return {
        ...state,
        base64WithEmbedded: '',
      };
    case QrCodeActionTypes.EMBEDDED_QR_CODE:
      return {
        ...state,
        fetching: true,
      };
    case QrCodeActionTypes.EMBEDDED_QR_CODE_SUCCESS:
      return {
        ...state,
        isOpenDialog: false,
        fetching: false,
        base64WithEmbedded: action.payload.data,
      };
    case QrCodeActionTypes.EMBEDDED_QR_CODE_FAILURE:
      return {
        ...state,
        isOpenDialog: false,
        fetching: false,
      };
    case QrCodeActionTypes.GET_HOSPITAL_BROCHURE_SUCCESS:
      return {
        ...state,
        hospitalBrochure: action.payload.data,
      };
    case QrCodeActionTypes.GET_HOSPITAL_BROCHURE_FAILURE:
      return {
        ...state,
        isNavigate: true,
      };
    case QrCodeActionTypes.GET_CATEGORY_OPTIONS_SUCCESS:
      return {
        ...state,
        categoryOptions: action.payload.data,
      };
    case QrCodeActionTypes.GET_CATEGORY_OPTIONS_FAILURE:
      return {
        ...state,
        isNavigate: true,
      };
    case QrDocumentActionTypes.GET_QR_DOCUMENTS:
      return defaultState;
    case QrCodeActionTypes.REGISTER_HOSPITAL_BROCHURE_SUCCESS:
      return {
        ...state,
        isNavigate: true,
      };
    case QrCodeActionTypes.UPDATE_HOSPITAL_BROCHURE_SUCCESS:
      return {
        ...state,
        isNavigate: true,
      };

    case QrCodeActionTypes.CHECK_PDF_FILE:
      return {
        ...state,
        fileCheckStatus: {
          status: false,
          action: 'init',
        },
      };
    case QrCodeActionTypes.CHECK_PDF_FILE_SUCCESS:
      return {
        ...state,
        fileCheckStatus: {
          status: action.payload.data,
          action: 'ok',
        },
      };
    case QrCodeActionTypes.CHECK_PDF_FILE_FAILURE:
      return {
        ...state,
        fileCheckStatus: {
          status: false,
          action: 'failed',
        },
      };

    default:
      return state;
  }
};
