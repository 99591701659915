import { useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useSelector, useDispatch } from 'react-redux';
import { CombinedState } from '../../../core';
import { qrCodeActions } from '../data-access/actions';

// Set the worker source to the pdf.js file for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const pdfjsOptions = {
  cMapUrl: '/cmaps/',
  cMapPacked: true,
};

const PDFEdit = (props: any) => {
  const { file, pageNumber, setNumPages } = props;
  const dispatch = useDispatch();
  const { embeddedQrCodeInfo, currentPage } = useSelector((state: CombinedState) => state['qrCode']);
  const ref = useRef<any>();

  const onPageLoadSuccess = () => {
    if (ref) {
      dispatch(qrCodeActions.setPDFSizeRender(ref?.current?.clientHeight, ref?.current?.clientWidth, currentPage));
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <div className='pdf-container'>
      {file && (
        <Document file={file} loading='' onLoadSuccess={onDocumentLoadSuccess} options={pdfjsOptions}>
          <Page
            canvasRef={ref}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            width={embeddedQrCodeInfo.pdfWidthOriginal}
            height={embeddedQrCodeInfo.pdfHeightOriginal}
            className='pdf-viewer-center'
            onLoadSuccess={onPageLoadSuccess}
            loading=''
          />
        </Document>
      )}
    </div>
  );
};

export default PDFEdit;
