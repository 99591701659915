import { OptionData } from './../../common/controls/select-field.component';
import { isEmpty } from './validations';
import { camelCase, snakeCase } from 'lodash';

const snakeToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeToCamelCase(v));
  }

  if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: snakeToCamelCase(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

const camelToSnakeCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelToSnakeCase(v));
  }

  if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      let keyTemp = snakeCase(key);
      if (
        ['password1', 'password2', 'new_password1', 'new_password2'].includes(
          key
        )
      ) {
        keyTemp = key;
      }
      return {
        ...result,
        [keyTemp]: camelToSnakeCase(obj[key]),
      };
    }, {});
  }
  return obj;
};

const getValueFromCode = (
  code: string,
  data: { code: string; value: string }[]
) => {
  if (!isEmpty(data)) {
    const filters = data.filter((category) => category.code === code);
    if (filters && filters.length > 0) {
      return filters[0].value;
    }
  }
  return code;
};

const getValueFromArrayCode = (
  codes: string[],
  data: { code: string; value: string }[]
) => {
  if (!isEmpty(data) && !isEmpty(codes)) {
    const results: string[] = [];
    codes.forEach((code) => {
      const filters = data.filter((category) => category.code === code);
      if (filters && filters.length > 0) {
        results.push(filters[0].value);
      }
    });
    return results.join('、');
  }
  return codes;
};

const convertModelToOptions = (data: any[], matching: any) => {
  return data.map(
    (item) =>
      ({
        code: item[matching['code']],
        value: item[matching['value']],
      } as OptionData)
  );
};

// eslint-disable-next-line import/prefer-default-export
export {
  snakeToCamelCase,
  camelToSnakeCase,
  getValueFromCode,
  getValueFromArrayCode,
  convertModelToOptions,
};
