import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { getLogLevel, getLogTitle, LogLevelIcon, LogMessage } from '../../../common';
import { CombinedState, LogInfo, MENU_HREF } from '../../../core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    historyFound: {
      color: `${(theme.palette.common as any).color.outlined}`,
    },
    historyError: {
      color: `${(theme.palette.common as any).color.textError}`,
    },
    container: {
      minHeight: '30em',
    },
    table: {
      width: '100%',
      minWidth: '60em',
      tableLayout: 'fixed',
      '& a': {
        color: (theme.palette.common as any).color.primary,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    borderNone: {
      border: 'none',
    },
  }),
);

export const TableEventLogComponent = (props: any) => {
  const classes = useStyles();
  const { isChangeCount, hasSearchCriteria } = props;
  const { eventLogs, fetching } = useSelector((state: CombinedState) => state['settingEventLog']);

  return (
    <>
      {(!isChangeCount || !fetching) && hasSearchCriteria() && (
        <>
          {eventLogs.count === 0 ? (
            <Typography className={classes.historyError}>指定された条件では履歴が見付かりません。</Typography>
          ) : (
            <Typography className={classes.historyFound}>{`${eventLogs.count}件の履歴が見付かりました。`}</Typography>
          )}
        </>
      )}

      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width='180px'>日時</TableCell>
              <TableCell width='25%'>処理</TableCell>
              <TableCell width='180px'>レベル</TableCell>
              <TableCell>概要</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventLogs.count === 0 ? (
              <TableRow>
                <TableCell className={classes.borderNone} colSpan={4}>
                  <Typography mt={2}>ログの記録はありません。</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {eventLogs.results?.map((row: LogInfo) => (
                  <TableRow key={row.logId} hover>
                    <TableCell>{row.recordDatetime}</TableCell>
                    <TableCell>{getLogTitle(row)}</TableCell>
                    <TableCell align='left'>
                      <Box className='lsc-table-icon' display='flex' alignItems='center'>
                        <LogLevelIcon status={row.logLevel} />
                        <Typography ml={1} component='span'>
                          {getLogLevel(row.logLevel)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <LogMessage sender={MENU_HREF.EVENT_LOG} log={row} />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
