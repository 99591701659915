import { Box, Button, Input, InputBaseProps, Typography, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';

interface FileInputProps {
  onChange: (file: File) => void;
  buttonName?: string;
  onImport: () => void;
  selectFile?: string;
  serverError?: string;
  accept?: string;
  allowExtensions?: string[];
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      position: 'relative',
    },
    error: {
      margin: 0,
      fontSize: '0.75rem',
      marginTop: `3px !important`,
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
      color: (theme.palette.common as any).color.textError,
    },
    singleLabel: {
      borderRadius: 0,
      borderColor: (theme.palette.common as any).color.borderGray,
      borderStyle: 'solid',
      borderWidth: 1,
      paddingLeft: '1em',
      paddingRight: '1em',
      alignItems: 'center',
      display: 'flex',
      minWidth: '25rem',
      color: `${(theme.palette.common as any).color.textPrimary}`,
    },
    singleTextField: {
      height: '100%',
      left: 0,
      opacity: 0,
      outline: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      display: 'none',
    },
    chooseButton: {
      backgroundColor: `${(theme.palette.common as any).color.gray} `,
      border: '1px solid',
      borderColor: `${(theme.palette.common as any).color.borderGray} `,
      color: `${(theme.palette.common as any).color.textPrimary}`,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      textTransform: 'inherit',
      borderRightWidth: 0,
      marginRight: -1,
      boxShadow: 'none',
      '&:hover, &:active': {
        backgroundColor: `${(theme.palette.common as any).color.gray}`,
        borderColor: `${(theme.palette.common as any).color.borderGray}`,
        color: (theme.palette.common as any).color.textPrimary,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: 'none',
      },
      fontWeight: 400,
      zIndex: 99,
    },
    importButton: {
      backgroundColor: `${(theme.palette.common as any).color.gray}`,
      border: '1px solid',
      borderColor: `${(theme.palette.common as any).color.borderGray}`,
      color: `${(theme.palette.common as any).color.textPrimary}`,
      textTransform: 'inherit',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeftWidth: 0,
      marginLeft: -1,
      boxShadow: 'none',
      minWidth: '10em',
      '&:hover, &:active': {
        backgroundColor: `${(theme.palette.common as any).color.gray} `,
        borderColor: `${(theme.palette.common as any).color.borderGray}`,
        color: (theme.palette.common as any).color.textPrimary,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        boxShadow: 'none',
      },
      fontWeight: 400,
      zIndex: 99,
    },
  }),
);

function FileInputComponent(props: FileInputProps & InputBaseProps) {
  const classes = useStyles();
  const { buttonName, selectFile, id, name, serverError, accept, onChange, onImport, loading = false } = props;
  const inputRef = useRef<any>(null);
  const [fileInfo, setFileInfo] = useState<any>(undefined);
  const [fileError, setFileError] = useState<string>('');
  const [uploading, setUploading] = useState<boolean>(false);

  const handleChange = (event: any) => {
    setFileError('');
    const files = event.target.files || event.dataTransfer.files;
    if (files.length === 0) {
      inputRef.current.value = '';
      return false;
    } else {
      const file = files[0];
      setFileInfo(file);
      onChange(file);
      inputRef.current.value = '';
      return true;
    }
  };

  const openFileDialog = () => {
    inputRef.current && inputRef.current?.click();
  };

  const handleImport = () => {
    onImport();
  };

  useEffect(() => {
    setFileError(serverError ?? '');
  }, [serverError]);

  useEffect(() => {
    setUploading(loading);
  }, [loading]);

  return (
    <>
      <Box className={classes.content}>
        <Button classes={{ root: classes.chooseButton }} onClick={openFileDialog} variant='contained'>
          {selectFile ?? 'ファイルを選択'}
        </Button>
        <Input
          inputRef={inputRef}
          id={id}
          name={name}
          type='file'
          classes={{ root: classes.singleTextField }}
          inputProps={{
            accept: accept,
          }}
          onChange={handleChange}
        />
        <Typography className={classes.singleLabel} component='span' onClick={openFileDialog}>
          {fileInfo?.name ?? '選択されていません'}
        </Typography>
        <Button
          classes={{ root: classes.importButton }}
          disabled={!fileInfo || uploading}
          onClick={handleImport}
          variant='contained'
          startIcon={uploading && <CircularProgress color='inherit' size={25} />}
        >
          {buttonName ?? `インポート`}
        </Button>
      </Box>
      {fileError && <Typography className={classes.error}>{fileError}</Typography>}
    </>
  );
}

export const FileInputControl = React.memo(FileInputComponent);
