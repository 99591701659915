import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

// Set the worker source to the pdf.js file for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const pdfjsOptions = {
  cMapUrl: '/cmaps/',
  cMapPacked: true,
};

interface Props {
  file: string;
}

const PDFViewer = (props: Props) => {
  const { file } = props;

  return (
    <div className='pdf-container'>
      {file && (
        <Document file={file} loading='' options={pdfjsOptions}>
          <Page
            pageNumber={1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            width={200}
            height={400}
            loading=''
          />
        </Document>
      )}
    </div>
  );
};

export default PDFViewer;
