import { Button, Stack, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { MENU_HREF } from '../../../core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 'calc(100vh - 200px)',
    },
    errorContainer: {
      textAlign: 'center',
      fontSize: 180,
      fontFamily: 'Catamaran, sans-serif',
      fontWeight: 800,
      margin: '0 auto',
    },
    '@keyframes shadowsDancing': {
      '0%': {
        boxShadow:
          'inset 30px 0 0 rgba(209, 242, 165, 0.4), inset 0 30px 0 rgba(239, 250, 180, 0.4), inset -30px 0 0 rgba(255, 196, 140, 0.4), inset 0 -30px 0 rgba(245, 105, 145, 0.4)',
      },
      '25% ': {
        boxShadow:
          'inset 30px 0 0 rgba(245, 105, 145, 0.4), inset 0 30px 0 rgba(209, 242, 165, 0.4), inset -30px 0 0 rgba(239, 250, 180, 0.4), inset 0 -30px 0 rgba(255, 196, 140, 0.4);',
      },
      '50%': {
        boxShadow:
          'inset 30px 0 0 rgba(255, 196, 140, 0.4), inset 0 30px 0 rgba(245, 105, 145, 0.4), inset -30px 0 0 rgba(209, 242, 165, 0.4), inset 0 -30px 0 rgba(239, 250, 180, 0.4)',
      },
      '75%': {
        boxShadow:
          'inset 30px 0 0 rgba(239, 250, 180, 0.4), inset 0 30px 0 rgba(255, 196, 140, 0.4), inset -30px 0 0 rgba(245, 105, 145, 0.4), inset 0 -30px 0 rgba(209, 242, 165, 0.4)',
      },
      '100%': {
        boxShadow:
          'inset 30px 0 0 rgba(209, 242, 165, 0.4), inset 0 30px 0 rgba(239, 250, 180, 0.4), inset -30px 0 0 rgba(255, 196, 140, 0.4), inset 0 -30px 0 rgba(245, 105, 145, 0.4)',
      },
    },

    '@keyframes colorDancing': {
      '0%': {
        color: '#D1F2A5',
      },
      '25%': {
        color: '#F56991',
      },
      '50%': {
        color: '#FFC48C',
      },
      '75%': {
        color: '#EFFAB4',
      },
      '100%': {
        color: '#D1F2A5',
      },
    },

    '@keyframes colorDancing2': {
      '0%': {
        color: '#FFC48C',
      },

      '25%': {
        color: '#EFFAB4',
      },

      '50%': {
        color: '#D1F2A5',
      },
      '75%': {
        color: '#F56991',
      },
      '100%': {
        color: '#FFC48C',
      },
    },
    firstText: {
      display: 'inline-block',
      position: 'relative',
      verticalAlign: 'middle',
      color: '#D1F2A5',
      animation: '$colorDancing 4s infinite',
      lineHeight: 0.7,
      fontSize: 'inherit',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
    },
    secondText: {
      display: 'inline-block',
      position: 'relative',
      verticalAlign: 'middle',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      lineHeight: 0.7,
      width: 120,
      height: 120,
      marginLeft: '3rem',
      marginRight: '3rem',
      borderRadius: 999,
      '&::before, &:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 'inherit',
        height: 'inherit',
        borderRadius: 999,
        boxShadow:
          'inset 30px 0 0 rgba(209, 242, 165, 0.4), inset 0 30px 0 rgba(239, 250, 180, 0.4), inset -30px 0 0 rgba(255, 196, 140, 0.4), inset 0 -30px 0 rgba(245, 105, 145, 0.4)',
        animation: '$shadowsDancing 4s infinite',
      },
      '&::before': {
        '-webkit-transform': 'rotate(45deg)',
        '-moz-transform': 'rotate(45deg)',
        transform: 'rotate(45deg)',
      },
    },
    thirdText: {
      display: 'inline-block',
      position: 'relative',
      verticalAlign: 'middle',
      color: '#F56991',
      animation: '$colorDancing2 4s infinite',
      lineHeight: 0.7,
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },

    screenReaderText: {
      position: 'absolute',
      top: '-9999em',
      left: '-9999em',
      color: '#FFB485',
      display: 'inline-block',
      verticalAlign: 'middle',
      lineHeight: 0.7,
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    buttonOutlined: {
      marginTop: '1rem',
      float: 'right',
      '&:active': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
      '&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
  }),
);

export const NotFoundPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onGoHome = () => {
    navigate(MENU_HREF.DASHBOARD);
  };

  return (
    <Stack className={classes.container} flexDirection='column' justifyContent='center' alignItems='center'>
      <Box className={classes.errorContainer}>
        <Typography className={classes.firstText} component='span'>
          4
        </Typography>
        <Typography component='span' className={classes.secondText}>
          <Typography component='span' className={classes.screenReaderText}>
            0
          </Typography>
        </Typography>
        <Typography component='span' className={classes.thirdText}>
          4
        </Typography>
      </Box>

      <Box>
        <Typography variant='h3'>Not Found</Typography>
        <Typography>指定されたページが見付かりませんでした。</Typography>
        <Typography> URL に誤りがあるか、ページが削除または移動された可能性があります。</Typography>
        <Button onClick={onGoHome} classes={{ root: classes.buttonOutlined }} variant='outlined'>
          管理画面に戻る
        </Button>
      </Box>
    </Stack>
  );
};
