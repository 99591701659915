import { apiService, SearchCondition } from '../../../core';

export const exportOrderData = async (condition: SearchCondition) => {
  return await apiService.post('/export/order_data', { ...condition.filter, caller: 2 });
};

export const exportRebateData = async (condition: SearchCondition) => {
  return await apiService.post(
    '/export/nutrition_guidance_fee',
    { ...condition.filter }
  );
};
