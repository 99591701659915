import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import store from 'store';
import { camelToSnakeCase, snakeToCamelCase } from '../utils';
import { KS_LSC_EXT_AUTH_TOKEN } from './config';
class HttpClient {
  protected readonly instance: AxiosInstance;

  constructor(axiosConfig: AxiosRequestConfig) {
    this.instance = axios.create(axiosConfig);
    this.instance.defaults.withCredentials = true;
    this.instance.defaults.xsrfCookieName = 'csrftoken';
    this.instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

    this.handleRequest();
    this.handleResponse();
  }

  private handleRequest() {
    this.instance.interceptors.request.use(
      (config) => {
        const token = store.get(KS_LSC_EXT_AUTH_TOKEN);
        config.headers!.Authorization = token ? `Token ${token}` : '';
        config.params = camelToSnakeCase(config.params);
        config.data = camelToSnakeCase(config.data);
        if (!window.navigator.onLine) {
          throw new axios.Cancel('ネットワークに接続されていません。インターネットに接続してご利用ください。');
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  private handleResponse() {
    this.instance.interceptors.response.use(
      (response) => {
        response.data = snakeToCamelCase(response.data);
        return response;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }
}

export default HttpClient;
