import {
  Grid,
  InputBaseProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { trim } from 'lodash';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#000000',
      fontWeight: 600,
      marginBottom: '0.25em',
    },
  })
);

interface CustomInputProps {
  label?: string;
  errorText?: any;
  maxLength?: number;
  rows?: number;
}

export const MultilineTextFieldControl = React.memo(
  (props: InputBaseProps & CustomInputProps) => {
    const classes = useStyles();
    const {
      id,
      name,
      placeholder,
      value,
      type,
      onChange,
      onBlur,
      error,
      errorText,
      maxLength,
      label,
      readOnly,
      disabled,
      autoComplete,
      rows = 4,
    } = props;

    const handleLostFocus = (e: any) => {
      e.target.value = trim(e.target.value);
      if (typeof onChange === 'function') onChange(e);
      if (typeof onBlur === 'function') onBlur(e);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === 'function') onChange(e);
    };
    return (
      <Grid container direction='column' alignItems='flex-start'>
        {label && <Typography className={classes.label}>{label}</Typography>}
        <TextField
          fullWidth
          multiline
          rows={rows}
          error={error}
          helperText={errorText}
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          autoComplete={autoComplete}
          size='small'
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleLostFocus}
          inputProps={{
            maxLength: maxLength || undefined,
            readOnly: readOnly,
          }}
        />
      </Grid>
    );
  }
);
