import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Box } from '@mui/material';

// Set the worker source to the pdf.js file for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const pdfjsOptions = {
  cMapUrl: '/cmaps/',
  cMapPacked: true,
};

interface Props {
  file: string;
  fileError: boolean;
}

const PDFViewer = (props: Props) => {
  const { fileError, file } = props;

  return (
    <div className='pdf-container'>
      {!fileError && file && (
        <Box sx={{ mr: 2 }}>
          <Document file={file} loading='' options={pdfjsOptions}>
            <Page
              pageNumber={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={200}
              height={400}
              loading=''
            />
          </Document>
        </Box>
      )}
    </div>
  );
};

export default PDFViewer;
