import * as React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';
import { CombinedState } from '../../core';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      zIndex: 10000,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    spinner: {
      width: '64px',
      height: '64px',
    },
  }),
);

const LoadingComponent = () => {
  const classes = useStyles();
  const { isLoading } = useSelector((state: CombinedState) => state['notifications']);

  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress className={classes.spinner} />
    </Backdrop>
  );
};

export const Loading = React.memo(LoadingComponent);
