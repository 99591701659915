import { Grid, Typography, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CartIcon, TabControl } from '../../../common';
import { CombinedState, isEmpty, MENU_HREF } from '../../../core';
import { PurchaseLimitImportComponent, PurchaseLimitStatusComponent } from '../component';
import { getMasterInfoAsync } from '../data-access/actions';

const useStyles = makeStyles(() =>
  createStyles({
    textHeader: {
      fontSize: '2rem',
      fontWeight: 500,
    },
  }),
);

const PurchaseLimitComponent = () => {
  const classes = useStyles();
  const { masterInfo } = useSelector((state: CombinedState) => state['purchaseLimit']);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();

  const tabSource = [
    {
      tabLabel: 'インポート・エクスポート',
      children: <PurchaseLimitImportComponent />,
      tabIndex: 0,
    },
    {
      tabLabel: '購入状況確認',
      children: <PurchaseLimitStatusComponent />,
      tabIndex: 1,
    },
  ];

  const onChange = (tabIndex: number) => {
    setTabIndex(tabIndex);
    navigate(MENU_HREF.PURCHASE_LIMIT, {
      replace: true,
      state: { ...state, tabIndex: tabIndex },
    });
  };

  useEffect(() => {
    if (!isEmpty(state)) {
      setTabIndex(state.tabIndex);
    } else {
      navigate(MENU_HREF.PURCHASE_LIMIT, {
        replace: true,
        state: { ...state, tabIndex: tabIndex },
      });
    }
    dispatch(getMasterInfoAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' mb={1}>
          <CartIcon fontSize='large' viewBox={'0 0 16 16'} />
          <Typography ml={1} variant='h2' className={classes.textHeader}>
            購入上限管理
          </Typography>
        </Box>
        <Typography component='span'>
          購入上限値マスタ更新日時：{masterInfo?.updatedDatetime ? masterInfo?.updatedDatetime : 'なし'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TabControl activeIndex={tabIndex} source={tabSource} onChangeTab={onChange} />
      </Grid>
    </Grid>
  );
};

export const PurchaseLimitPage = React.memo(PurchaseLimitComponent);
