import { getLogs } from '../../../common';
import {
  ActionUnion,
  createAction,
  getApiError,
  getFileName,
  LogInfoPagination,
  SearchCondition,
  ThunkAction,
} from '../../../core';
import { exportFile } from '../service/services';
import { saveAs } from 'file-saver';

export enum SettingAccessLogActionTypes {
  GET_LOGS = 'SETTING_ACCESS_LOG_GET_LOGS',
  GET_LOGS_SUCCESS = 'SETTING_ACCESS_LOG_GET_LOGS_SUCCESS',
  GET_LOGS_FAILURE = 'SETTING_ACCESS_LOG_GET_LOGS_FAILURE',
  EXPORT_LOG = 'SETTING_ACCESS_LOG_EXPORT_LOG',
  EXPORT_LOG_SUCCESS = 'SETTING_ACCESS_LOG_EXPORT_LOG_SUCCESS',
  EXPORT_LOG_FAILURE = 'SETTING_ACCESS_LOG_EXPORT_LOG_FAILURE',
}

export const settingAccessLogActions = {
  getAccessLogs: () => createAction(SettingAccessLogActionTypes.GET_LOGS),
  getAccessLogsSuccess: (data: LogInfoPagination) =>
    createAction(SettingAccessLogActionTypes.GET_LOGS_SUCCESS, { data }),
  getAccessLogsFailure: (error: any) =>
    createAction(SettingAccessLogActionTypes.GET_LOGS_FAILURE, {
      error,
    }),
  exportLog: () => createAction(SettingAccessLogActionTypes.EXPORT_LOG),
  exportLogSuccess: (row: number) => createAction(SettingAccessLogActionTypes.EXPORT_LOG_SUCCESS, { row }),
  exportLogFailure: (error: any) =>
    createAction(SettingAccessLogActionTypes.EXPORT_LOG_FAILURE, {
      error,
    }),
};

export type SettingAccessLogActions = ActionUnion<typeof settingAccessLogActions>;

/**
 * Get all access logs
 * @returns
 */
export const getAccessLogsAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(settingAccessLogActions.getAccessLogs());
    try {
      const data = await getLogs(condition);
      dispatch(settingAccessLogActions.getAccessLogsSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(settingAccessLogActions.getAccessLogsFailure(errorText));
    }
  };
};

/**
 * export file access log
 * @returns
 */
export const exportAccessLogFileAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(settingAccessLogActions.exportLog());
    try {
      const fileResponse = await exportFile(condition);
      const rowNumber = fileResponse.headers['row-number'];
      const fileName = getFileName(fileResponse.headers['content-disposition']);
      const blob = new Blob([fileResponse.data]);
      saveAs(blob, fileName);
      dispatch(settingAccessLogActions.exportLogSuccess(rowNumber));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(settingAccessLogActions.exportLogFailure(errorText));
    }
  };
};
