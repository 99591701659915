import { SettingAccessLogActions, SettingAccessLogActionTypes } from './actions';
import { SettingAccessLogState } from './states';

const defaultState: SettingAccessLogState = {
  fetching: false,
  accessLogs: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  exportFetching: false,
};

export const settingAccessLogReducer = (
  state = defaultState,
  action: SettingAccessLogActions,
): SettingAccessLogState => {
  switch (action.type) {
    case SettingAccessLogActionTypes.GET_LOGS:
      return {
        ...state,
        fetching: true,
      };
    case SettingAccessLogActionTypes.GET_LOGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        accessLogs: action.payload.data,
      };
    case SettingAccessLogActionTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        fetching: false,
      };
    case SettingAccessLogActionTypes.EXPORT_LOG:
      return {
        ...state,
        exportFetching: true,
      };
    case SettingAccessLogActionTypes.EXPORT_LOG_SUCCESS:
    case SettingAccessLogActionTypes.EXPORT_LOG_FAILURE:
      return {
        ...state,
        exportFetching: false,
      };
    default:
      return state;
  }
};
