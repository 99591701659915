import {
  Grid,
  InputBaseProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import jpLocale from 'date-fns/locale/ja';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#000000',
      fontWeight: 600,
      marginBottom: '0.25em',
    },
  })
);

interface CustomInputProps {
  label?: string;
  errorText?: any;
}

export const DatePickerFieldControl = React.memo(
  (props: InputBaseProps & CustomInputProps) => {
    const classes = useStyles();
    const { id, placeholder, value, onChange, disabled, readOnly, label } = props;

    const handleChange = (value: any) => {
      if (typeof onChange === 'function')
        onChange({ target: { id, value } } as any);
    };
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={jpLocale}
      >
        <Grid container direction='column' alignItems='flex-start'>
          {label && <Typography className={classes.label}>{label}</Typography>}

          <DesktopDatePicker
            value={value}
            toolbarTitle='Select a date'
            inputFormat={placeholder}
            showToolbar={false}
            onChange={handleChange}
            closeOnSelect
            readOnly={readOnly}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                size='small'
                fullWidth
                disabled={disabled}
                {...params}
              />
            )}
          />
        </Grid>
      </LocalizationProvider>
    );
  }
);
