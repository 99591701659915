import { Alert, AlertColor, AlertProps, Stack, Link, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import {
  addNumberDays,
  ALERT_TYPE,
  CombinedState,
  createLogUserBehaviorAsync,
  DateTimeFormatType,
  deleteMessageAsync,
  formatDate,
  FUNCTION_CODE,
  getMessagesAsync,
  isEmpty,
  LOG_LEVEL,
  MENU_HREF,
  Message,
  notificationActions,
  SCREEN_NAME,
  SearchCondition,
} from '../../core';
import { AlertErrorIcon, AlertSuccessIcon, AlertWarningIcon, InfoIcon } from '../icons';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    successRoot: {
      color: '##0f5132',
      backgroundColor: '#d1e7dd',
      borderColor: '#badbcc',
    },
    errorRoot: {
      color: '#842029',
      backgroundColor: '#f8d7da',
      borderColor: '#f5c2c7',
    },
    warningRoot: {
      color: '#664d03',
      backgroundColor: '#fff3cd',
      borderColor: '#ffecb5',
    },
    infoRoot: {
      color: '#00525E',
      backgroundColor: '#CBF4FC',
      borderColor: '#CBF4FC',
      '& .MuiAlert-icon': {
        color: 'inherit',
      },
    },
    link: {
      color: (theme.palette.common as any).color.primary,
      cursor: 'pointer',
      textDecorationColor: 'inherit',
    },
  }),
);

const AlertComponent = (props: Message & AlertProps) => {
  const { type, onClose, message, sender } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const getClass = (alertType: string) => {
    switch (type) {
      case ALERT_TYPE.ERROR:
        return classes.errorRoot;
      case ALERT_TYPE.WARNING:
        return classes.warningRoot;
      case ALERT_TYPE.SUCCESS:
        return classes.successRoot;
      default:
        return classes.infoRoot;
    }
  };

  const getIcon = (alertType: string) => {
    switch (alertType) {
      case ALERT_TYPE.ERROR:
        return <AlertErrorIcon fontSize='small' viewBox='0 0 16 16' />;
      case ALERT_TYPE.WARNING:
        return <AlertWarningIcon fontSize='small' viewBox='0 0 16 16' />;
      case ALERT_TYPE.SUCCESS:
        return <AlertSuccessIcon fontSize='small' viewBox='0 0 16 16' />;
      default:
        return <InfoIcon color='inherit' fontSize='small' viewBox='0 0 16 16' />;
    }
  };

  const onClick = () => {
    navigate(MENU_HREF.EVENT_LOG);
  };

  const options = {
    replace: (domNode: any) => {
      if (domNode?.name === 'a') {
        return (
          <Link className={classes.link} onClick={onClick}>
            設定 ＞ 処理状況ログ{' '}
          </Link>
        );
      }
    },
  };

  return type === ALERT_TYPE.CUSTOM ? (
    <></>
  ) : sender === MENU_HREF.DASHBOARD ? (
    <Alert classes={{ root: getClass(type) }} severity={type as AlertColor} onClose={onClose} icon={getIcon(type)}>
      {parse(message, options)}
    </Alert>
  ) : (
    <Alert classes={{ root: getClass(type) }} severity={type as AlertColor} onClose={onClose} icon={getIcon(type)}>
      {message}
    </Alert>
  );
};

export const AlertControl = React.memo(AlertComponent);

const AlertContainerComponent = () => {
  const { systemAlerts, fetchingDeleteMessage, user } = useSelector((state: CombinedState) => {
    const notifications = state['notifications'];
    const auth = state['auth'];
    return {
      systemAlerts: notifications.systemAlerts,
      fetchingDeleteMessage: notifications.fetchingDeleteMessage,
      user: auth.user,
    };
  });
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [alerts, setAlerts] = React.useState([]);

  const onClose = (item: Message) => {
    if (item?.isLocal) {
      dispatch(notificationActions.closerAlert(item.messageId));
    } else {
      dispatch(notificationActions.closerAlert(item.messageId));
      dispatch(deleteMessageAsync(item.messageId));
    }
  };

  const getSender = () => {
    let sender = '';
    switch (true) {
      case !isEmpty(matchPath(MENU_HREF.DASHBOARD, pathname)):
        sender = MENU_HREF.DASHBOARD;
        break;
      case !isEmpty(matchPath(MENU_HREF.ACCESS_LOG, pathname)):
        sender = MENU_HREF.ACCESS_LOG;
        break;
      case !isEmpty(matchPath(MENU_HREF.EVENT_LOG, pathname)):
        sender = MENU_HREF.EVENT_LOG;
        break;
      case !isEmpty(matchPath(MENU_HREF.EXPORT, pathname)):
        sender = MENU_HREF.EXPORT;
        break;
      case !isEmpty(matchPath(MENU_HREF.IMPORT, pathname)):
        sender = MENU_HREF.IMPORT;
        break;
      case !isEmpty(matchPath(MENU_HREF.PURCHASE_LIMIT, pathname)):
        sender = MENU_HREF.PURCHASE_LIMIT;
        break;
      case !isEmpty(matchPath(MENU_HREF.REBATE_AMOUNT, pathname)):
        sender = MENU_HREF.REBATE_AMOUNT;
        break;
      case !isEmpty(matchPath(MENU_HREF.ROOT, pathname)):
        sender = SCREEN_NAME.DASHBOARD;
        break;
    }
    return sender;
  };

  const getScreenName = () => {
    let screenName = '';
    switch (true) {
      case !isEmpty(matchPath(MENU_HREF.DASHBOARD, pathname)):
        screenName = SCREEN_NAME.DASHBOARD;
        break;
      case !isEmpty(matchPath(MENU_HREF.ACCESS_LOG, pathname)):
        screenName = SCREEN_NAME.ACCESS_LOG;
        break;
      case !isEmpty(matchPath(MENU_HREF.EVENT_LOG, pathname)):
        screenName = SCREEN_NAME.EVENT_LOG;
        break;
      case !isEmpty(matchPath(MENU_HREF.EXPORT, pathname)):
        screenName = SCREEN_NAME.EXPORT;
        break;
      case !isEmpty(matchPath(MENU_HREF.IMPORT, pathname)):
        screenName = SCREEN_NAME.IMPORT;
        break;
      case !isEmpty(matchPath(MENU_HREF.PURCHASE_LIMIT, pathname)):
        screenName = SCREEN_NAME.PURCHASE_LIMIT;
        break;
      case !isEmpty(matchPath(MENU_HREF.REBATE_AMOUNT, pathname)):
        screenName = SCREEN_NAME.REBATE_AMOUNT;
        break;
      case !isEmpty(matchPath(MENU_HREF.ROOT, pathname)):
        screenName = SCREEN_NAME.DASHBOARD;
        break;
    }
    return screenName;
  };

  const getMessages = () => {
    const sender = getSender();
    const types = `${ALERT_TYPE.ERROR},${ALERT_TYPE.WARNING},${ALERT_TYPE.SUCCESS}`;
    const condition = new SearchCondition();
    condition.filter = {
      ...condition.filter,
      pageSize: sender === MENU_HREF.DASHBOARD ? 4 : 8,
      sender: sender,
      type: types,
    };

    if (sender === MENU_HREF.DASHBOARD) {
      const ordering = '-message_id';
      const now = new Date();
      const fromDate = formatDate(now, DateTimeFormatType.yyyy_MM_dd);
      const toDate = formatDate(addNumberDays(now, 7), DateTimeFormatType.yyyy_MM_dd);
      condition.filter = {
        ...condition.filter,
        startDate: fromDate ?? '',
        finishDate: toDate ?? '',
        ordering,
      };
    }
    dispatch(getMessagesAsync(condition));
  };

  React.useEffect(() => {
    if (isEmpty(systemAlerts)) setAlerts([]);
    else {
      const alerts = systemAlerts.filter((x: any) =>
        x.sender === MENU_HREF.DASHBOARD
          ? (!isEmpty(matchPath(x.sender, pathname)) || !isEmpty(matchPath(MENU_HREF.ROOT, pathname))) &&
            x.type !== ALERT_TYPE.CUSTOM
          : !isEmpty(matchPath(x.sender, pathname)) && x.type !== ALERT_TYPE.CUSTOM,
      );
      const sender = getSender();
      setAlerts(sender === MENU_HREF.DASHBOARD ? alerts.slice(0, 4) : alerts.slice(0, 8));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemAlerts]);

  React.useEffect(() => {
    if (user) {
      getMessages();
      const screenName = getScreenName();
      dispatch(
        createLogUserBehaviorAsync({
          functionCode: FUNCTION_CODE.LSC_SCREEN,
          logLevel: LOG_LEVEL.INFORMATION,
          systemMessage: `${user.id} が ${screenName} を開きました`,
          processType: 2,
          methodName: screenName,
          functionName: '',
        }),
      );
    } else return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  React.useEffect(() => {
    if (fetchingDeleteMessage) {
      getMessages();
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingDeleteMessage]);

  return isEmpty(alerts) ? null : (
    <Stack spacing={1} mb={2}>
      {alerts.map((item: Message) => (
        <AlertComponent key={item.messageId} {...item} onClose={() => onClose(item)} />
      ))}
    </Stack>
  );
};

export const AlertContainer = React.memo(AlertContainerComponent);
