import { DateTimeFormatType, apiService, camelToSnakeCase, formatDate } from '../../../core';
import { EmbeddedQrCodeInfo } from '../data-access/states';

export const embeddedQrCode = async (data: EmbeddedQrCodeInfo[], fileOriginal: File, hb_id: string) => {
  const formData = new FormData();
  formData.append('file', fileOriginal);
  formData.append('qr_state', JSON.stringify(camelToSnakeCase(data)));
  formData.append('hb_id', hb_id);
  return await apiService.post('/qr/embedded', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getHospitalBrochure = async (hb_id: string) => {
  return await apiService.post('/qr/get_hb', { hb_id });
};

export const registerHospitalBrochure = async (data: any, embeddedQrCodeInfo: EmbeddedQrCodeInfo[]) => {
  const formData = createFormData(data);
  formData.append('qr_state', JSON.stringify(camelToSnakeCase(embeddedQrCodeInfo)));
  return await apiService.post('/qr/register_hb', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateHospitalBrochure = async (data: any, embeddedQrCodeInfo: EmbeddedQrCodeInfo[]) => {
  const formData = createFormData(data);
  formData.append('qr_state', JSON.stringify(camelToSnakeCase(embeddedQrCodeInfo)));
  return await apiService.post('/qr/update_hb', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getCategoryOptions = async () => {
  return await apiService.get('/qr/categories');
};

const createFormData = (data: any) => {
  const formData = new FormData();
  formData.append('hb_id', data.hbId);
  formData.append('category', data.category);
  formData.append('title', data.title);
  formData.append('file', data.file);
  formData.append('jump_url', data.jumpUrl);
  formData.append('description', data.description);
  formData.append('release_flag', data.releaseFlag);
  const releaseStartDatetime = formatDate(data.releaseStartDatetime, DateTimeFormatType.yyyy_MM_dd_HH_mm_ss_dash);
  const releaseEndDatetime = formatDate(data.releaseEndDatetime, DateTimeFormatType.yyyy_MM_dd_HH_mm_ss_dash);
  if (releaseStartDatetime) {
    formData.append('release_start_datetime', releaseStartDatetime);
  }
  if (releaseEndDatetime) {
    formData.append('release_end_datetime', releaseEndDatetime);
  }
  return formData;
};

export const checkPdfFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await apiService.post('/qr/check_pdf_file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};
