import { getLogs } from '../../../common';
import {
  ActionUnion,
  createAction,
  getApiError,
  LogInfo,
  OrderDataOutput,
  SearchCondition,
  ThunkAction,
} from '../../../core';
import { exportOrderData, exportRebateData } from '../service/services';

export enum OrderDataOutputActionTypes {
  EXPORT_ORDER = 'EXPORT_DATA_EXPORT_ORDER',
  EXPORT_ORDER_SUCCESS = 'EXPORT_DATA_EXPORT_ORDER_SUCCESS',
  EXPORT_ORDER_FAILURE = 'EXPORT_DATA_EXPORT_ORDER_FAILURE',

  EXPORT_REBATE = 'EXPORT_DATA_EXPORT_REBATE',
  EXPORT_REBATE_SUCCESS = 'EXPORT_DATA_EXPORT_REBATE_SUCCESS',
  EXPORT_REBATE_FAILURE = 'EXPORT_DATA_EXPORT_REBATE_FAILURE',

  GET_NUTRITION_HISTORY = 'GET_NUTRITION_HISTORY',
  GET_NUTRITION_HISTORY_SUCCESS = 'GET_NUTRITION_HISTORY_SUCCESS',
  GET_NUTRITION_HISTORY_FAILURE = 'GET_NUTRITION_HISTORY_FAILURE',
}

export const orderDataOutputActions = {
  exportOrder: () => createAction(OrderDataOutputActionTypes.EXPORT_ORDER),
  exportOrderSuccess: (data?: OrderDataOutput, message?: string) =>
    createAction(OrderDataOutputActionTypes.EXPORT_ORDER_SUCCESS, { data, message }),
  exportOrderFailure: (error: any) =>
    createAction(OrderDataOutputActionTypes.EXPORT_ORDER_FAILURE, {
      error,
    }),
  exportRebate: () => createAction(OrderDataOutputActionTypes.EXPORT_REBATE),
  exportRebateSuccess: (data?: number, message?: string) =>
    createAction(OrderDataOutputActionTypes.EXPORT_REBATE_SUCCESS, { data, message }),
  exportRebateFailure: (error: any) =>
    createAction(OrderDataOutputActionTypes.EXPORT_REBATE_FAILURE, {
      error,
    }),

  getNutritionHistory: () => createAction(OrderDataOutputActionTypes.GET_NUTRITION_HISTORY),
  getNutritionHistorySuccess: (data: LogInfo[]) =>
    createAction(OrderDataOutputActionTypes.GET_NUTRITION_HISTORY_SUCCESS, { data }),
  getNutritionHistoryFailure: (error: any) =>
    createAction(OrderDataOutputActionTypes.GET_NUTRITION_HISTORY_FAILURE, {
      error,
    }),
};

export type OrderDataOutputActions = ActionUnion<typeof orderDataOutputActions>;

/**
 * Export order data
 * @returns
 */
export const exportOrderDataAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(orderDataOutputActions.exportOrder());
    try {
      const response: any = await exportOrderData(condition);
      dispatch(orderDataOutputActions.exportOrderSuccess(undefined, response?.message));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(orderDataOutputActions.exportOrderFailure(errorText));
    }
  };
};

/**
 * Export rebate data
 * @returns
 */
export const exportRebateDataAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(orderDataOutputActions.exportRebate());
    try {
      await exportRebateData(condition);
      dispatch(orderDataOutputActions.exportRebateSuccess());
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(orderDataOutputActions.exportRebateFailure(errorText));
    }
  };
};


export const getNutritionHistory = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(orderDataOutputActions.getNutritionHistory());
    try {
      const nutritionHistory = await getLogs(condition);
      dispatch(orderDataOutputActions.getNutritionHistorySuccess(nutritionHistory.results));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(orderDataOutputActions.getNutritionHistoryFailure(errorText));
    }
  };
};