import thunk from 'redux-thunk';
import {
  createStore,
  applyMiddleware,
  ReducersMapObject,
  combineReducers,
} from 'redux';
import { createLogger } from 'redux-logger';
import { isDev } from '../utils/environment';

const logger = createLogger({
  predicate: isDev,
  collapsed: true,
});

const middleWares = [thunk, logger];

type StoreInjectable = ReturnType<typeof createStore> & {
  injectReducers(reducers: ReducersMapObject): void;
};

const emptyReducer = () => {
  return 'Initialize';
};

function createKsLscExtStore(): StoreInjectable {
  let appliedMiddleWares = applyMiddleware(...middleWares);

  const reducersMap: ReducersMapObject = { init: emptyReducer };

  if (isDev()) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { composeWithDevTools } = require('redux-devtools-extension');

    appliedMiddleWares = composeWithDevTools(appliedMiddleWares);
  }

  const store = createStore(
    combineReducers(reducersMap),
    appliedMiddleWares
  ) as StoreInjectable;
  store['injectReducers'] = (reducers: ReducersMapObject) => {
    Object.assign(reducersMap, reducers);
    store.replaceReducer(combineReducers(reducersMap));
  };

  return store;
}

export const ksLscExtStore = createKsLscExtStore();
