export const enum DateTimeFormatType {
  yyyyMMdd = 'yyyyMMdd',
  yyyyMMdd_HH_mm_ss = 'yyyyMMdd HH:mm:ss',
  yyyy_MM_dd_HH_mm_ss_dash = 'yyyy-MM-dd HH:mm:ss',
  yyyy_MM_dd = 'yyyy-MM-dd',
  yyyy_MM_dd_HH_mm_ss = 'yyyy/MM/dd HH:mm:ss',
  yyyy_MM_dd_HH_mm = 'yyyy/MM/dd HH:mm',
  yyyy_MM_dd_T_HH_mm = "yyyy-MM-dd'T'HH:mm",
  dd_MM_yyyy = 'dd/MM/yyyy',
  dd_MM_yyyy_HH_h_mm = "dd/MM/yyyy - HH'h'mm",
  dd_MM_yyyy_hh_mm_a = 'dd/MM/yyyy hh:mm a',
  dd_MM_yyy_HH_mm = 'dd/MM/yyy HH:mm',
  dd_MM_yyyy_HH_mm = 'dd/MM/yyyy HH:mm',
  dd_MM_yyy___HH_mm = 'dd/MM/yyyy - HH:mm',
  dd_MM_yyyy___HH_mm_ss = 'dd/MM/yyyy - HH:mm:ss',
  yyyyMMdd_HH_mm_ss_sssZ = "yyyy-MM-dd HH:mm:ss.sss'Z'",
  yyyy_MM_dd_dash_HH_mm_a = "yyyy/MM/dd '-' hh:mm a",
  yyyy_MM_dd_not_dash = 'yyyy/MM/dd',
  h = 'h',
  hh = 'hh',
  HH_mm = 'HH:mm',
  HH_mm_ss = 'HH:mm:ss',
  hh_mm = 'hh:mm',
  hh_mm_a = 'hh:mm a',
  HH_mm_a = 'HH:mm a',
  HH_h = "HH'h'",
  HH = 'HH',
  HH_h_mm = "HH'h'mm",
  dd_MM_yyyy_colon_HH_h_mm = "dd/MM/yyyy : HH'h'mm",
  yyyy_MM_dd_T_HH_mm_ss = "yyyy-MM-dd'T'HH:mm:ss",
  yyyy_MM_dd_HH_mm_ss_a_dash = 'yyyy-MM-dd HH:mm:ss a',
  yyyyMMdd_HHmmss = 'yyyyMMdd_HHmmss',
  yyyyMMddHHmmss = 'yyyyMMddHHmmss',
}
