import { LoginComponent } from '../component/login.component'

export const LoginPage = () => {
  return (
    <LoginComponent />
  )
}



