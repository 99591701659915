import { ImportFilesActions, ImportFilesActionTypes } from './actions';
import { ImportFilesState } from './states';

const defaultState: ImportFilesState = {
  fetching: false,
  businessLogs: [],
  inventoryLogs: [],
  productLogs: [],
  businessCalendarFetching: false,
  productFetching: false,
  inventoryFetching: false,
};

export const importFilesReducer = (state = defaultState, action: ImportFilesActions): ImportFilesState => {
  switch (action.type) {
    case ImportFilesActionTypes.GET_LOGS:
      return {
        ...state,
        fetching: true,
      };
    case ImportFilesActionTypes.GET_LOGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        businessLogs: action.payload.businessLogs,
        inventoryLogs: action.payload.inventoryLogs,
        productLogs: action.payload.productLogs,
      };

    case ImportFilesActionTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case ImportFilesActionTypes.IMPORT_BUSINESS_CALENDAR:
      return {
        ...state,
        businessCalendarFetching: true,
      };

    case ImportFilesActionTypes.IMPORT_BUSINESS_CALENDAR_SUCCESS:
    case ImportFilesActionTypes.IMPORT_BUSINESS_CALENDAR_FAILURE:
      return {
        ...state,
        businessCalendarFetching: false,
      };

    case ImportFilesActionTypes.IMPORT_PRODUCT:
      return {
        ...state,
        productFetching: true,
      };

    case ImportFilesActionTypes.IMPORT_PRODUCT_SUCCESS:
    case ImportFilesActionTypes.IMPORT_PRODUCT_FAILURE:
      return {
        ...state,
        productFetching: false,
      };

    case ImportFilesActionTypes.IMPORT_INVENTORY:
      return {
        ...state,
        inventoryFetching: true,
      };
    case ImportFilesActionTypes.IMPORT_INVENTORY_SUCCESS:
    case ImportFilesActionTypes.IMPORT_INVENTORY_FAILURE:
      return {
        ...state,
        inventoryFetching: false,
      };
    default:
      return state;
  }
};
