import { Button, Grid, Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { DateTimePickerControl, ExportButton } from '../../../common/controls';
import { ArrowLeftIcon, ArrowRightIcon, InfoIcon } from '../../../common/icons';
import {
  CombinedState,
  convertToDate,
  DateTimeFormatType,
  formatDate,
  isAfterDate,
  isDateValid,
  isEmpty,
  MENU_HREF,
  SearchCondition,
} from '../../../core';
import { exportAccessLogFileAsync, getAccessLogsAsync } from '../data-access/actions';
import { SettingAccessLogTableComponent } from './setting-accesslog-table.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonOutlined: {
      marginRight: '0.3rem',
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
  }),
);

export const SettingAccessLogComponent = () => {
  const classes = useStyles();
  const formRef = React.useRef<any>();
  const actionFormRef = React.useRef<HTMLFormElement>(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchState = React.useMemo(() => (state as any)?.searchCondition || {}, [state]);
  const [page, setPage] = React.useState(1);
  const { accessLogs, exportFetching } = useSelector((state: CombinedState) => state['settingAccessLog']);
  const [isChangeCount, setIsChangeCount] = useState<Boolean>(true);

  const validationSchema = yup.object({
    startDate: yup
      .date()
      .nullable()
      .default(undefined)
      .typeError('表示開始・終了日のフォーマットが不正です。')
      .test('later-than-finish-date', '表示開始日時は表示終了日時より前の日時を指定してください', (value, context) => {
        if (isEmpty(context.parent.finishDate?.toString()) || isEmpty(value?.toString())) return true;
        else {
          return !isAfterDate(value, context.parent.finishDate);
        }
      }),
    finishDate: yup.date().nullable().default(undefined).typeError('表示開始・終了日のフォーマットが不正です。'),
  });

  const handleSubmit = (formValue: any) => {
    setPage(1);
    const condition = new SearchCondition();
    condition.filter = {
      ...searchState,
      page: 1,
      startDate: isDateValid(formValue.startDate)
        ? `${formatDate(formValue.startDate, DateTimeFormatType.yyyy_MM_dd_T_HH_mm)}:00`
        : undefined,
      finishDate: isDateValid(formValue.finishDate)
        ? `${formatDate(formValue.finishDate, DateTimeFormatType.yyyy_MM_dd_T_HH_mm)}:59`
        : undefined,
      processType: 2,
    };
    navigate(MENU_HREF.ACCESS_LOG, {
      replace: true,
      state: { searchCondition: condition.filter },
    });
    setIsChangeCount(true);
    dispatch(getAccessLogsAsync(condition));
  };

  formRef.current = useFormik({
    initialValues: {
      startDate: null,
      finishDate: null,
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const onPrevious = () => {
    setPage(page - 1);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: page - 1, processType: 2 };
    navigate(MENU_HREF.ACCESS_LOG, {
      replace: true,
      state: { searchCondition: condition.filter },
    });
    setIsChangeCount(false);
    dispatch(getAccessLogsAsync(condition));
  };

  const onNext = () => {
    setPage(page + 1);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: page + 1, processType: 2 };
    navigate(MENU_HREF.ACCESS_LOG, {
      replace: true,
      state: { searchCondition: condition.filter },
    });
    setIsChangeCount(false);
    dispatch(getAccessLogsAsync(condition));
  };

  const onExport = () => {
    const condition = new SearchCondition();
    condition.filter = { ...searchState, processType: 2 };
    dispatch(exportAccessLogFileAsync(condition));
  };

  const onToChange = (date: Date) => {
    formRef.current.setFieldValue('finishDate', date, true);
  };

  const onFromChange = (date: Date) => {
    formRef.current.setFieldValue('startDate', date, true);
  };

  const onSearch = () => {
    actionFormRef.current?.requestSubmit();
  };

  const hasSearchCriteria = () => {
    return !isEmpty(searchState?.finishDate) || !isEmpty(searchState?.startDate);
  };

  useEffect(() => {
    const condition = new SearchCondition();
    if (!isEmpty(searchState)) {
      condition.filter = searchState;
      formRef.current.setValues({
        finishDate: convertToDate(condition.filter.finishDate as string, DateTimeFormatType.yyyy_MM_dd_T_HH_mm_ss),
        startDate: convertToDate(condition.filter.startDate as string, DateTimeFormatType.yyyy_MM_dd_T_HH_mm_ss),
      });
    }
    const ordering = '-log_id';
    condition.filter = { ...condition.filter, page: 1, pageSize: 15, ordering, processType: 2 };
    navigate(MENU_HREF.ACCESS_LOG, {
      replace: true,
      state: { searchCondition: condition.filter },
    });

    dispatch(getAccessLogsAsync(condition));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center'>
      <Grid item xs={12}>
        <Box flexDirection='row' display='flex' alignItems='center' gap={1}>
          <InfoIcon fontSize='medium' viewBox={'0 0 16 16'} />
          <Typography variant='h6'>アクセスログ</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{ marginBottom: 1 }}>LSC-EC 拡張領域へのログイン、操作履歴を確認できます。</Typography>
        <form ref={actionFormRef} onSubmit={formRef.current.handleSubmit} noValidate>
          <DateTimePickerControl
            form={formRef.current}
            fromLabel='表示開始日'
            toLabel='表示終了日'
            variant='FormSearch'
            onFromChange={onFromChange}
            onToChange={onToChange}
            fromName='startDate'
            toName='finishDate'
            buttonName='検索'
            inputFromValue={formRef.current.values.startDate}
            inputToValue={formRef.current.values.finishDate}
            maxDate={new Date()}
            onClick={onSearch}
            inputFormat={DateTimeFormatType.yyyy_MM_dd_HH_mm}
            mode='datetime'
          />
        </form>
      </Grid>

      <Grid item xs={12}>
        <SettingAccessLogTableComponent isChangeCount={isChangeCount} hasSearchCriteria={hasSearchCriteria} />
      </Grid>

      <Grid item xs={12}>
        <Box flexDirection='row' display='flex' sx={{ marginTop: '0.5rem' }}>
          <Button
            variant='outlined'
            classes={{ outlined: classes.buttonOutlined }}
            size='small'
            onClick={onPrevious}
            disabled={isEmpty(accessLogs.previous)}
            startIcon={<ArrowLeftIcon fontSize='small' viewBox={'0 0 16 16'} />}
          >
            前ページ
          </Button>
          <Button
            variant='outlined'
            classes={{ outlined: classes.buttonOutlined }}
            size='small'
            onClick={onNext}
            disabled={isEmpty(accessLogs.next)}
            startIcon={<ArrowRightIcon fontSize='small' viewBox={'0 0 16 16'} />}
          >
            次ページ
          </Button>
          <ExportButton
            variant='outlined'
            classes={{ outlined: classes.buttonOutlined }}
            disabled={exportFetching || !hasSearchCriteria()}
            loading={exportFetching}
            onClick={onExport}
            size='small'
          >
            エクスポート
          </ExportButton>
        </Box>
      </Grid>
    </Grid>
  );
};
