import {
  Grid,
  InputAdornment,
  InputBaseProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { numberWithCommas } from '../../core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#000000',
      fontWeight: 600,
      marginBottom: '0.25em',
    },
  })
);

interface CustomInputProps {
  label?: string;
  errorText?: any;
  maxLength?: number;
}

export const NumberFieldControl = React.memo(
  (props: InputBaseProps & CustomInputProps) => {
    const classes = useStyles();
    const {
      id,
      name,
      placeholder,
      value,
      type,
      onChange,
      onBlur,
      error,
      errorText,
      maxLength,
      endAdornment,
      label,
      readOnly,
      autoComplete,
      disabled,
    } = props;

    const inputRef = React.useRef<any>(null);
    const isCompositionStart = React.useRef(false);

    const handleEvent = (event: any) => {
      if (event.type === 'compositionstart') {
        isCompositionStart.current = true;
      } else if (event.type === 'compositionend') {
        isCompositionStart.current = false;
      }
    };

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.addEventListener('compositionstart', handleEvent);
        inputRef.current.addEventListener('compositionupdate', handleEvent);
        inputRef.current.addEventListener('compositionend', handleEvent);
      }
    }, [inputRef]);

    const handleLostFocus = (e: any) => {
      if (e.target.value) {
        const value = String(e.target.value);
        const extractNumber = value.replace(/\D/g, '');
        if (extractNumber) {
          e.target.value = Number(extractNumber).toString();
        } else {
          e.target.value = '';
        }
      }
      if (typeof onChange === 'function') onChange(e);
      if (typeof onBlur === 'function') onBlur(e);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isCompositionStart.current) {
        isCompositionStart.current = false;
        return;
      }
      if (type === 'number') {
        if (e.target.value) {
          const value = String(e.target.value);
          const extractNumber = value.replace(/\D/g, '');
          if (extractNumber) {
            e.target.value = Number(extractNumber).toString();
          } else {
            e.target.value = '';
          }
        }
      }
      if (typeof onChange === 'function') onChange(e);
    };
    return (
      <Grid container direction='column' alignItems='flex-start'>
        {label && <Typography className={classes.label}>{label}</Typography>}
        <TextField
          inputRef={inputRef}
          fullWidth
          error={error}
          helperText={errorText}
          id={id}
          name={name}
          type={type === 'number' || type === 'halfWidthNumber' ? 'tel' : type}
          placeholder={placeholder}
          value={numberWithCommas(String(value))}
          autoComplete={autoComplete}
          size='small'
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleLostFocus}
          inputProps={{
            maxLength: maxLength || undefined,
            readOnly: readOnly,
          }}
          InputProps={{
            endAdornment: endAdornment ? (
              <InputAdornment position='end'>{endAdornment}</InputAdornment>
            ) : undefined,
          }}
        />
      </Grid>
    );
  }
);
