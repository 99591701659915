import { apiService, SearchCondition } from '../../../core';
import { HBAccessLogPagination, QrAccessCsv, SearchOptions } from '../data-access/states';

export const getHospitalBrochureList = async (condition: SearchCondition): Promise<HBAccessLogPagination> => {
  return await apiService.get<HBAccessLogPagination>('/qrAccessCount', { ...condition.filter });
};

export const getHospitalBrochureListCsv = async (condition: SearchCondition) => {
  return await apiService.get<any>('/qrAccessCount/output_csv', { ...condition.filter });
};

export const getSearchOption = async (): Promise<SearchOptions> => {
  return await apiService.get<SearchOptions>('/qrAccessCount/search_options');
};

export const getQrAccessListCsv = async (): Promise<QrAccessCsv[]> => {
  return await apiService.get<QrAccessCsv[]>('/qrAccessCount/get_csv_list');
};

export const downloadQrAccessCsv = async (id: string) => {
  return await apiService.getFile<any>('/qrAccessCount/download_csv', { id }, { responseType: 'blob' });
};
