import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowRepeatIcon,
  CalendarEventIcon,
  ExpandIcon,
  FileInputControl,
  getLogLevel,
  getLogTitle,
  LogLevelIcon,
  LogMessage,
} from '../../../common';
import { CombinedState, CONTENT_TYPE, downloadTemplateAsync, LogInfo, MENU_HREF } from '../../../core';
import { importBusinessCalendarAsync } from '../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginAccordion: {
      '&:first-of-type': {
        marginBottom: 0,
        marginTop: 0,
        boxShadow: 'none',
        border: '1px solid rgba(0,0,0,.125)',
        borderBottomWidth: 0,
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem',
      },
      '&:last-of-type': {
        marginBottom: 0,
        marginTop: 0,
        boxShadow: 'none',
        border: '1px solid rgba(0,0,0,.125)',
        borderTopWidth: 0,
        borderBottomLeftRadius: '0.25rem',
        borderBottomRightRadius: '0.25rem',
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'inherit',
      },
    },
    headerBlueOpen: {
      backgroundColor: (theme.palette.common as any).color.backgroundCyan,
      borderColor: (theme.palette.common as any).color.borderCyan,
      color: (theme.palette.common as any).color.textBlue,
      borderTopLeftRadius: 'calc(0.25rem - 1px)',
      borderTopRightRadius: 'calc(0.25rem - 1px)',
      boxShadow: 'inset 0 -1px 0 rgba(0,0,0,.125)',
      minHeight: '48px !important',
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    headerBlueClose: {
      backgroundColor: 'inherit',
      borderColor: 'inherit',
      color: 'inherit',
      borderTopLeftRadius: 'calc(0.25rem - 1px)',
      borderTopRightRadius: 'calc(0.25rem - 1px)',
    },
    footerBlueOpen: {
      backgroundColor: (theme.palette.common as any).color.backgroundCyan,
      color: (theme.palette.common as any).color.textBlue,
      border: '1px solid rgba(0,0,0,.125)',
      borderRightWidth: 0,
      borderLeftWidth: 0,
      minHeight: '48px !important',
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    footerBlueClose: {
      backgroundColor: 'inherit',
      borderColor: 'inherit',
      color: 'inherit',
      borderBottomLeftRadius: 'calc(0.25rem - 1px)',
      borderBottomRightRadius: 'calc(0.25rem - 1px)',
    },
    reloadButton: {
      color: (theme.palette.common as any).color.outlineSecondary,
      borderColor: (theme.palette.common as any).color.outlineSecondary,
      '&:hover': {
        borderColor: (theme.palette.common as any).color.outlineSecondary,
        backgroundColor: (theme.palette.common as any).color.outlineSecondary,
        color: (theme.palette.common as any).color.gray,
      },
    },
    tableRoot: {
      width: '100%',
      minWidth: '60em',
      maxWidth: '90em',
      tableLayout: 'fixed',
      '& a': {
        color: (theme.palette.common as any).color.primary,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    linkText: {
      color: (theme.palette.common as any).color.primary,
      cursor: 'pointer',
    },
  }),
);

const BusinessCalendar = forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const { onRefresh } = props;
  const { businessLogs, businessCalendarFetching } = useSelector((state: CombinedState) => state['importFiles']);
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [file, setFile] = useState<any>(undefined);
  const dispatch = useDispatch();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onChangeFile = (file: any) => {
    setFile(file);
  };

  const onImport = () => {
    dispatch(importBusinessCalendarAsync(file));
  };

  const onDownloadTemplate = () => {
    dispatch(downloadTemplateAsync('1'));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display='flex' flexDirection='row' alignItems='center' mb={2}>
          <CalendarEventIcon fontSize='medium' viewBox={'0 0 16 16'} />
          <Typography ml={1} fontSize={'130%'}>
            営業日カレンダー
          </Typography>
        </Box>
        <Box ml={2}>
          <Accordion
            classes={{ root: classes.marginAccordion }}
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              className={expanded === 'panel1' ? classes.headerBlueOpen : classes.headerBlueClose}
              ref={ref}
              expandIcon={<ExpandIcon fontSize='small' viewBox='0 0 16 16' color='inherit' />}
              aria-controls='panel1d-content'
              id='panel1d-header'
            >
              <Typography>ファイル取り込み</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography marginBottom={2}>
                営業日カレンダーをインポートできます。旧形式と異なり、日付は日付として EXCEL
                ファイル上にデータを作成してください。
              </Typography>
              <FileInputControl
                buttonName='インポート'
                accept={`.${CONTENT_TYPE.XLS},.${CONTENT_TYPE.XLSX}`}
                allowExtensions={[CONTENT_TYPE.XLS, CONTENT_TYPE.XLSX]}
                onChange={onChangeFile}
                onImport={onImport}
                loading={businessCalendarFetching}
              />
              <Typography marginTop={1}>
                インポートファイルの雛形は、
                <Link onClick={onDownloadTemplate} className={classes.linkText}>
                  こちらのファイル
                </Link>
                をご確認ください。
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            classes={{ root: classes.marginAccordion }}
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              className={expanded === 'panel2' ? classes.footerBlueOpen : classes.footerBlueClose}
              expandIcon={<ExpandIcon fontSize='small' viewBox='0 0 16 16' color='inherit' />}
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography>ファイル読み込み履歴</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer sx={{ marginBottom: 2 }}>
                <Table classes={{ root: classes.tableRoot }}>
                  <TableHead>
                    <TableRow>
                      <TableCell width='180px'>日時</TableCell>
                      <TableCell align='left' width='250px'>
                        処理
                      </TableCell>
                      <TableCell align='left' width='180px'>
                        レベル
                      </TableCell>
                      <TableCell align='left'>概要</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {businessLogs?.map((row: LogInfo) => (
                      <TableRow hover key={row.logId}>
                        <TableCell component='th' scope='row'>
                          {row.recordDatetime}
                        </TableCell>
                        <TableCell>{getLogTitle(row)}</TableCell>
                        <TableCell>
                          <Box className='lsc-table-icon' display='flex' alignItems='center'>
                            <LogLevelIcon status={row.logLevel} />
                            <Typography ml={1} component='span'>
                              {getLogLevel(row.logLevel)}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align='left'>
                          <LogMessage sender={MENU_HREF.IMPORT} log={row} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {businessLogs.length === 0 && <Typography>営業日カレンダーファイルの読み込みはありません。</Typography>}
              <Button
                className={classes.reloadButton}
                variant='outlined'
                startIcon={<ArrowRepeatIcon fontSize='small' viewBox={'0 0 16 16'} />}
                onClick={onRefresh}
              >
                再読込
              </Button>
              <Typography marginTop={1} fontSize={'80%'}>
                ※履歴が表示されていない場合は、再読み込みボタンをクリックしてください。
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Grid>
    </Grid>
  );
});

export default React.memo(BusinessCalendar);
