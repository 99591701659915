import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { CloseIcon, ModalControl, PaginationControl, PencilIcon, SortIcon } from '../../../common';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CombinedState, HospitalBrochure, MENU_HREF, SORT_TYPE, SearchCondition, isEmpty } from '../../../core';
import { deleteQrDocumentAsync, getQrDocumentsAsync } from '../data-access/actions';
import _ from 'lodash';
import { AntSwitch } from './switch.component';
import PDFViewer from './pdf-viewer.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textTitle: {
      fontSize: '1.5rem',
    },
    container: {
      minHeight: '25em',
    },
    tableRoot: {
      width: '100%',
      minWidth: '78em',
      tableLayout: 'fixed',
    },
    sortIcon: {
      color: (theme.palette.common as any).color.primary,
    },
    error: {
      color: (theme.palette.common as any).color.textError,
    },
    preview: {
      width: '100%',
      height: '100%',
    },
    newButton: {
      width: '8em',
      fontSize: '.875rem',
      backgroundColor: (theme.palette.common as any).color.primary,
      borderColor: (theme.palette.common as any).color.primary,
      '&:hover': {
        borderColor: (theme.palette.common as any).color.hover,
        backgroundColor: (theme.palette.common as any).color.hover,
        boxShadow: '0 0 0 0.25rem rgba(49,132,253,.5)',
      },
    },
    outlineButton: {
      fontSize: '.875rem',
      padding: '0.25rem 0.5rem',
      '&:active,&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    deleteButton: {
      marginTop: '0.5rem',
      fontSize: '.875rem',
      padding: '0.25rem 0.5rem',
      '&:active,&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    wrapPreview: {
      borderRadius: '0.25rem!important',
      borderColor: '#6c757d!important',
      border: '1px solid #dee2e6!important',
      overflow: 'hidden',
    },
    pagination: {
      '& ul': {
        justifyContent: 'center',
      },
    },
  }),
);

export const ListComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { qrDocumentPagination, deleteQrDocumentAction } = useSelector((state: CombinedState) => state['qrDocument']);
  const ITEM_PER_PAGE = 10;
  const searchState = useMemo(() => (state as any)?.searchCondition || {}, [state]);
  const [page, setPage] = useState(1);
  const [sortIdType, setSortIdType] = useState<string>(SORT_TYPE.ASCENDING);
  const [sortCategoryNameType, setCategoryNameType] = useState<string>(SORT_TYPE.ASCENDING);
  const [sortTitleType, setSortTitleType] = useState<string>(SORT_TYPE.ASCENDING);
  const [sortStartDateType, setSortStartDateType] = useState<string>(SORT_TYPE.ASCENDING);
  const [sortEndDateType, setSortEndDateType] = useState<string>(SORT_TYPE.ASCENDING);
  const [sortReleaseFlagType, setSortReleaseFlagType] = useState<string>(SORT_TYPE.ASCENDING);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedHbId, setSelectedHbId] = useState<string | null>(null);
  const formRef = useRef<any>();

  const onSort = (sortItem: string) => {
    let sortType = '';
    switch (sortItem) {
      case 'hb_id':
        sortType = sortIdType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        sortItem = sortIdType === SORT_TYPE.ASCENDING ? `-${sortItem}` : sortItem;
        setSortIdType(sortType);
        break;
      case 'hb_category_id__hd_category_name':
        sortType = sortCategoryNameType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        sortItem = sortCategoryNameType === SORT_TYPE.ASCENDING ? `-${sortItem}` : sortItem;
        setCategoryNameType(sortType);
        break;
      case 'title':
        sortType = sortTitleType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        sortItem = sortTitleType === SORT_TYPE.ASCENDING ? `-${sortItem}` : sortItem;
        setSortTitleType(sortType);
        break;
      case 'release_end_datetime':
        sortType = sortStartDateType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        sortItem = sortStartDateType === SORT_TYPE.ASCENDING ? `-${sortItem}` : sortItem;
        setSortStartDateType(sortType);
        break;
      case 'release_start_datetime':
        sortType = sortEndDateType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        sortItem = sortEndDateType === SORT_TYPE.ASCENDING ? `-${sortItem}` : sortItem;
        setSortEndDateType(sortType);
        break;
      case 'release_flag':
        sortType = sortReleaseFlagType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        sortItem = sortReleaseFlagType === SORT_TYPE.ASCENDING ? `-${sortItem}` : sortItem;
        setSortReleaseFlagType(sortType);
        break;
    }

    setPage(1);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: 1, ordering: sortItem };

    navigate(MENU_HREF.QR_DOCUMENT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    dispatch(getQrDocumentsAsync(condition));
  };

  const handleSubmit = (value: any) => {
    setPage(1);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: 1, ...value };
    navigate(MENU_HREF.QR_DOCUMENT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    dispatch(getQrDocumentsAsync(condition));
  };

  const onChangePage = (page: number) => {
    setPage(page);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: page };

    navigate(MENU_HREF.QR_DOCUMENT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    dispatch(getQrDocumentsAsync(condition));
  };

  const onCreate = () => {
    navigate('/qr-code');
  };

  const onUpdate = (hbId: string) => {
    navigate(`/qr-code/${hbId}`);
  };

  const onBeforeDelete = (hbId: string) => {
    setShowDeleteModal(true);
    setSelectedHbId(hbId);
  };

  const onDelete = (hbId?: string | null) => {
    setShowDeleteModal(false);
    if (!hbId) return;
    dispatch(deleteQrDocumentAsync(hbId));
  };

  const onChangeReleaseFlag = (event: React.ChangeEvent<HTMLInputElement>) => {
    formRef.current.setFieldValue('releaseFlag', event.target.checked ? '1' : '0', true);

    setPage(1);
    const condition = new SearchCondition();
    if (event.target.checked) {
      condition.filter = { ...searchState, page: 1, releaseFlag: event.target.checked ? '1' : 0 };
    } else {
      if (searchState.hasOwnProperty('releaseFlag')) {
        delete searchState['releaseFlag'];
      }
      condition.filter = { ...searchState, page: 1 };
    }
    navigate(MENU_HREF.QR_DOCUMENT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    dispatch(getQrDocumentsAsync(condition));
  };

  formRef.current = useFormik({
    initialValues: {
      releaseFlag: false,
    },
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const condition = new SearchCondition();
    condition.filter = { ...condition.filter, pageSize: ITEM_PER_PAGE };
    if (!isEmpty(searchState)) {
      condition.filter = searchState;
      formRef.current.setValues({
        releaseFlag: searchState?.releaseFlag === '1' ? true : false,
      });
      if (searchState.hasOwnProperty('releaseFlag')) {
        delete searchState['releaseFlag'];
      }
    } else {
      const ordering = 'hb_id';
      condition.filter = { ...condition.filter, ordering };
    }

    navigate(MENU_HREF.QR_DOCUMENT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    dispatch(getQrDocumentsAsync(condition));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!deleteQrDocumentAction.fetching && deleteQrDocumentAction.action === 'ok') {
      const condition = new SearchCondition();
      condition.filter = { ...condition.filter, pageSize: ITEM_PER_PAGE };
      if (!isEmpty(searchState)) {
        condition.filter = searchState;
      } else {
        const ordering = 'hb_id';
        condition.filter = { ...condition.filter, ordering };
      }
      navigate(MENU_HREF.QR_DOCUMENT, {
        replace: true,
        state: { ...state, searchCondition: condition.filter },
      });
      dispatch(getQrDocumentsAsync(condition));
    } else return;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deleteQrDocumentAction]);

  return (
    <Stack spacing={2}>
      <Grid container spacing={4} direction='row' alignItems='flex-end' justifyContent='flex-end'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <AntSwitch name='releaseFlag' checked={formRef.current.releaseFlag} onChange={onChangeReleaseFlag} />
          <Typography mx={2}>公開のみを表示</Typography>
        </Box>

        <Button onClick={onCreate} className={classes.newButton} variant='contained'>
          新規登録
        </Button>
      </Grid>
      <TableContainer component={Box} classes={{ root: classes.container }}>
        <Table classes={{ root: classes.tableRoot }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align='left'>
                <Box display='flex' justifyContent='flex-start' alignItems='center'>
                  <Typography className='lsc-table-header' component='span'>
                    資料 ID
                  </Typography>
                  <IconButton className={classes.sortIcon} onClick={() => onSort('hb_id')}>
                    <SortIcon color='inherit' type={sortIdType} viewBox={'0 0 16 16'} />
                  </IconButton>
                </Box>
              </TableCell>
              <TableCell align='left'>
                <Box display='flex' justifyContent='flex-start' alignItems='center'>
                  <Typography className='lsc-table-header' component='span'>
                    カテゴリ
                  </Typography>
                  <IconButton className={classes.sortIcon} onClick={() => onSort('hb_category_id__hd_category_name')}>
                    <SortIcon color='inherit' type={sortCategoryNameType} viewBox={'0 0 16 16'} />
                  </IconButton>
                </Box>
              </TableCell>
              <TableCell width='15%'>
                <Box display='flex' justifyContent='flex-start' alignItems='center'>
                  <Typography className='lsc-table-header' component='span'>
                    資料名
                  </Typography>
                </Box>
              </TableCell>
              <TableCell width='220px' align='center'>
                プレビュー
              </TableCell>
              <TableCell>
                <Box display='flex' justifyContent='flex-start' alignItems='center'>
                  <Typography className='lsc-table-header' component='span'>
                    公開開始日時
                  </Typography>
                  <IconButton className={classes.sortIcon} onClick={() => onSort('release_start_datetime')}>
                    <SortIcon color='inherit' type={sortStartDateType} viewBox={'0 0 16 16'} />
                  </IconButton>
                </Box>
              </TableCell>
              <TableCell>
                <Box display='flex' justifyContent='flex-start' alignItems='center'>
                  <Typography className='lsc-table-header' component='span'>
                    公開終了日時
                  </Typography>
                  <IconButton className={classes.sortIcon} onClick={() => onSort('release_end_datetime')}>
                    <SortIcon color='inherit' type={sortEndDateType} viewBox={'0 0 16 16'} />
                  </IconButton>
                </Box>
              </TableCell>
              <TableCell width='150px'>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Typography className='lsc-table-header' component='span'>
                    公開設定
                  </Typography>
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {qrDocumentPagination.results?.map((row: HospitalBrochure) => (
              <TableRow hover key={row.hbId}>
                <TableCell align='left'>{row.hbId}</TableCell>
                <TableCell align='left'>{row?.hbCategoryName}</TableCell>
                <TableCell align='left'>{row?.title}</TableCell>
                <TableCell align='center'>
                  {!_.isEmpty(row?.previewFile) && (
                    // <div className={classes.wrapPreview}>
                    <PDFViewer file={`data:application/pdf;base64,${row?.previewFile}`} />
                    // </div>
                  )}
                </TableCell>
                <TableCell align='left'>{row?.releaseStartDatetime}</TableCell>
                <TableCell align='left'>{row?.releaseEndDatetime}</TableCell>
                <TableCell align='center'>{row?.releaseFlag === 1 ? '公開' : '非公開'}</TableCell>
                <TableCell align='center'>
                  <Button
                    variant='outlined'
                    onClick={() => onUpdate(row.hbId)}
                    classes={{ outlined: classes.outlineButton }}
                  >
                    <Box display='flex' alignItems='center'>
                      <PencilIcon fontSize='small' viewBox='0 0 16 16' />
                      <Typography ml={1}>編集</Typography>
                    </Box>
                  </Button>
                  <br />
                  <Button
                    onClick={() => onBeforeDelete(row.hbId)}
                    variant='outlined'
                    classes={{ outlined: classes.deleteButton }}
                  >
                    <Box display='flex' alignItems='center'>
                      <CloseIcon fontSize='small' viewBox='0 0 16 16' />
                      <Typography ml={1}>削除</Typography>
                    </Box>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box mt={2} mb={2} className={classes.pagination}>
          {qrDocumentPagination.count === 0 && <Typography>データはありません。</Typography>}
          <PaginationControl
            totalRecord={qrDocumentPagination.count}
            currentPage={page}
            itemPerPage={ITEM_PER_PAGE}
            onChange={onChangePage}
          />
        </Box>
      </TableContainer>
      {showDeleteModal && (
        <ModalControl
          onClose={() => {
            setShowDeleteModal(false);
          }}
          onOk={() => onDelete(selectedHbId)}
          okCancel={() => {
            setShowDeleteModal(false);
          }}
          title='確認'
          content={<Typography>削除しますか？</Typography>}
        ></ModalControl>
      )}
    </Stack>
  );
};
