import { ksLscExtStore } from '../../core';
import { orderDataOutPutReducer } from './data-access/reducers';

// Inject Module Reducers Into Root Reducers
ksLscExtStore.injectReducers({
  orderDataOutput: orderDataOutPutReducer,
});


export * from './page/order-data-output-page.component';
