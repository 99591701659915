import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckListIcon,
  ExportButton,
  FileDownloadIcon,
  FileInputControl,
  FileUploadIcon,
  getLogLevel,
  getLogTitle,
  LogLevelIcon,
  LogMessage,
} from '../../../common';
import {
  CombinedState,
  CONTENT_TYPE,
  downloadTemplateAsync,
  EXPORT_PURCHASE_TYPE,
  FUNCTION_CODE,
  LogInfo,
  MENU_HREF,
  SearchCondition,
} from '../../../core';
import {
  exportPurchaseLimitAsync,
  getPurchaseLimitLogsAsync,
  importPurchaseLimitSettingAsync,
} from '../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textTitle: {
      fontSize: '1.5rem',
    },
    reloadButton: {
      width: '8em',
      color: (theme.palette.common as any).color.outlineSecondary,
      borderColor: (theme.palette.common as any).color.outlineSecondary,
      '&:hover': {
        borderColor: (theme.palette.common as any).color.outlineSecondary,
        backgroundColor: (theme.palette.common as any).color.outlineSecondary,
        color: (theme.palette.common as any).color.gray,
      },
    },
    linkDescription: {
      marginTop: '0.2em !important',
    },
    linkText: {
      color: (theme.palette.common as any).color.primary,
      cursor: 'pointer',
    },
    tableRoot: {
      width: '100%',
      minWidth: '60em',
      maxWidth: '90em',
      tableLayout: 'fixed',
      '& a': {
        color: (theme.palette.common as any).color.primary,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    buttonOutlined: {
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    textHeader: {
      fontSize: '1.5rem',
    },
    error: {
      color: (theme.palette.common as any).color.textError,
    },
  }),
);

const ImportComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { logs, exportPurchaseLimitSettingFetching, purchaseLimitSettingFetching, saleLimitDuplicated } = useSelector(
    (state: CombinedState) => state['purchaseLimit'],
  );
  const [file, setFile] = useState<any>(null);

  const onChangeFile = (file: any) => {
    setFile(file);
  };

  const onImport = () => {
    dispatch(importPurchaseLimitSettingAsync(file));
  };

  const onExport = () => {
    dispatch(exportPurchaseLimitAsync(EXPORT_PURCHASE_TYPE.EX_PURCHASE_LIMIT_SETTING));
  };

  const onRefresh = useCallback(() => {
    const condition = new SearchCondition();
    const ordering = '-log_id';
    condition.filter = {
      ...condition.filter,
      page: 1,
      pageSize: 5,
      functionCode: `${FUNCTION_CODE.PURCHASE_LIMIT},${FUNCTION_CODE.PURCHASE_LIMIT_IMPORT_MASTER}`,
      ordering,
    };
    dispatch(getPurchaseLimitLogsAsync(condition));
  }, [dispatch]);

  const onDownloadTemplate = () => {
    dispatch(downloadTemplateAsync('4'));
  };

  useEffect(() => {
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography>購入上限値設定のインポートやエクスポートを行えます。</Typography>
        <Stack spacing={1}>
          <Box display='flex' alignItems='center'>
            <FileDownloadIcon fontSize='medium' viewBox={'0 0 16 16'} />
            <Typography ml={1} variant='h4' className={classes.textTitle}>
              受注上限値設定ファイルの読み込み
            </Typography>
          </Box>
          <Box px={3} display='flex' flexDirection='column'>
            <FileInputControl
              accept={`.${CONTENT_TYPE.XLS},.${CONTENT_TYPE.XLSX}`}
              allowExtensions={[CONTENT_TYPE.XLS, CONTENT_TYPE.XLSX]}
              onChange={onChangeFile}
              onImport={onImport}
              loading={purchaseLimitSettingFetching}
            />

            <Typography component='span' className={classes.linkDescription}>
              インポートファイルの雛形は、
              <Link onClick={onDownloadTemplate} className={classes.linkText}>
                こちらのファイル
              </Link>
              をご確認ください。
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <Box display='flex' alignItems='center'>
          <FileUploadIcon fontSize='medium' viewBox={'0 0 16 16'} />
          <Typography ml={1} className={classes.textHeader} variant='h4'>
            上限値一覧エクスポート
          </Typography>
        </Box>
        <Box px={3} display='flex' flexDirection='column'>
          <Box>
            <ExportButton
              variant='outlined'
              disabled={exportPurchaseLimitSettingFetching}
              onClick={onExport}
              loading={exportPurchaseLimitSettingFetching}
              classes={{ outlined: classes.buttonOutlined }}
            >
              CSV エクスポート
            </ExportButton>
          </Box>
          {!exportPurchaseLimitSettingFetching && saleLimitDuplicated && (
            <Typography className={classes.error}>
              受注上限値設定ファイルの読み込み中のためエクスポートできません。
            </Typography>
          )}
          <Typography component='span' className={classes.linkDescription}>
            最新の上限一覧をエクスポートします。
          </Typography>
        </Box>
      </Stack>

      <Stack spacing={2}>
        <Box display='flex' alignItems='center'>
          <CheckListIcon fontSize='medium' viewBox={'0 0 16 16'} />
          <Typography ml={1} variant='h4' className={classes.textTitle}>
            ファイル読み込み履歴
          </Typography>
        </Box>

        <TableContainer component={Box} px={3}>
          <Table classes={{ root: classes.tableRoot }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width='180px' align='left'>
                  日時
                </TableCell>
                <TableCell width='200px' align='left'>
                  処理
                </TableCell>
                <TableCell width='180px' align='left'>
                  レベル
                </TableCell>
                <TableCell align='left'>概要</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {logs?.map((row: LogInfo) => (
                <TableRow hover key={row.logId}>
                  <TableCell align='left'>{row.recordDatetime}</TableCell>
                  <TableCell align='left'>{getLogTitle(row)}</TableCell>
                  <TableCell align='left'>
                    <Box className='lsc-table-icon' display='flex' alignItems='center'>
                      <LogLevelIcon status={row.logLevel} />
                      <Typography ml={1} component='span'>
                        {getLogLevel(row.logLevel)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align='left'>
                    <LogMessage sender={MENU_HREF.PURCHASE_LIMIT} log={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box mt={2} display='flex' flexDirection='column'>
            {isEmpty(logs) && <Typography>購入条件値設定ファイルの読み込みはありません。</Typography>}
          </Box>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export const PurchaseLimitImportComponent = React.memo(ImportComponent);
