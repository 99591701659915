import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getNutritionLogsAsync } from '../../data-access/actions';
import { FeeListComponent } from './fee-list.component';
import { FeeNotifiesComponent } from './fee-notifies.component';

export const LinkLogComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(getNutritionLogsAsync());
  }, [dispatch]);

  return (
    <Stack spacing={2}>
      <Typography>OBIC7 連携では、OBIC7 と連携するファイルの取り込みや書き出しに関する状況を確認できます。</Typography>
      <FeeListComponent />
      <FeeNotifiesComponent />
    </Stack>
  );
};
