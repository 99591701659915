import { apiService } from '../../../core';

export const importFile = async (url: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return await apiService.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
