import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import theme from './app/theme';
import './index.css';
import { ksLscExtStore } from './libs/core';
import reportWebVitals from './reportWebVitals';

import { createRoot } from 'react-dom/client';

const domNode = document.getElementById('root');
const root = createRoot(domNode as HTMLElement);
root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <Provider store={ksLscExtStore}>
        <BrowserRouter>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </Provider>
    </StyledEngineProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
