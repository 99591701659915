import { AuthActions, AuthActionTypes } from './actions';
import { AuthState } from './states';

const defaultState: AuthState = {
  initialized: false,
  fetching: false,
  user: null,
  error: null,
  isShowLoginModal: false,
};

export const authReducer = (state = defaultState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AuthActionTypes.AUTHORIZED_SUCCESS:
      return {
        ...state,
        initialized: true,
        user: action.payload.user,
      };
    case AuthActionTypes.AUTHORIZED_FAILED:
      return {
        ...state,
        initialized: true,
      };
    case AuthActionTypes.LOGIN:
      return {
        ...state,
        fetching: true,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: action.payload.user,
        isShowLoginModal: false,
      };
    case AuthActionTypes.LOGIN_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case AuthActionTypes.VERIFY_EMAIL:
      return {
        ...state,
        fetching: true,
      };
    case AuthActionTypes.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    case AuthActionTypes.VERIFY_EMAIL_FAILED:
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        fetching: true,
      };
    case AuthActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: null,
      };
    case AuthActionTypes.REGISTER:
      return {
        ...state,
        fetching: true,
        user: null,
      };
    case AuthActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    case AuthActionTypes.REGISTER_FAILED:
      return {
        ...state,
        fetching: false,
      };

    case AuthActionTypes.REQUEST_PASSWORD_RESET:
      return {
        ...state,
        fetching: true,
      };
    case AuthActionTypes.REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    case AuthActionTypes.REQUEST_PASSWORD_RESET_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case AuthActionTypes.RESET_PASSWORD:
      return {
        ...state,
        fetching: true,
      };
    case AuthActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    case AuthActionTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case AuthActionTypes.SHOW_LOGIN_MODAL:
      return {
        ...state,
        isShowLoginModal: !state.isShowLoginModal,
      };
    default:
      return state;
  }
};
