import { Action, ActionCreatorsMapObject, AnyAction } from 'redux';
import {
  ThunkAction as _ThunkAction,
  ThunkDispatch as _ThunkDispatch,
} from 'redux-thunk';
import { CombinedState } from './interfaces';

export interface ActionWithPayload<T, P> extends Action<T> {
  payload: P;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(
  type: T,
  payload: P,
): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(
  type: T,
  payload?: P,
): Action<T> | ActionWithPayload<T, P> {
  return typeof payload === 'undefined' ? { type } : { type, payload };
}

export type ActionUnion<A extends ActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type ThunkAction<R = {}, A extends Action = AnyAction> = _ThunkAction<
  R,
  CombinedState,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  A
>;

export type ThunkDispatch<
  // eslint-disable-next-line @typescript-eslint/ban-types
  E = {},
  A extends Action = AnyAction,
> = _ThunkDispatch<CombinedState, E, A>;
