import { Stack, Typography, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { forwardRef } from 'react';
import { DateTimePickerControl, ExportButton } from '../../../common';
import { BagCheckIcon } from '../../../common/icons';
import { CheckboxGroupControl } from './check-box-group.component';
import {
  CombinedState,
  DateTimeFormatType,
  formatDate,
  isAfterDate,
  isEmpty,
  joinString,
  SearchCondition,
} from '../../../core';
import { useDispatch, useSelector } from 'react-redux';
import { exportOrderDataAsync } from '../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textTitle: {
      fontSize: '1.5rem',
    },
    error: {
      color: (theme.palette.common as any).color.textError,
    },
    buttonOutlined: {
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    orderType: {
      marginTop: '0.25rem',
      paddingLeft: '0.75rem',
    },
  }),
);

const options = [
  { id: '1', label: '1: AH', checked: false, value: 1 },
  { id: '2', label: '5: 達成型リベート', checked: false, value: 5 },
  { id: '3', label: '2: 企業', checked: false, value: 2 },
  { id: '4', label: '6: KSオンラインへの移送', checked: false, value: 6 },
  { id: '5', label: '3: SPSC', checked: false, value: 3 },
  { id: '6', label: '7: 倉庫間移送', checked: false, value: 7 },
  { id: '7', label: '4: UC・サプリサンプル', checked: false, value: 4 },
  { id: '8', label: '8: PO（VHD受注データ取込）', checked: false, value: 8 },
];

const OrderOutputComponent = forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const formRef = React.useRef<any>();
  const [orderTypes, setOrderTypes] = React.useState(options);
  const dispatch = useDispatch();
  const { exportOrderFetching, orderData } = useSelector((state: CombinedState) => state['orderDataOutput']);

  const validationSchema = yup.object({
    targetFromDate: yup
      .date()
      .nullable()
      .default(undefined)
      .typeError('表示開始・終了日のフォーマットが不正です。')
      .test('later-than-finish-date', '表示開始日時は表示終了日時より前の日時を指定してください', (value, context) => {
        if (isEmpty(context.parent.targetToDate?.toString()) || isEmpty(value?.toString())) return true;
        else {
          return !isAfterDate(value, context.parent.targetToDate);
        }
      }),
    targetToDate: yup.date().nullable().default(undefined).typeError('表示開始・終了日のフォーマットが不正です。'),
  });

  const handleSubmit = (values: any) => {
    const condition = new SearchCondition();
    condition.filter = {
      targetFromDate: formatDate(values.targetFromDate, DateTimeFormatType.yyyyMMddHHmmss) ?? '',
      targetToDate: formatDate(values.targetToDate, DateTimeFormatType.yyyyMMddHHmmss) ?? '',
      orderType: joinString(
        orderTypes.filter((x) => x.checked).map((x) => x.value),
        ',',
      ),
    };

    dispatch(exportOrderDataAsync(condition));
  };

  formRef.current = useFormik({
    initialValues: {
      targetFromDate: null,
      targetToDate: null,
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const onChange = (options: any) => {
    setOrderTypes(options);
  };

  const onToChange = (date: Date) => {
    formRef.current.setFieldValue('targetToDate', date, true);
  };

  const onFromChange = (date: Date) => {
    formRef.current.setFieldValue('targetFromDate', date, true);
  };

  return (
    <Stack>
      <Box display='flex' alignItems='center'>
        <BagCheckIcon fontSize='medium' viewBox={'0 0 16 16'} />
        <Typography ref={ref} ml={1} variant='h4' className={classes.textTitle}>
          注文データ連携ファイル書き出し
        </Typography>
      </Box>
      <Typography mt={1}>
        注文データ連携で利用されるヘッダー情報もしくはボディ情報を指定された期間および注文種別で
        CSV ファイルに書き出します。
      </Typography>
      <Typography>
        ファイルは自動でサーバー上に保存されますので、ダウンロードやアップロードの操作は必要ありません。
      </Typography>
      <Typography mb={1} className={classes.error}>
        注文データ連携ファイルは一定時間毎に自動出力されています。注文データの差し替えが必要な場合などにご利用ください。
      </Typography>

      <form onSubmit={formRef.current.handleSubmit} noValidate>
        <DateTimePickerControl
          form={formRef.current}
          fromLabel='書き出し開始日'
          toLabel='書き出し終了日'
          variant='FromTo'
          onFromChange={onFromChange}
          onToChange={onToChange}
          fromName='targetFromDate'
          toName='targetToDate'
          inputFromValue={formRef.current.values.targetFromDate}
          inputToValue={formRef.current.values.targetToDate}
          maxDate={new Date()}
          mode='datetime'
          inputFormat={DateTimeFormatType.yyyy_MM_dd_HH_mm}
        />
        <Box className={classes.orderType}>
          <Typography>注文種別（出力したい種別にチェックを入れてください）</Typography>
          <CheckboxGroupControl onChange={onChange} options={orderTypes} />
        </Box>
        <Box mt={1}>
          <ExportButton
            variant='outlined'
            type='submit'
            disabled={exportOrderFetching}
            classes={{ outlined: classes.buttonOutlined }}
            loading={exportOrderFetching}
          >
            ファイルヘ書き出し
          </ExportButton>
          {orderData && orderData.data === 0 && (
            <Typography className={classes.error}>指定された条件では注文データが見付かりません。</Typography>
          )}
        </Box>
      </form>
    </Stack>
  );
});

export const OrderOutput = React.memo(OrderOutputComponent);
