import { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { CombinedState, MAX_FILE_SIZE, MAX_FILE_SIZE_ERROR, isAfterDate, isEmpty, strTrim } from '../../../core';
import { CreateQRCodeFormComponent } from '../form/create-form.component';
import {
  getHospitalBrochureAsync,
  registerHospitalBrochureAsync,
  getCategoryOptionsAsync,
  updateHospitalBrochureAsync,
} from '../data-access/actions';
import { useParams } from 'react-router-dom';
import { ClipboardPlusIcon } from '../../../common';

export const QRCodeComponent = () => {
  const dispatch = useDispatch();
  const { embeddedQrCodeInfoCurrent } = useSelector((state: CombinedState) => state['qrCode']);
  const [isRegister, setIsRegister] = useState(true);
  const formRef = React.useRef<any>();
  const [formSubmit, setFormSubmit] = useState(false);
  const { hb_id } = useParams();

  /* eslint-disable no-useless-escape */
  const urlMatch = /^((https|http):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;

  const handleSubmit = (formData: any) => {
    if (isRegister) {
      dispatch(registerHospitalBrochureAsync(formData, embeddedQrCodeInfoCurrent));
    } else {
      dispatch(updateHospitalBrochureAsync(formData, embeddedQrCodeInfoCurrent));
    }
  };

  const handleChange = (e: any) => {
    if (e.target.name === 'descriptionTemp') {
      formRef.current.setFieldValue('description', e.target.value);
    }
  };

  const validationSchema = yup.object({
    category: yup.string().test('type', 'カテゴリが入力されていません。', (value) => {
      return value !== '0';
    }),
    title: yup
      .string()
      .transform((v) => (isEmpty(v) ? '' : strTrim(v)))
      .max(256, '256文字以内で指定してください。')
      .required('資料タイトルが入力されていません。'),
    file: yup
      .mixed()
      .test('required', 'ファイルアップロードが入力されていません。', (value) => {
        if (hb_id) return true;
        return !!value;
      })
      .test('type', 'PDF形式以外のファイルはアップロードできません。', (value) => {
        if (value === null || value === undefined) return true;
        return value && value.type === 'application/pdf';
      })
      .test('fileSize', MAX_FILE_SIZE_ERROR, (value) => {
        if (value === null || value === undefined) return true;
        return value.size <= MAX_FILE_SIZE;
      }),
    jumpUrl: yup
      .string()
      .matches(urlMatch, '有効な URL を入力してください。(例: https://yourwebsite.com)')
      .max(500, '500文字以内で指定してください。')
      .required('遷移先 URLが入力されていません。'),
    description: yup.string().max(1000, '1000文字以内で指定してください。').required('説明文が入力されていません。'),
    releaseFlag: yup.string().required('公開設定が入力されていません。'),
    releaseStartDatetime: yup
      .date()
      .typeError('公開開始日時のフォーマットが不正です。')
      .nullable()
      .required('公開開始日時が入力されていません。')
      .test('later-than-end-date', '公開開始日時は公開終了日時より前の日時を指定してください。', (value, context) => {
        if (isEmpty(context.parent.releaseEndDatetime?.toString()) || isEmpty(value?.toString())) return true;
        else {
          return !isAfterDate(value, context.parent.releaseEndDatetime);
        }
      }),
    releaseEndDatetime: yup
      .date()
      .typeError('公開終了日時日のフォーマットが不正です。')
      .nullable()
      .required('公開終了日時が入力されていません。'),
    isQrCode: yup.boolean().test('is-true', 'QRコードが設定されていません。', (value) => {
      return embeddedQrCodeInfoCurrent.some((obj: any) => obj.isQrCode === true);
    }),
  });

  formRef.current = useFormik({
    initialValues: {
      hb_id: null,
      isQrCode: true,
      category: '0',
      title: '',
      file: null,
      fileBase64: '',
      jumpUrl: '',
      description: '',
      releaseStartDatetime: new Date(),
      releaseEndDatetime: new Date(2099, 11, 31, 23, 59, 59),
      releaseFlag: '1',
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (hb_id) {
      setIsRegister(false);
      dispatch(getHospitalBrochureAsync(hb_id));
    }
    dispatch(getCategoryOptionsAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display='flex' flexDirection='column'>
      <Grid container direction='row' sx={{ padding: '0 60px' }}>
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' mt={4} mb={1}>
            <ClipboardPlusIcon fontSize='small' viewBox={'0 0 16 16'} />
            <Typography ml={1} variant='h2' fontSize='1.5rem' fontWeight='500'>
              資料アップロード
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight='initial' variant='h6' fontSize='11pt'>
            全ての項目は入力必須項目となります。
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <form
            autoComplete='off'
            onSubmit={(formData: any) => {
              setFormSubmit(true);
              formRef.current.handleSubmit(formData);
            }}
            noValidate
            onChange={handleChange}
          >
            <CreateQRCodeFormComponent form={formRef.current} isRegister={isRegister} formSubmit={formSubmit} />
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};
