import { getLogs } from '../../../common';
import { ActionUnion, createAction, getApiError, LogInfo, SearchCondition, ThunkAction } from '../../../core';

export enum DashboardActionTypes {
  GET_LOGS = 'DASHBOARD_GET_LOGS',
  GET_LOGS_SUCCESS = 'DASHBOARD_GET_LOGS_SUCCESS',
  GET_LOGS_FAILURE = 'DASHBOARD_GET_LOGS_FAILURE',
}

export const dashboardActions = {
  getLogs: () => createAction(DashboardActionTypes.GET_LOGS),
  getLogsSuccess: (data: LogInfo[]) => createAction(DashboardActionTypes.GET_LOGS_SUCCESS, { data }),
  getLogsFailure: (error: any) =>
    createAction(DashboardActionTypes.GET_LOGS_FAILURE, {
      error,
    }),
};

export type DashboardActions = ActionUnion<typeof dashboardActions>;

/**
 * Get logs
 * @returns
 */
export const getLogsAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(dashboardActions.getLogs());
    try {
      const data = await getLogs(condition);
      dispatch(dashboardActions.getLogsSuccess(data.results));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(dashboardActions.getLogsFailure(errorText));
    }
  };
};
