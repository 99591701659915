import { apiService } from '../http-client';
const isReachable = async (url: string, method: string): Promise<boolean> => {
  try {
    await apiService.serverRequest(url, {
      method,
    });
    return true;
  } catch (error: any) {
    return ![0, 404].includes(error.code);
  }
};

const isOnline = () => window.navigator.onLine;

export { isReachable, isOnline };
