import { QrScanActionTypes, QrScanActions } from './actions';

interface QrScanState {
  jumpUrl: string;
}

const defaultState: QrScanState = {
  jumpUrl: '',
};

export const qrScanReducer = (state = defaultState, action: QrScanActions): QrScanState => {
  switch (action.type) {
    case QrScanActionTypes.GET_JUMP_URL:
      return {
        ...state,
      };
    case QrScanActionTypes.GET_JUMP_URL_SUCCESS:
      return {
        ...state,
        jumpUrl: action.payload.data
      };
    case QrScanActionTypes.GET_JUMP_URL_FAILED:
      return {
        ...state,
        jumpUrl: '/'
      };
    default:
      return state;
  }
};
