/* eslint-disable jsx-a11y/anchor-is-valid */
const DownloadPDF = (props: any) => {
  const { fileError, base64String, file, fileName } = props;

  const downloadPDF = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = base64String;
    downloadLink.download = fileName ? fileName : file.name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      {(base64String || file) && !fileError ? (
        <>
          <a href='#' target='blank' onClick={downloadPDF}>
            {fileName ? fileName : file.name}
          </a>
          が登録されています。
        </>
      ) : (
        <>登録されているファイルはありません。</>
      )}
    </>
  );
};

export default DownloadPDF;
