import { ActionUnion, createAction, getApiError, ThunkAction } from '../../../core';
import {
  embeddedQrCode,
  getHospitalBrochure,
  registerHospitalBrochure,
  getCategoryOptions,
  updateHospitalBrochure,
  checkPdfFile,
} from '../service/service';
import { EmbeddedQrCodeInfo } from './states';

export enum QrCodeActionTypes {
  OPEN_DIALOG = 'OPEN_DIALOG',
  CLOSE_DIALOG = 'CLOSE_DIALOG',
  SET_PAGE_NUMBER = 'SET_PAGE_NUMBER',
  SET_QR_CODE_STATE = 'SET_QR_CODE_STATE',
  SET_QR_CODE_STATE_CURRENT = 'SET_QR_CODE_STATE_CURRENT',
  GENERATE_QR_CODE = 'GENERATE_QR_CODE',
  REMOVE_QR_CODE = 'REMOVE_QR_CODE',
  SET_PDF_SIZE_ORIGINAL = 'SET_PDF_SIZE_ORIGINAL',
  SET_PDF_SIZE_RENDER = 'SET_PDF_SIZE_RENDER',
  SET_QR_CODE_SIZE = 'SET_QR_CODE_SIZE',
  SET_PDF_FILE = 'SET_PDF_FILE',
  REMOVE_EMBEDDED_FILE = 'REMOVE_EMBEDDED_FILE',
  EMBEDDED_QR_CODE = 'EMBEDDED_QR_CODE',
  EMBEDDED_QR_CODE_SUCCESS = 'EMBEDDED_QR_CODE_SUCCESS',
  EMBEDDED_QR_CODE_FAILURE = 'EMBEDDED_QR_CODE_FAILURE',
  GET_HOSPITAL_BROCHURE = 'GET_HOSPITAL_BROCHURE',
  GET_HOSPITAL_BROCHURE_SUCCESS = 'GET_HOSPITAL_BROCHURE_SUCCESS',
  GET_HOSPITAL_BROCHURE_FAILURE = 'GET_HOSPITAL_BROCHURE_FAILURE',
  REGISTER_HOSPITAL_BROCHURE = 'REGISTER_HOSPITAL_BROCHURE',
  REGISTER_HOSPITAL_BROCHURE_SUCCESS = 'REGISTER_HOSPITAL_BROCHURE_SUCCESS',
  REGISTER_HOSPITAL_BROCHURE_FAILURE = 'REGISTER_HOSPITAL_BROCHURE_FAILURE',
  UPDATE_HOSPITAL_BROCHURE = 'UPDATE_HOSPITAL_BROCHURE',
  UPDATE_HOSPITAL_BROCHURE_SUCCESS = 'UPDATE_HOSPITAL_BROCHURE_SUCCESS',
  UPDATE_HOSPITAL_BROCHURE_FAILURE = 'UPDATE_HOSPITAL_BROCHURE_FAILURE',
  GET_CATEGORY_OPTIONS = 'GET_CATEGORY_OPTIONS',
  GET_CATEGORY_OPTIONS_SUCCESS = 'GET_CATEGORY_OPTIONS_SUCCESS',
  GET_CATEGORY_OPTIONS_FAILURE = 'GET_CATEGORY_OPTIONS_FAILURE',

  CHECK_PDF_FILE = 'CHECK_PDF_FILE',
  CHECK_PDF_FILE_SUCCESS = 'CHECK_PDF_FILE_SUCCESS',
  CHECK_PDF_FILE_FAILURE = 'CHECK_PDF_FILE_FAILURE',
}

export const qrCodeActions = {
  openDialog: () => createAction(QrCodeActionTypes.OPEN_DIALOG),
  closeDialog: () => createAction(QrCodeActionTypes.CLOSE_DIALOG),
  getHospitalBrochure: () => createAction(QrCodeActionTypes.GET_HOSPITAL_BROCHURE),
  getHospitalBrochureSuccess: (data: any) => createAction(QrCodeActionTypes.GET_HOSPITAL_BROCHURE_SUCCESS, { data }),
  getHospitalBrochureFailure: (error: any) =>
    createAction(QrCodeActionTypes.GET_HOSPITAL_BROCHURE_FAILURE, {
      error,
    }),
  setQrCodeState: (state: EmbeddedQrCodeInfo[]) => createAction(QrCodeActionTypes.SET_QR_CODE_STATE, { state }),
  setQrCodeStateCurrent: (state: EmbeddedQrCodeInfo[]) =>
    createAction(QrCodeActionTypes.SET_QR_CODE_STATE_CURRENT, { state }),
  generateQrCode: (currentPage: number) => createAction(QrCodeActionTypes.GENERATE_QR_CODE, { currentPage }),
  removeQrCode: (currentPage: number) => createAction(QrCodeActionTypes.REMOVE_QR_CODE, { currentPage }),
  setPageNumber: (pageNumber: number) => createAction(QrCodeActionTypes.SET_PAGE_NUMBER, { pageNumber }),
  setPDFSizeOriginal: (height: number, width: number, pageNumber: number) =>
    createAction(QrCodeActionTypes.SET_PDF_SIZE_ORIGINAL, { height, width, pageNumber }),
  setPDFSizeRender: (height: number, width: number, currentPage: number) =>
    createAction(QrCodeActionTypes.SET_PDF_SIZE_RENDER, { height, width, currentPage }),
  setQrCodeSize: (qrCodeInfo: any, currentPage: number) =>
    createAction(QrCodeActionTypes.SET_QR_CODE_SIZE, { qrCodeInfo, currentPage }),
  setPdfFile: (base64Original: string | ArrayBuffer | null) =>
    createAction(QrCodeActionTypes.SET_PDF_FILE, { base64Original }),
  removeEmbeddedFile: () => createAction(QrCodeActionTypes.REMOVE_EMBEDDED_FILE),
  embeddedQrCode: () => createAction(QrCodeActionTypes.EMBEDDED_QR_CODE),
  embeddedQrCodeSuccess: (data: string) => createAction(QrCodeActionTypes.EMBEDDED_QR_CODE_SUCCESS, { data }),
  embeddedQrCodeFailure: (error: any) =>
    createAction(QrCodeActionTypes.EMBEDDED_QR_CODE_FAILURE, {
      error,
    }),
  registerHospitalBrochure: () => createAction(QrCodeActionTypes.REGISTER_HOSPITAL_BROCHURE),
  registerHospitalBrochureSuccess: (data: any) =>
    createAction(QrCodeActionTypes.REGISTER_HOSPITAL_BROCHURE_SUCCESS, { data }),
  registerHospitalBrochureFailure: (error: any) =>
    createAction(QrCodeActionTypes.REGISTER_HOSPITAL_BROCHURE_FAILURE, {
      error,
    }),
  updateHospitalBrochure: () => createAction(QrCodeActionTypes.UPDATE_HOSPITAL_BROCHURE),
  updateHospitalBrochureSuccess: (data: any) =>
    createAction(QrCodeActionTypes.UPDATE_HOSPITAL_BROCHURE_SUCCESS, { data }),
  updateHospitalBrochureFailure: (error: any) =>
    createAction(QrCodeActionTypes.UPDATE_HOSPITAL_BROCHURE_FAILURE, {
      error,
    }),
  getCategoryOptions: () => createAction(QrCodeActionTypes.GET_CATEGORY_OPTIONS),
  getCategoryOptionsSuccess: (data: any[]) => createAction(QrCodeActionTypes.GET_CATEGORY_OPTIONS_SUCCESS, { data }),
  getCategoryOptionsFailure: (error: any) => createAction(QrCodeActionTypes.GET_CATEGORY_OPTIONS_FAILURE, { error }),

  checkPdfFile: () => createAction(QrCodeActionTypes.CHECK_PDF_FILE),
  checkPdfFileSuccess: (data: any[]) => createAction(QrCodeActionTypes.CHECK_PDF_FILE_SUCCESS, { data }),
  checkPdfFileFailure: (error: any) => createAction(QrCodeActionTypes.CHECK_PDF_FILE_FAILURE, { error }),
};

export type QrCodeActions = ActionUnion<typeof qrCodeActions>;

export const embeddedQrCodeAsync = (
  data: EmbeddedQrCodeInfo[],
  currentState: EmbeddedQrCodeInfo[],
  fileOriginal: File,
  hbId: string,
): ThunkAction => {
  return async (dispatch) => {
    dispatch(qrCodeActions.embeddedQrCode());
    try {
      const response: any = await embeddedQrCode(data, fileOriginal, hbId);
      dispatch(qrCodeActions.embeddedQrCodeSuccess(response?.data));
      dispatch(qrCodeActions.setQrCodeStateCurrent(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrCodeActions.embeddedQrCodeFailure(errorText));
      dispatch(qrCodeActions.setQrCodeState(currentState));
    }
  };
};

export const getHospitalBrochureAsync = (hb_id: string): ThunkAction => {
  return async (dispatch) => {
    dispatch(qrCodeActions.getHospitalBrochure());
    try {
      const response: any = await getHospitalBrochure(hb_id);
      dispatch(qrCodeActions.getHospitalBrochureSuccess(response?.data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrCodeActions.getHospitalBrochureFailure(errorText));
    }
  };
};

export const registerHospitalBrochureAsync = (data: any, embeddedQrCodeInfo: EmbeddedQrCodeInfo[]): ThunkAction => {
  return async (dispatch) => {
    dispatch(qrCodeActions.registerHospitalBrochure());
    try {
      const response: any = await registerHospitalBrochure(data, embeddedQrCodeInfo);
      dispatch(qrCodeActions.registerHospitalBrochureSuccess(response?.data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrCodeActions.registerHospitalBrochureFailure(errorText));
    }
  };
};

export const updateHospitalBrochureAsync = (data: any, embeddedQrCodeInfo: EmbeddedQrCodeInfo[]): ThunkAction => {
  return async (dispatch) => {
    dispatch(qrCodeActions.updateHospitalBrochure());
    try {
      const response: any = await updateHospitalBrochure(data, embeddedQrCodeInfo);
      dispatch(qrCodeActions.updateHospitalBrochureSuccess(response?.data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrCodeActions.updateHospitalBrochureFailure(errorText));
    }
  };
};

export const getCategoryOptionsAsync = (): ThunkAction => {
  return async (dispatch) => {
    dispatch(qrCodeActions.getCategoryOptions());
    try {
      const response: any = await getCategoryOptions();
      dispatch(qrCodeActions.getCategoryOptionsSuccess(response?.data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrCodeActions.getCategoryOptionsFailure(errorText));
    }
  };
};

export const checkPdfFileAsync = (file: File): ThunkAction => {
  return async (dispatch) => {
    dispatch(qrCodeActions.checkPdfFile());
    try {
      const response: any = await checkPdfFile(file);
      dispatch(qrCodeActions.checkPdfFileSuccess(response?.data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(qrCodeActions.checkPdfFileFailure(errorText));
    }
  };
};
