/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJumpUrlAsync } from '../data-access/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { CombinedState, MENU_HREF } from '../../../core';
import { Box, Typography } from '@mui/material';

export const QrScanComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jumpUrl } = useSelector((state: CombinedState) => state['qrScan']);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const hbHistoryId = queryParams.get('hb_history_id');
  const [jumpError, setJumpError] = useState(false);

  useEffect(() => {
    if (hbHistoryId) {
      dispatch(getJumpUrlAsync(hbHistoryId));
    } else {
      navigate(MENU_HREF.ROOT);
    }
  }, [hbHistoryId]);

  useEffect(() => {
    if (jumpUrl === MENU_HREF.ROOT) {
      setJumpError(true);
    } else if (jumpUrl) {
      window.location.href = jumpUrl;
    }
  }, [jumpUrl]);

  return (
    <>
      {jumpError && (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          sx={{
            height: 'calc(100vh - 164px)',
          }}
        >
          <Typography>
            指定されたページの公開は終了しました。
            <br />
            ご利用ありがとうございました。
          </Typography>
        </Box>
      )}
    </>
  );
};
