import { Typography, Stack, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ExportButton, FileUploadIcon } from '../../../../common';
import { CombinedState, EXPORT_REBATE_TYPE, SearchCondition } from '../../../../core';
import { exportRebateAsync } from '../../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      fontSize: '1.5rem',
    },
    linkDescription: {
      marginTop: '0.2em !important',
    },
    buttonOutlined: {
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
  }),
);

export const ExportComponent = () => {
  const classes = useStyles();
  const { exportRebateSettingFetching } = useSelector((state: CombinedState) => state['rebateAmount']);
  const dispatch = useDispatch();

  const onExport = () => {
    const condition = new SearchCondition();
    condition.filter.type = EXPORT_REBATE_TYPE.EX_ADVISOR_FEE_RATE_SETTING;
    dispatch(exportRebateAsync(condition));
  };

  return (
    <Stack spacing={1}>
      <Box display='flex' alignItems='center'>
        <FileUploadIcon fontSize='medium' viewBox={'0 0 16 16'} />
        <Typography ml={1} className={classes.textHeader} variant='h4'>
          栄養指導料率一覧ファイルの書き出し
        </Typography>
      </Box>
      <Box px={3} display='flex' flexDirection='column'>
        <Box>
          <ExportButton
            onClick={onExport}
            variant='outlined'
            disabled={exportRebateSettingFetching}
            classes={{ outlined: classes.buttonOutlined }}
            loading={exportRebateSettingFetching}
          >
            CSV エクスポート
          </ExportButton>
        </Box>
        <Typography component='span' className={classes.linkDescription}>
          現在登録されている栄養指導料率一覧をすべて書き出します。（インポートされた最新のデータが展開された情報で書き出されます。）
        </Typography>
      </Box>
    </Stack>
  );
};
