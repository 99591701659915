import { Box, Button, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import jpLocale from 'date-fns/locale/ja';
import React, { useCallback } from 'react';
import { debouncedSave } from '../../core';
interface CustomInputProps {
  variant: 'Single' | 'FromTo' | 'FormSearch' | 'FormTextSearch';
  fromName: string;
  fromLabel: string;
  toLabel?: string;
  fromErrorText?: string;
  toErrorText?: string;
  onFromChange: (date: Date) => void;
  onToChange?: (date: Date) => void;
  onInputChange?: (text: string) => void;
  onClick?: () => void;
  toName?: string;
  searchLabel?: string;
  searchPlaceholder?: string;
  buttonName?: string;
  inputFromValue?: Date;
  inputToValue?: Date;
  disablePast?: boolean;
  minDate?: Date;
  maxDate?: Date;
  form: any;
  inputTextValue?: string;
  inputFormat: string;
  mode: 'date' | 'datetime';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      position: 'relative',
    },
    error: {
      margin: 0,
      fontSize: '0.75rem',
      marginTop: `3px !important`,
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
      color: (theme.palette.common as any).color.textError,
    },
    singleLabel: {
      backgroundColor: (theme.palette.common as any).color.gray,
      border: `1px solid ${(theme.palette.common as any).color.borderGray}`,
      color: (theme.palette.common as any).color.textPrimary,
      borderRadius: 4,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: 0,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    textFieldRightRadius: {
      marginLeft: -1,
      zIndex: 99,
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
      '& .Mui-focused': {
        '& fieldset': {
          borderWidth: `1px !important`,
          // boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)',
        },
      },
    },
    textFieldNoneRadius: {
      marginLeft: -1,
      zIndex: 99,
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 0,
        },
      },
      '& .Mui-focused': {
        '& fieldset': {
          borderWidth: `1px !important`,
          // boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)',
        },
      },
    },
    searchTextField: {
      width: '20em',

      color: `${(theme.palette.common as any).color.textPrimary}`,
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 0,
        },
      },
      '& .Mui-focused': {
        '& fieldset': {
          borderWidth: `1px !important`,
          // boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)',
        },
      },
    },
    buttonRoot: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: -1,
      zIndex: 999,
    },
    buttonOutlined: {
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    multiLabel: {
      backgroundColor: `${(theme.palette.common as any).color.gray}`,
      border: `1px solid ${(theme.palette.common as any).color.borderGray}`,
      color: `${(theme.palette.common as any).color.textPrimary}`,
      borderRadius: 0,
      borderRight: 0,
      borderLeft: 0,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginLeft: -1,
      zIndex: 999,
    },
  }),
);

export const CustomDateControl = (props: any) => {
  const { mode, error, classes, onBlur } = props;
  return mode === 'datetime' ? (
    <DateTimePicker
      renderInput={(props) => (
        <TextField
          {...props}
          inputProps={{
            ...props.inputProps,
            placeholder: '年/月/日--:--',
          }}
          error={error}
          variant='outlined'
          size='small'
          classes={classes}
          onBlur={onBlur}
        />
      )}
      {...props}
    />
  ) : (
    <DatePicker
      renderInput={(props) => (
        <TextField
          {...props}
          inputProps={{
            ...props.inputProps,
            placeholder: '年/月/日',
          }}
          error={error}
          variant='outlined'
          size='small'
          classes={classes}
          onBlur={onBlur}
        />
      )}
      {...props}
    />
  );
};

export const DateTimePickerControl = React.memo((props: CustomInputProps) => {
  const classes = useStyles();
  const {
    fromLabel,
    toLabel,
    fromName,
    toName,
    variant,
    searchLabel,
    searchPlaceholder,
    buttonName,
    inputFromValue,
    inputToValue,
    disablePast = false,
    maxDate = new Date('2099-12-31'),
    minDate = new Date('1900-01-01'),
    form,
    inputTextValue,
    onFromChange,
    onInputChange,
    onToChange,
    onClick,
    inputFormat,
    mode,
  } = props;

  const currentFromError =
    variant === 'Single' ? form.errors[fromName] : form.errors[toName ?? ''] ?? form.errors[fromName];

  const hasFromError =
    variant === 'Single'
      ? Boolean(currentFromError && form.touched[fromName])
      : Boolean(currentFromError && (form.touched[fromName] || form.touched[toName ?? '']));

  const onStartChange = (value: any) => {
    onFromChange(value);
  };

  const onEndChange = (value: any) => {
    onToChange && onToChange(value);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange && onInputChange(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    debouncedSave(() => {
      onClick && onClick();
    }, 100),
    [],
  );

  const getControl = (variant: string) => {
    switch (variant) {
      default:
        return (
          <>
            <Typography className={classes.singleLabel}>{fromLabel}</Typography>
            <CustomDateControl
              ampm={false}
              minDate={minDate}
              maxDate={maxDate}
              disablePast={disablePast}
              inputFormat={inputFormat}
              value={inputFromValue}
              onChange={onStartChange}
              classes={{ root: classes.textFieldRightRadius }}
              onBlur={() => form.setFieldTouched(fromName, true, true)}
              mode={mode}
              error={Boolean(currentFromError)}
            />
          </>
        );
      case 'FromTo':
        return (
          <>
            <Typography className={classes.singleLabel}>{fromLabel}</Typography>
            <CustomDateControl
              ampm={false}
              minDate={minDate}
              maxDate={maxDate}
              disablePast={disablePast}
              inputFormat={inputFormat}
              value={inputFromValue}
              onChange={onStartChange}
              onBlur={() => form.setFieldTouched(fromName, true, true)}
              mode={mode}
              classes={{ root: classes.textFieldNoneRadius }}
              error={Boolean(currentFromError)}
            />
            <Typography className={classes.multiLabel}>{toLabel}</Typography>
            <CustomDateControl
              ampm={false}
              minDate={minDate}
              maxDate={maxDate}
              disablePast={disablePast}
              inputFormat={inputFormat}
              value={inputToValue}
              onChange={onEndChange}
              onBlur={() => form.setFieldTouched(toName, true, true)}
              mode={mode}
              classes={{ root: classes.textFieldRightRadius }}
              error={Boolean(currentFromError)}
            />
          </>
        );
      case 'FormSearch':
        return (
          <>
            <Typography className={classes.singleLabel}>{fromLabel}</Typography>
            <CustomDateControl
              ampm={false}
              minDate={minDate}
              maxDate={maxDate}
              disablePast={disablePast}
              inputFormat={inputFormat}
              value={inputFromValue}
              onChange={onStartChange}
              onBlur={() => form.setFieldTouched(fromName, true, true)}
              classes={{ root: classes.textFieldNoneRadius }}
              mode={mode}
              error={Boolean(currentFromError)}
            />
            <Typography className={classes.multiLabel}>{toLabel}</Typography>
            <CustomDateControl
              ampm={false}
              minDate={minDate}
              maxDate={maxDate}
              disablePast={disablePast}
              inputFormat={inputFormat}
              value={inputToValue}
              onChange={onEndChange}
              onBlur={() => form.setFieldTouched(toName, true, true)}
              mode={mode}
              classes={{ root: classes.textFieldNoneRadius }}
              error={Boolean(currentFromError)}
            />
            <Button
              classes={{ root: classes.buttonRoot, outlined: classes.buttonOutlined }}
              onClick={onSearch}
              variant='outlined'
            >
              {buttonName ?? `表示`}
            </Button>
          </>
        );
      case 'FormTextSearch':
        return (
          <>
            <Typography className={classes.singleLabel}>{fromLabel}</Typography>
            <CustomDateControl
              ampm={false}
              minDate={minDate}
              maxDate={maxDate}
              disablePast={disablePast}
              inputFormat={inputFormat}
              value={inputFromValue}
              onChange={onStartChange}
              onBlur={() => form.setFieldTouched(fromName, true, true)}
              mode={mode}
              classes={{ root: classes.textFieldNoneRadius }}
              error={Boolean(currentFromError)}
            />
            <Typography className={classes.multiLabel}>{toLabel}</Typography>
            <CustomDateControl
              ampm={false}
              minDate={minDate}
              maxDate={maxDate}
              disablePast={disablePast}
              inputFormat={inputFormat}
              value={inputToValue}
              onChange={onEndChange}
              onBlur={() => form.setFieldTouched(toName, true, true)}
              mode={mode}
              classes={{ root: classes.textFieldNoneRadius }}
              error={Boolean(currentFromError)}
            />
            <Typography className={classes.multiLabel}>{searchLabel}</Typography>
            <TextField
              value={inputTextValue ?? ''}
              size='small'
              variant='outlined'
              fullWidth
              id='searchInput'
              onChange={onSearchChange}
              classes={{ root: classes.searchTextField }}
              placeholder={searchPlaceholder}
            />
            <Button
              classes={{ root: classes.buttonRoot, outlined: classes.buttonOutlined }}
              onClick={onSearch}
              variant='outlined'
            >
              {buttonName ?? `表示`}
            </Button>
          </>
        );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jpLocale}>
      <Box className={classes.content}>{getControl(variant)}</Box>
      {hasFromError && <Typography className={classes.error}>{currentFromError as string}</Typography>}
    </LocalizationProvider>
  );
});
