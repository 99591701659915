import {
  Stack,
  Typography,
  Box,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { CombinedState, LogInfo, MENU_HREF } from '../../../../core';
import { useSelector } from 'react-redux';
import { FileDownloadIcon, getLogLevel, getLogTitle, LogLevelIcon, LogMessage } from '../../../../common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      fontSize: '1.5rem',
    },
    tableRoot: {
      width: '100%',
      minWidth: '60em',
      maxWidth: '90em',
      tableLayout: 'fixed',
      '& a': {
        color: (theme.palette.common as any).color.primary,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
  }),
);

export const FeeNotifiesComponent = () => {
  const classes = useStyles();
  const { nutritionPaymentLogs } = useSelector((state: CombinedState) => state['rebateAmount']);

  return (
    <Stack spacing={2}>
      <Box display='flex' alignItems='center'>
        <FileDownloadIcon fontSize='medium' viewBox={'0 0 16 16'} />
        <Typography ml={1} variant='h4' className={classes.textHeader}>
          栄養指導料支払通知書（仕入明細書）取り込み結果
        </Typography>
      </Box>

      <TableContainer component={Box} px={3}>
        <Table classes={{ root: classes.tableRoot }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width='150px'>日時</TableCell>
              <TableCell width='250px'>処理</TableCell>
              <TableCell width='150px'>レベル</TableCell>
              <TableCell>概要</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nutritionPaymentLogs?.map((row: LogInfo) => (
              <TableRow hover key={row.logId}>
                <TableCell>{row.recordDatetime}</TableCell>
                <TableCell>{getLogTitle(row)}</TableCell>
                <TableCell>
                  <Box className='lsc-table-icon' display='flex' alignItems='center'>
                    <LogLevelIcon status={row.logLevel} />
                    <Typography ml={1} component='span'>
                      {getLogLevel(row.logLevel)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <LogMessage sender={MENU_HREF.REBATE_AMOUNT} log={row} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {nutritionPaymentLogs.length === 0 && <Typography mt={2}>データが登録されていません</Typography>}
      </TableContainer>
    </Stack>
  );
};
