import { Stack } from '@mui/material';
import { ImportComponent } from './import.component';
import { ExportComponent } from './export.component';
import { LogComponent } from './log.component';

export const FileHandlingComponent = () => {
  return (
    <Stack spacing={4}>
      <ImportComponent />
      <ExportComponent />
      <LogComponent />
    </Stack>
  );
};
