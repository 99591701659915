import { Box, Button, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { SearchIcon, TextFieldControl } from '../../../../common';
import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CombinedState, isEmpty } from '../../../../core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      fontSize: '1.5rem',
    },
    linkDescription: {
      marginTop: '-0.1em !important',
      fontSize: '0.9rem',
    },
    linkText: {
      color: (theme.palette.common as any).color.primary,
    },
    buttonOutlined: {
      '&:active,&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    textDanger: {
      color: (theme.palette.common as any).color.textDanger,
    },
    infoText: {
      color: (theme.palette.common as any).color.primary,
    },
  }),
);
interface SearchFormProps {
  onSubmit: (value: any) => void;
  searchState: any;
  isChangeCount: Boolean;
}

export const SearchFormComponent = (props: SearchFormProps) => {
  const classes = useStyles();
  const { onSubmit, searchState, isChangeCount } = props;
  const formRef = useRef<any>();
  const { nutritionRates, fetching } = useSelector((state: CombinedState) => state['rebateAmount']);

  const handleSubmit = (value: any) => {
    onSubmit(value);
  };

  formRef.current = useFormik({
    initialValues: {
      makerName: '',
      goodsName: '',
      goodsGroupName: '',
    },
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (!isEmpty(searchState)) {
      formRef.current.setValues({
        makerName: searchState?.makerName ?? '',
        goodsName: searchState?.goodsName ?? '',
        goodsGroupName: searchState?.goodsGroupName ?? '',
      });
    } else return;
  }, [searchState]);

  const hasSearchCriteria = () => {
    return !isEmpty(searchState.makerName) || !isEmpty(searchState.goodsName) || !isEmpty(searchState.goodsGroupName);
  };

  return (
    <form onSubmit={formRef.current.handleSubmit} noValidate>
      <Grid container>
        <Grid item xs={12}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <SearchIcon fontSize='medium' viewBox={'0 0 16 16'} />
            <Typography ml={1} variant='h4' className={classes.textHeader}>
              栄養指導料率の検索
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.linkDescription}>設定されている栄養指導料率を検索できます。</Typography>
          <Typography className={classes.linkDescription}>
            全データを確認したい場合は、何も入力せずに「検索」ボタンをクリックしてください。（全データは表示量が多いため、インポート・エクスポートからデータをエクスポートしてご確認いただくと扱いやすいです。）
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={0.5} spacing={2}>
        <Grid item xs={3}>
          <TextFieldControl
            fullWidth
            id='makerName'
            name='makerName'
            type='text'
            label='メーカー名'
            size='small'
            value={formRef.current.values.makerName}
            onChange={formRef.current.handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldControl
            fullWidth
            id='goodsGroupName'
            name='goodsGroupName'
            type='text'
            label='商品体系４名'
            size='small'
            value={formRef.current.values.goodsGroupName}
            onChange={formRef.current.handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldControl
            fullWidth
            id='goodsName'
            name='goodsName'
            type='text'
            label='商品名'
            size='small'
            value={formRef.current.values.goodsName}
            onChange={formRef.current.handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <Button type='submit' variant='outlined' classes={{ outlined: classes.buttonOutlined }}>
            検索
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography component='span'>※商品名を指定すると対応する商品体系4の情報が表示されます。</Typography>
        </Grid>
        {(!isChangeCount || !fetching) && hasSearchCriteria() && (
          <Grid item xs={12}>
            {nutritionRates.count === 0 ? (
              <Typography className={classes.textDanger} component='span'>
                指定された条件ではデータが存在しませんでした。
              </Typography>
            ) : (
              <Typography className={classes.infoText} component='span'>
                {`${nutritionRates.count}件のデータが存在しました。`}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </form>
  );
};
