import { AdvisorFeeRatePagination, apiService, SearchCondition } from '../../../core';

export const getAdvisorFeeRates = async (condition: SearchCondition): Promise<AdvisorFeeRatePagination> => {
  return await apiService.get<AdvisorFeeRatePagination>('/advisorFeeRates', { ...condition.filter });
};

export const importFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await apiService.post('/import/rebate_amount', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const exportFile = async (condition: SearchCondition) => {
  return await apiService.getFile<any>('/export/rebate', { ...condition.filter }, { responseType: 'blob' });
};
