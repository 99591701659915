import { useState, useEffect } from 'react';
import { Stage, Layer } from 'react-konva';
import Rectangle from './rectangle.component';
import { useSelector, useDispatch } from 'react-redux';
import { CombinedState, validatePosition } from '../../../core';
import { qrCodeActions } from '../data-access/actions';
import { EmbeddedQrCodeInfo } from '../data-access/states';

const QRCodeImage = () => {
  const { embeddedQrCodeInfo, currentPage } = useSelector((state: CombinedState) => state['qrCode']);
  const dispatch = useDispatch();
  const [isQrCode, setIsQrCode] = useState(false);
  const [rectangles, setRectangles] = useState<any[]>([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [selectedId, selectShape] = useState<any>(null);

  useEffect(() => {
    const embeddedQrCodeCurrentInfo: EmbeddedQrCodeInfo = embeddedQrCodeInfo.find(
      (item: EmbeddedQrCodeInfo) => item.pageNumber === currentPage,
    );
    if (embeddedQrCodeCurrentInfo) {
      const currentRectangles = [
        {
          x: embeddedQrCodeCurrentInfo.xPosition,
          y: embeddedQrCodeCurrentInfo.yPosition,
          width: embeddedQrCodeCurrentInfo.qrCodeWidth,
          height: embeddedQrCodeCurrentInfo.qrCodeHeight,
          pdfWidth: embeddedQrCodeCurrentInfo.pdfWidthRender,
          pdfHeight: embeddedQrCodeCurrentInfo.pdfHeightRender,
          id: 'rect1',
        },
      ];
      setRectangles(currentRectangles);
      setWidth(embeddedQrCodeCurrentInfo.pdfWidthRender);
      setHeight(embeddedQrCodeCurrentInfo.pdfHeightRender);
      setIsQrCode(embeddedQrCodeCurrentInfo.isQrCode);
    }
  }, [currentPage, embeddedQrCodeInfo]);

  return (
    <div className='qr-container'>
      {isQrCode && (
        <Stage
          width={width}
          height={height}
          onMouseDown={(e: any) => {
            // deselect when clicked on empty area
            const clickedOnEmpty = e.target === e.target.getStage();
            if (clickedOnEmpty) {
              selectShape(null);
            }
          }}
          className='konvajs-content-custom'
        >
          <Layer>
            {rectangles.map((rect, i) => {
              return (
                <Rectangle
                  key={i}
                  shapeProps={rect}
                  isSelected={rect.id === selectedId}
                  onSelect={() => {
                    selectShape(rect.id);
                  }}
                  onChange={(newAttrs: any) => {
                    const newAttrsValidated = {
                      ...newAttrs,
                      x: validatePosition(newAttrs.pdfWidth, newAttrs.width, newAttrs.x),
                      y: validatePosition(newAttrs.pdfHeight, newAttrs.height, newAttrs.y),
                    };
                    const rects = rectangles.slice();
                    rects[i] = newAttrsValidated;
                    setRectangles(rects);
                    dispatch(qrCodeActions.setQrCodeSize(newAttrsValidated, currentPage));
                  }}
                />
              );
            })}
          </Layer>
        </Stage>
      )}
    </div>
  );
};

export default QRCodeImage;
