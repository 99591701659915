import { Explicit, Menu } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/system';
import React, { useState } from 'react';
import { NavItem } from './nav-item';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundImage: 'linear-gradient(90deg, rgba(0, 91, 172, 1) 80%, rgba(83, 195, 241, 1))',
  },
  toolbar: {
    minHeight: '50px',
  },
  mobileToolbar: {
    minHeight: '50px',
    flexDirection: 'column',
  },
  flexGrow1: {
    flexGrow: 1,
  },
  logo: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.25em',
    fontSize: '1.25rem',
    color: 'inherit',
    textDecoration: 'none',
  },
  logoutBtn: {
    color: '#f8f9fa',
    borderColor: '#f8f9fa',
    '&:hover': {
      color: '#000000',
      backgroundColor: '#f8f9fa',
    },
  },
  logoutTile: {
    backgroundColor: (theme.palette.common as any).color.primary,
    color: '#fff',
  },
  buttonOutlined: {
    '&:active, &:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: (theme.palette.common as any).color.outlined,
    },
  },
  noButton: {
    color: '#6c757d',
    borderColor: '#6c757d',
    '&:active, &:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#6c757d',
      borderColor: '#6c757d',
    },
  },
}));

interface Props {
  children: React.ReactElement;
}

interface UserProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // user: any;
}

function ElevationScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const NormalToolbar = (props: any) => {
  const classes = useStyles();
  const { goBack, menuList } = props;

  return (
    <Toolbar className={classes.toolbar}>
      <Typography className={classes.logo}>
        LSC-EC
        <Explicit />
      </Typography>

      <Stack direction='row' columnGap={3} sx={{ ml: 12 }}>
        {menuList.map((menu: any) => (
          <NavItem key={menu.identifyKey} {...menu} isMobile={false} />
        ))}
      </Stack>
      <Box className={classes.flexGrow1} />
      <Button variant='outlined' size='small' className={classes.logoutBtn} onClick={goBack}>
        ECVO に戻る
      </Button>
    </Toolbar>
  );
};

const MobileToolbar = (props: any) => {
  const classes = useStyles();
  const { goBack, menuList } = props;
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  return (
    <Toolbar className={classes.mobileToolbar}>
      <Stack width='100%' direction='row' justifyContent='space-between'>
        <Typography className={classes.logo}>
          LSC-EC
          <Explicit />
        </Typography>

        <IconButton size='large' edge='end' color='inherit' onClick={() => setOpenMenu(!openMenu)}>
          <Menu />
        </IconButton>
      </Stack>

      {openMenu && (
        <Stack width='100%' direction='column' alignItems='flex-start' ml={12} pb={3}>
          {menuList.map((menu: any) => (
            <NavItem key={menu.identifyKey} {...menu} isMobile={true} />
          ))}
          <Button sx={{ marginTop: 1 }} variant='outlined' size='small' className={classes.logoutBtn} onClick={goBack}>
            ECVO に戻る
          </Button>
        </Stack>
      )}
    </Toolbar>
  );
};

export function NavBar(props: UserProps) {
  const menuList: never[] = [];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleGoBack = () => {
    setOpen(true);
  };

  const goBack = () => {
    window.location.href = `${process.env['REACT_APP_ECVO_MANAGEMENT_URL']}`;
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ElevationScroll>
      <AppBar elevation={0} position='fixed' className={classes.appBar}>
        {matches ? (
          <MobileToolbar menuList={menuList} goBack={handleGoBack} />
        ) : (
          <NormalToolbar menuList={menuList} goBack={handleGoBack} />
        )}
        <Dialog
          fullWidth={true}
          maxWidth='sm'
          open={open}
          onClose={onClose}
          sx={{
            '.MuiPaper-root': {
              top: 0,
              position: 'absolute',
            },
          }}
        >
          <DialogTitle>ECVO に戻る</DialogTitle>
          <DialogContent dividers>
            <Typography>資料アップロード画面から管理画面に戻りますか？</Typography>
            <Typography>保存されていないデータは破棄されますのでご注意ください。</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' classes={{ outlined: classes.noButton }} onClick={onClose}>
              いいえ
            </Button>
            <Button variant='outlined' classes={{ outlined: classes.buttonOutlined }} onClick={goBack}>
              はい
            </Button>
          </DialogActions>
        </Dialog>
      </AppBar>
    </ElevationScroll>
  );
}

export default NavBar;
