import { IconButton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topButton: {
      height: 50,
      width: 50,
      position: 'fixed',
      right: 30,
      bottom: 30,
      background: theme.palette.common.white,
      border: 'solid 2px rgba(0, 91, 172, 1)',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,
      color: (theme.palette.common as any).color.primary,
    },
    icon: {
      height: 10,
      width: 10,
      borderTop: '3px solid rgba(0, 91, 172, 1)',
      borderRight: '3px solid rgba(0, 91, 172, 1)',
      transform: 'translateY(20%) rotate(-45deg)',
    },
  }),
);

function TopButtonComponent() {
  const classes = useStyles();
  const [scrollTop, setScrollTop] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 340) {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
    });
  }, []);

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return scrollTop ? (
    <IconButton onClick={onTop} className={classes.topButton}>
      <Typography component='span' className={classes.icon}></Typography>
    </IconButton>
  ) : (
    <></>
  );
}

export const TopButtonControl = React.memo(TopButtonComponent);
