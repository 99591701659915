import * as React from 'react';
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

export function CheckboxGroupItem(props: any) {
  const { rows, onChange } = props;
  const handleChange = (checked: boolean, item: any) => {
    onChange(item, checked);
  };

  return (
    <Grid item xs={12}>
      <Grid container alignItems='center' spacing={3}>
        {rows.map((item: any) => (
          <Grid key={item.id} item xs={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event: any) => handleChange(event.target.checked, item)}
                    checked={item.checked}
                  />
                }
                label={item.label}
              />
            </FormGroup>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

interface CheckboxGroupProps {
  onChange: (options: any) => void;
  options: { id: string; label: string; checked: boolean; value: number }[];
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { onChange, options } = props;
  const [checkItemList, setCheckItemList] = React.useState(options);

  const onChangeItem = (item: any, checked: boolean) => {
    const checkItemIndex = checkItemList.findIndex((x: any) => x.id === item.id);
    checkItemList[checkItemIndex].checked = checked;
    setCheckItemList([...checkItemList]);
    onChange(options);
  };

  return (
    <Grid px={1} container>
      {(() => {
        const list = [];
        for (let index = 0; index < Math.ceil(checkItemList.length / 2); index++) {
          list.push(
            <CheckboxGroupItem
              key={index}
              rows={checkItemList.slice(index * 2, (index + 1) * 2)}
              onChange={onChangeItem}
            />,
          );
        }
        return list;
      })()}
    </Grid>
  );
};

export const CheckboxGroupControl = React.memo(CheckboxGroup);
