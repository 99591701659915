import { Grid, Typography, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { ClipboardPlusIcon } from '../../../common';
import { ListComponent } from '../component';

const useStyles = makeStyles(() =>
  createStyles({
    textHeader: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
  }),
);

const QrDocumentComponent = () => {
  const classes = useStyles();

  return (
    <Grid container paddingX={2} spacing={3}>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' mt={4} mb={1}>
          <ClipboardPlusIcon fontSize='small' viewBox={'0 0 16 16'} />
          <Typography ml={1} variant='h2' className={classes.textHeader}>
            アップロード資料一覧
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ListComponent></ListComponent>
      </Grid>
    </Grid>
  );
};

export const QrDocumentPage = React.memo(QrDocumentComponent);
