import {
  Button,
  CardActions,
  InputBaseProps,
  Theme,
  Box,
  Card,
  CardContent,
  Typography,
  CardHeader,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonOutlined: {
      marginBottom: '0.5rem',
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    cardHeader: {
      color: (theme.palette.common as any).color.textPrimary,
      backgroundColor: 'rgba(0,0,0,.03)',
      borderBottom: '1px solid rgba(0,0,0,.125)',
      borderRadius: 'calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0',
      padding: '0.5rem 1rem',
      textAlign: 'center',
      boxSizing: 'border-box',
    },
    cardTitle: {
      fontWeight: 700,
      fontSize: '120%',
    },
    card: {
      border: '1px solid rgba(0,0,0,.125)',
      boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)',
    },
    cardAction: {
      justifyContent: 'flex-end',
    },
    contentCard: {
      whiteSpace: 'pre-line',
      color: `${(theme.palette.common as any).color.textPrimary}`,
      fontSize: '80%',
      fontFamily: 'Sans-Serif',
      lineHeight: 1.5,
    },
  }),
);

interface CustomCardProps {
  headerText?: string;
  content?: string;
  buttons?: { label: string; event?: any; key: number }[];
}

export const CardDashboard = React.memo((props: InputBaseProps & CustomCardProps) => {
  const classes = useStyles();
  const { headerText, content, buttons } = props;

  return (
    <Card className={classes.card}>
      <CardHeader title={headerText} classes={{ root: classes.cardHeader, title: classes.cardTitle }} />
      <CardContent>
        <Typography className={classes.contentCard} variant='body2' color='text.secondary'>
          {content?.split('<br/>').join('\n')}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Box display='flex' flexDirection='column'>
          {buttons?.map((button: any) => (
            <Button
              onClick={() => button.event(button.key)}
              key={button.key}
              variant='outlined'
              classes={{ outlined: classes.buttonOutlined }}
              size='small'
            >
              {button.label}
            </Button>
          ))}
        </Box>
      </CardActions>
    </Card>
  );
});
