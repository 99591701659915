import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { CombinedState } from '../../core';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    footer: {
      height: '52px',
      backgroundColor: '#f8f9fa',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    textCopyRight: {
      fontSize: '11pt',
      color: '#212529',
    },
    textVersion: {
      fontSize: '50%',
      color: '#212529',
      position: 'absolute',
      right: '15%',
      marginTop: '4pt',
    },
  }),
);

export const FooterComponent = () => {
  const classes = useStyles();
  const { version } = useSelector((state: CombinedState) => state['notifications']['settingInfo']);
  return (
    <Box className={classes.footer}>
      <Typography className={classes.textCopyRight} component='div'>
        © 2023 Kyoritsu Seiyaku Corporation All Rights Reserved.
      </Typography>
      <Typography className={classes.textVersion} component='div'>
        Version {version}
      </Typography>
    </Box>
  );
};
