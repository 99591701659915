import { getLogs } from '../../../common';
import {
  ActionUnion,
  createAction,
  FUNCTION_CODE,
  getApiError,
  LogInfo,
  SearchCondition,
  ThunkAction,
} from '../../../core';
import { importFile } from '../service/services';

export enum ImportFilesActionTypes {
  GET_LOGS = 'IMPORT_FILES_GET_LOGS',
  GET_LOGS_SUCCESS = 'IMPORT_FILES_GET_LOGS_SUCCESS',
  GET_LOGS_FAILURE = 'IMPORT_FILES_GET_LOGS_FAILURE',

  IMPORT_BUSINESS_CALENDAR = 'IMPORT_FILES_IMPORT_BUSINESS_CALENDAR',
  IMPORT_BUSINESS_CALENDAR_SUCCESS = 'IMPORT_FILES_IMPORT_BUSINESS_CALENDAR_SUCCESS',
  IMPORT_BUSINESS_CALENDAR_FAILURE = 'IMPORT_FILES_IMPORT_BUSINESS_CALENDAR_FAILURE',

  IMPORT_PRODUCT = 'IMPORT_FILES_IMPORT_PRODUCT',
  IMPORT_PRODUCT_SUCCESS = 'IMPORT_FILES_IMPORT_PRODUCT_SUCCESS',
  IMPORT_PRODUCT_FAILURE = 'IMPORT_FILES_IMPORT_PRODUCT_FAILURE',

  IMPORT_INVENTORY = 'IMPORT_FILES_DATA_INVENTORY',
  IMPORT_INVENTORY_SUCCESS = 'IMPORT_FILES_DATA_INVENTORY_SUCCESS',
  IMPORT_INVENTORY_FAILURE = 'IMPORT_FILES_DATA_INVENTORY_FAILURE',
}

export const importFilesActions = {
  getLogs: () => createAction(ImportFilesActionTypes.GET_LOGS),
  getLogsSuccess: (businessLogs: LogInfo[], inventoryLogs: LogInfo[], productLogs: LogInfo[]) =>
    createAction(ImportFilesActionTypes.GET_LOGS_SUCCESS, { businessLogs, inventoryLogs, productLogs }),
  getLogsFailure: (error: any) =>
    createAction(ImportFilesActionTypes.GET_LOGS_FAILURE, {
      error,
    }),

  importBusinessCalendar: () => createAction(ImportFilesActionTypes.IMPORT_BUSINESS_CALENDAR),
  importBusinessCalendarSuccess: (message?: string) =>
    createAction(ImportFilesActionTypes.IMPORT_BUSINESS_CALENDAR_SUCCESS, {
      message,
    }),
  importBusinessCalendarFailure: (error: any) =>
    createAction(ImportFilesActionTypes.IMPORT_BUSINESS_CALENDAR_FAILURE, {
      error,
    }),

  importProduct: () => createAction(ImportFilesActionTypes.IMPORT_PRODUCT),
  importProductSuccess: (message?: string) =>
    createAction(ImportFilesActionTypes.IMPORT_PRODUCT_SUCCESS, {
      message,
    }),
  importProductFailure: (error: any) =>
    createAction(ImportFilesActionTypes.IMPORT_PRODUCT_FAILURE, {
      error,
    }),

  importInventory: () => createAction(ImportFilesActionTypes.IMPORT_INVENTORY),
  importInventorySuccess: (message?: string) =>
    createAction(ImportFilesActionTypes.IMPORT_INVENTORY_SUCCESS, {
      message,
    }),
  importInventoryFailure: (error: any) =>
    createAction(ImportFilesActionTypes.IMPORT_INVENTORY_FAILURE, {
      error,
    }),
};

export type ImportFilesActions = ActionUnion<typeof importFilesActions>;

/**
 * Get all import logs
 * @returns
 */
export const getLogsAsync = (): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(importFilesActions.getLogs());
    try {
      const condition = new SearchCondition();
      const ordering = '-log_id';
      condition.filter = {
        ...condition.filter,
        page: 1,
        pageSize: 5,
        functionCode: `${FUNCTION_CODE.PRODUCT_GROUP},${FUNCTION_CODE.PRODUCT_GROUP_IMPORT},${FUNCTION_CODE.PRODUCT_GROUP_VALIDATION}`,
        ordering,
      };
      const productLogs = await getLogs(condition);

      condition.filter = {
        ...condition.filter,
        functionCode: `${FUNCTION_CODE.BUSINESS_DAY_CALENDAR},${FUNCTION_CODE.BUSINESS_DAY_CALENDAR_IMPORT},${FUNCTION_CODE.BUSINESS_DAY_CALENDAR_VALIDATION}`,
      };
      const businessLogs = await getLogs(condition);

      condition.filter = {
        ...condition.filter,
        functionCode: `${FUNCTION_CODE.INVENTORY},${FUNCTION_CODE.INVENTORY_IMPORT},${FUNCTION_CODE.INVENTORY_VALIDATION}`,
      };
      const inventoryLogs = await getLogs(condition);

      dispatch(importFilesActions.getLogsSuccess(businessLogs.results, inventoryLogs.results, productLogs.results));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(importFilesActions.getLogsFailure(errorText));
    }
  };
};

/**
 * Import business calendar
 * @returns
 */
export const importBusinessCalendarAsync = (file: File): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(importFilesActions.importBusinessCalendar());
    try {
      const response: any = await importFile('/import/business_date', file);
      dispatch(importFilesActions.importBusinessCalendarSuccess(response?.message));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(importFilesActions.importBusinessCalendarFailure(errorText));
    }
  };
};

/**
 * Import product
 * @returns
 */
export const importProductAsync = (file: File): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(importFilesActions.importProduct());
    try {
      const response: any = await importFile('/import/goods_group', file);
      dispatch(importFilesActions.importProductSuccess(response?.message));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(importFilesActions.importProductFailure(errorText));
    }
  };
};

/**
 * Import business calendar
 * @returns
 */
export const importInventoryAsync = (file: File): ThunkAction => {
  return async (dispatch) => {
    dispatch(importFilesActions.importInventory());
    try {
      const response: any = await importFile('/import/inventory', file);
      dispatch(importFilesActions.importInventorySuccess(response?.message));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(importFilesActions.importInventoryFailure(errorText));
    }
  };
};
