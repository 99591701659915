import saveAs from 'file-saver';
import { getLogs, getSetting } from '../../../common';
import {
  ActionUnion,
  AdvisorFeeRatePagination,
  createAction,
  EXPORT_REBATE_TYPE,
  FUNCTION_CODE,
  getApiError,
  getApiSuccess,
  getFileName,
  isEmpty,
  LogInfo,
  MASTER_TYPE,
  SearchCondition,
  SettingInfo,
  ThunkAction,
} from '../../../core';
import { exportFile, getAdvisorFeeRates, importFile } from '../service/services';

export enum RebateAmountActionTypes {
  GET_IMPORT_LOGS = 'REBATE_AMOUNT_GET_IMPORT_LOGS',
  GET_IMPORT_LOGS_SUCCESS = 'REBATE_AMOUNT_GET_IMPORT_LOGS_SUCCESS',
  GET_IMPORT_LOGS_FAILURE = 'REBATE_AMOUNT_GET_IMPORT_LOGS_FAILURE',

  GET_NUTRITION_LOGS = 'REBATE_AMOUNT_GET_NUTRITION_LOGS',
  GET_NUTRITION_LOGS_SUCCESS = 'REBATE_AMOUNT_GET_NUTRITION_LOGS_SUCCESS',
  GET_NUTRITION_LOGS_FAILURE = 'REBATE_AMOUNT_GET_GET_NUTRITION_LOGS_FAILURE',

  GET_NUTRITION_RATES = 'REBATE_AMOUNT_GET_NUTRITION_RATES',
  GET_NUTRITION_RATES_SUCCESS = 'REBATE_AMOUNT_GET_NUTRITION_RATES_SUCCESS',
  GET_NUTRITION_RATES_FAILURE = 'REBATE_AMOUNT_GET_NUTRITION_RATES_FAILURE',

  IMPORT_REBATE_AMOUNT = 'IMPORT_FILES_DATA_REBATE_AMOUNT',
  IMPORT_REBATE_AMOUNT_SUCCESS = 'IMPORT_FILES_DATA_REBATE_AMOUNT_SUCCESS',
  IMPORT_REBATE_AMOUNT_FAILURE = 'IMPORT_FILES_DATA_REBATE_AMOUNT_FAILURE',

  GET_MASTER_INFO = 'REBATE_AMOUNT_GET_MASTER_INFO',
  GET_MASTER_INFO_SUCCESS = 'REBATE_AMOUNT_GET_MASTER_INFO_SUCCESS',
  GET_MASTER_INFO_FAILURE = 'REBATE_AMOUNT_GET_MASTER_INFO_FAILURE',

  EXPORT_REBATE = 'REBATE_AMOUNT_EXPORT_REBATE',
  EXPORT_REBATE_SUCCESS = 'REBATE_AMOUNT_EXPORT_REBATE_SUCCESS',
  EXPORT_REBATE_FAILURE = 'REBATE_AMOUNT_EXPORT_REBATE_FAILURE',
}

export const rebateAmountActions = {
  getImportLogs: () => createAction(RebateAmountActionTypes.GET_IMPORT_LOGS),
  getImportLogsSuccess: (data: LogInfo[]) => createAction(RebateAmountActionTypes.GET_IMPORT_LOGS_SUCCESS, { data }),
  getImportLogsFailure: (error: any) =>
    createAction(RebateAmountActionTypes.GET_IMPORT_LOGS_FAILURE, {
      error,
    }),

  getNutritionLogs: () => createAction(RebateAmountActionTypes.GET_NUTRITION_LOGS),
  getNutritionLogsSuccess: (logs: LogInfo[], paymentLogs: LogInfo[]) =>
    createAction(RebateAmountActionTypes.GET_NUTRITION_LOGS_SUCCESS, { logs, paymentLogs }),
  getNutritionLogsFailure: (error: any) =>
    createAction(RebateAmountActionTypes.GET_NUTRITION_LOGS_FAILURE, {
      error,
    }),

  getNutritionRates: () => createAction(RebateAmountActionTypes.GET_NUTRITION_RATES),
  getNutritionRatesSuccess: (data: AdvisorFeeRatePagination) =>
    createAction(RebateAmountActionTypes.GET_NUTRITION_RATES_SUCCESS, { data }),
  getNutritionRatesFailure: (error: any) =>
    createAction(RebateAmountActionTypes.GET_NUTRITION_RATES_FAILURE, {
      error,
    }),

  getMasterData: () => createAction(RebateAmountActionTypes.GET_MASTER_INFO),
  getMasterDataSuccess: (masterInfo: SettingInfo) =>
    createAction(RebateAmountActionTypes.GET_MASTER_INFO_SUCCESS, { masterInfo }),
  getMasterDataFailure: (error: any) => createAction(RebateAmountActionTypes.GET_MASTER_INFO_FAILURE, { error }),

  importRebateAmount: () => createAction(RebateAmountActionTypes.IMPORT_REBATE_AMOUNT),
  importRebateAmountSuccess: (message?: string) =>
    createAction(RebateAmountActionTypes.IMPORT_REBATE_AMOUNT_SUCCESS, { message }),
  importRebateAmountFailure: (error: any) =>
    createAction(RebateAmountActionTypes.IMPORT_REBATE_AMOUNT_FAILURE, {
      error,
    }),

  exportRebate: (type: EXPORT_REBATE_TYPE) => createAction(RebateAmountActionTypes.EXPORT_REBATE, { type }),
  exportRebateSuccess: (row: number, type: EXPORT_REBATE_TYPE, message?: string) =>
    createAction(RebateAmountActionTypes.EXPORT_REBATE_SUCCESS, { row, type, message }),
  exportRebateFailure: (error: any, type: EXPORT_REBATE_TYPE) =>
    createAction(RebateAmountActionTypes.EXPORT_REBATE_FAILURE, {
      error,
      type,
    }),
};

export type RebateAmountActions = ActionUnion<typeof rebateAmountActions>;

/**
 * Get all import logs
 * @returns
 */
export const getImportLogsAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(rebateAmountActions.getImportLogs());
    try {
      const data = await getLogs(condition);
      dispatch(rebateAmountActions.getImportLogsSuccess(data.results));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(rebateAmountActions.getImportLogsFailure(errorText));
    }
  };
};

/**
 * Get all nutrition logs
 * @returns
 */
export const getNutritionLogsAsync = (): ThunkAction => {
  return async (dispatch) => {
    dispatch(rebateAmountActions.getNutritionLogs());
    try {
      const condition = new SearchCondition();
      const ordering = '-log_id';
      condition.filter = {
        ...condition.filter,
        page: 1,
        pageSize: 10,
        functionCode: `${FUNCTION_CODE.NUTRITION_GUIDANCE_FEE_OBIC7_OUTPUT}`,
        ordering,
      };
      const logs = await getLogs(condition);
      condition.filter = {
        ...condition.filter,
        page: 1,
        pageSize: 10,
        functionCode: `${FUNCTION_CODE.NUTRITION_GUIDANCE_FEE_OBIC7_IMPORT}`,
        ordering,
      };
      const paymentLogs = await getLogs(condition);
      dispatch(rebateAmountActions.getNutritionLogsSuccess(logs.results, paymentLogs.results));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(rebateAmountActions.getNutritionLogsFailure(errorText));
    }
  };
};

/**
 * Get all nutrition rates
 * @returns
 */
export const getNutritionRatesAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(rebateAmountActions.getNutritionRates());
    try {
      const data = await getAdvisorFeeRates(condition);
      dispatch(rebateAmountActions.getNutritionRatesSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(rebateAmountActions.getNutritionRatesFailure(errorText));
    }
  };
};

export const getMasterInfoAsync = (): ThunkAction => {
  return async (dispatch): Promise<any> => {
    dispatch(rebateAmountActions.getMasterData());
    try {
      const data = await getSetting(MASTER_TYPE.EX_ADVISOR_FEE_RATE);
      dispatch(rebateAmountActions.getMasterDataSuccess(data));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(rebateAmountActions.getMasterDataFailure(errorText));
    }
  };
};
/**
 * import rebate
 * @returns
 */
export const importRebateAmountAsync = (file: File): ThunkAction => {
  return async (dispatch) => {
    dispatch(rebateAmountActions.importRebateAmount());
    try {
      const response: any = await importFile(file);
      dispatch(rebateAmountActions.importRebateAmountSuccess(response?.message));
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(rebateAmountActions.importRebateAmountFailure(errorText));
    }
  };
};

/**
 * export rebate
 * @returns
 */
export const exportRebateAsync = (condition: SearchCondition): ThunkAction => {
  return async (dispatch) => {
    dispatch(rebateAmountActions.exportRebate(condition.filter.type as EXPORT_REBATE_TYPE));
    try {
      const fileResponse = await exportFile(condition);
      const warningMessage = await getApiSuccess(fileResponse);
      if (isEmpty(warningMessage)) {
        const rowNumber = fileResponse.headers['row-number'];
        const fileName = getFileName(fileResponse.headers['content-disposition']);
        const blob = new Blob([fileResponse.data]);
        saveAs(blob, fileName);
        dispatch(rebateAmountActions.exportRebateSuccess(rowNumber, condition.filter.type as EXPORT_REBATE_TYPE));
      } else {
        dispatch( rebateAmountActions.exportRebateSuccess(0, condition.filter.type as EXPORT_REBATE_TYPE, warningMessage));
      }
    } catch (error) {
      const errorText = await getApiError(error);
      dispatch(rebateAmountActions.exportRebateFailure(errorText, condition.filter.type as EXPORT_REBATE_TYPE));
    }
  };
};
