import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { CombinedState, AdvisorFeeRate, SORT_TYPE, isEmpty, numberWithCommas } from '../../../../core';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { SortIcon } from '../../../../common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRoot: {
      width: '50%',
      minWidth: '50em',
      maxWidth: '80em',
      marginBottom: '1rem',
      tableLayout: 'fixed',
    },
    container: {
      minHeight: '15rem',
    },
    sortIcon: {
      color: (theme.palette.common as any).color.primary,
    },
  }),
);

interface ResultTableProps {
  onSort: (sortItem: string) => void;
  ordering: string;
}

export const ResultTableComponent = (props: ResultTableProps) => {
  const classes = useStyles();
  const { onSort, ordering } = props;
  const { nutritionRates } = useSelector((state: CombinedState) => state['rebateAmount']);
  const [sortRateType, setSortRateType] = useState<string>(SORT_TYPE.ASCENDING);
  const [sortProductGroupType, setSortProductGroupType] = useState<string>(SORT_TYPE.ASCENDING);

  const handleSort = (columnType: string) => {
    let sortType = '';
    switch (columnType) {
      case 'advisor_fee_rate':
        sortType = sortRateType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        columnType = sortRateType === SORT_TYPE.ASCENDING ? `-${columnType}` : columnType;
        setSortRateType(sortType);
        break;

      case 'goods_id__ks_item_group_4__goods_group_name':
        sortType = sortProductGroupType === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
        columnType = sortProductGroupType === SORT_TYPE.ASCENDING ? `-${columnType}` : columnType;
        setSortProductGroupType(sortType);
        break;
    }
    onSort(columnType);
  };

  useEffect(() => {
    if (isEmpty(ordering)) return;
    else {
      switch (ordering) {
        case 'advisor_fee_rate':
          setSortRateType(SORT_TYPE.ASCENDING);
          break;
        case '-advisor_fee_rate':
          setSortRateType(SORT_TYPE.DESCENDING);
          break;
        case 'goods_id__ks_item_group_4__goods_group_name':
          setSortProductGroupType(SORT_TYPE.ASCENDING);
          break;
        case '-goods_id__ks_item_group_4__goods_group_name':
          setSortProductGroupType(SORT_TYPE.DESCENDING);
          break;
      }
    }
  }, [ordering]);

  return (
    <TableContainer classes={{ root: classes.container }}>
      <Table classes={{ root: classes.tableRoot }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell width='30%'>メーカー</TableCell>
            <TableCell>
              <Box display='flex' justifyContent='flex-start' alignItems='center'>
                <Typography className='lsc-table-header' component='span'>
                  商品体系４
                </Typography>
                <IconButton
                  className={classes.sortIcon}
                  onClick={() => handleSort('goods_id__ks_item_group_4__goods_group_name')}
                >
                  <SortIcon color='inherit' type={sortProductGroupType} viewBox={'0 0 16 16'} />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell width='15%'>
              <Box display='flex' justifyContent='flex-end' alignItems='center'>
                <Typography className='lsc-table-header' component='span'>
                  料率
                </Typography>
                <IconButton className={classes.sortIcon} onClick={() => handleSort('advisor_fee_rate')}>
                  <SortIcon color='inherit' type={sortRateType} viewBox={'0 0 16 16'} />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nutritionRates.results?.map((row: AdvisorFeeRate) => (
            <TableRow hover key={row.advisorFeeRateSettingId}>
              <TableCell component='th' scope='row'>
                {row?.makerId ? `${row?.makerId} ${row?.makerName ?? ''}` : row?.makerName ?? ''}
              </TableCell>
              <TableCell>
                {row?.goodsGroupCode ? `${row.goodsGroupCode} ${row.goodsGroupName ?? ''}` : row.goodsGroupName ?? ''}
              </TableCell>
              <TableCell align='right'>{numberWithCommas(row.advisorFeeRate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {nutritionRates.count === 0 && <Typography component='span'>検索結果のデータが表示されます。</Typography>}
    </TableContainer>
  );
};
