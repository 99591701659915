import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';

interface ModalCommonProps {
  onClose: () => void;
  title: string;
  content: ReactNode;
  onOk: () => void;
  okCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonOutlined: {
    '&:active, &:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: (theme.palette.common as any).color.outlined,
    },
  },
  noButton: {
    color: '#6c757d',
    borderColor: '#6c757d',
    '&:active, &:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#6c757d',
      borderColor: '#6c757d',
    },
  },
  tile: {
    backgroundColor: (theme.palette.common as any).color.primary,
    color: '#fff',
  },
}));

function ModalComponent(props: ModalCommonProps) {
  const { title, content, onOk, okCancel, onClose } = props;
  const classes = useStyles();
  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={true}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          // top: '50%',
          position: 'absolute',
        },
      }}
    >
      <DialogTitle classes={{ root: classes.tile }}>{title}</DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button variant='outlined' classes={{ outlined: classes.noButton }} onClick={okCancel}>
          いいえ
        </Button>
        <Button variant='outlined' classes={{ outlined: classes.buttonOutlined }} onClick={onOk}>
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const ModalControl = React.memo(ModalComponent);
