import { apiService, LogInfo, LogInfoPagination, MessagePagination, SearchCondition, SettingInfo } from '../../core';

export const logout = async () => {
  return apiService.logout();
};

export const getLogs = async (condition: SearchCondition): Promise<LogInfoPagination> => {
  return await apiService.get<LogInfoPagination>('/logs', { ...condition.filter });
};

export const getSetting = async (type?: string): Promise<SettingInfo> => {
  return await apiService.get<SettingInfo>('/getMasterInfo', { type });
};

export const getMessages = async (condition: SearchCondition): Promise<MessagePagination> => {
  return await apiService.get<MessagePagination>('/messageInfos', { ...condition.filter });
};

export const deleteMessage = async (messageId: string): Promise<MessagePagination> => {
  return await apiService.delete<any>(`/messageInfos/${messageId}`);
};

export const downloadTemplate = async (type: '1' | '2' | '3' | '4' | '5') => {
  return await apiService.downloadFile(
    '/downloadTemplate',
    { type },
    {
      responseType: 'blob',
    },
  );
};

export const downloadLogFile = async (file: any) => {
  return await apiService.downloadFile(
    '/download/log_file',
    { ...file },
    {
      responseType: 'blob',
    },
  );
};

export const createLogUserBehavior = async (logs: LogInfo): Promise<void> => {
  return await apiService.post('/logs', logs);
};
