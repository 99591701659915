import { createTheme } from '@mui/material';
import { jaJP } from '@mui/material/locale';

export default createTheme(
  {
    palette: {
      common: {
        color: {
          primary: '#0d6efd',
          secondary: '#dee2e6',
          active: '#495057',
          gray: '#e9ecef',
          borderGray: '#ced4da',
          textPrimary: '#212529',
          hover: '#0a58ca',
          outlined: '#0d6efd',
          outlineSecondary: '#6c757d',
          textError: '#f44336',
          textDanger: '#dc3545',
          backgroundCyan: '#e7f1ff',
          borderCyan: '#86b7fe',
          textBlue: '#0c63e4',
        },
      } as any,
    },
    typography: {
      fontFamily: 'inherit',
    },
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: {
            // backgroundColor: '#00000080',
            // opacity: '.47 !important'
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            wordBreak: 'break-word',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent !important',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: '#FFF',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            '&::before, &::after': {
              borderColor: '#000000',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            marginRight: 0,
            textAlign: 'center',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            fontFamily: 'inherit',
          },
        },
        variants: [
          {
            props: {
              variant: 'contained',
              color: 'primary',
            },
            style: {
              color: '#FFFFFF',
              backgroundColor: '#0d6efd',
              borderColor: '#0d6efd',
            },
          },
          {
            props: {
              variant: 'contained',
              color: 'primary',
              size: 'large',
            },
            style: {
              fontSize: '1.25rem',
            },
          },
          {
            props: {
              variant: 'outlined',
              color: 'primary',
            },
            style: {
              color: '#0d6efd',
              borderColor: '#0d6efd',
            },
          },
        ],
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            borderColor: '#212529',
            fontWeight: 600,
            padding: '0.5rem',
            '& .lsc-table-header': {
              fontWeight: 600,
              fontSize: 'inherit',
            },
          },
          root: {
            color: '#212529',
            padding: '0.5rem',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            lineHeight: '1em',
            '& .lsc-table-icon': {
              '& svg': {
                fontSize: 'inherit',
              },
              '& .MuiTypography-root': {
                fontSize: 'inherit',
                lineHeight: '1em',
              },
            },
            '& svg': {
              fontSize: 'inherit',
            },
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1150,
        lg: 1280,
        xl: 1920,
      },
    },
  },
  jaJP,
);
