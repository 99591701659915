import {
  Button,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  DialogActions,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import PDFEdit from './pdf-edit.component';
import QRCodeImage from './qr-code-image.component';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from '../../../core';
import Pagination from '@mui/material/Pagination';
import { useState } from 'react';
import { qrCodeActions } from '../data-access/actions';
import { ModalControl, QrIcon } from '../../../common';
import { FileMinusIcon } from '../../../common/icons/file-minus-icon.component';
import { EmbeddedQrCodeInfo } from '../data-access/states';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationItem: {
      '& .MuiPaginationItem-root': {
        borderColor: (theme.palette.common as any).color.secondary,
        color: `${(theme.palette.common as any).color.primary}`,
        backgroundColor: theme.palette.primary.contrastText,
        borderLeftWidth: 0,
        borderRadius: 0,
        margin: 0,
        '&.Mui-selected': {
          backgroundColor: `#0d6efd`,
          color: 'white',
          '&:hover': {
            zIndex: 2,
            color: (theme.palette.common as any).color.hover,
            borderColor: (theme.palette.common as any).color.secondary,
            backgroundColor: `${(theme.palette.common as any).color.borderGray}`,
          },
        },
        '&:hover': {
          zIndex: 2,
          color: (theme.palette.common as any).color.hover,
          borderColor: (theme.palette.common as any).color.secondary,
          backgroundColor: (theme.palette.common as any).color.borderGray,
        },
      },
      '& .MuiPaginationItem-ellipsis': {
        border: `1px solid ${(theme.palette.common as any).color.secondary}`,
        height: 32,
        borderLeftWidth: 0,
        borderRadius: 0,
        '&:hover': {
          zIndex: 2,
          backgroundColor: theme.palette.primary.contrastText,
        },
      },
      '& li:first-child': {
        '& button': {
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
          borderLeftWidth: 1,
        },
      },
      '& li:last-child': {
        '& button': {
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
        },
      },
    },
  }),
);

const EditQRCodeDialog = (props: any) => {
  const classes = useStyles();
  const { isOpenDialog, file, handleCloseDialog, handleSaveDialog } = props;
  const { fetching, currentPage, embeddedQrCodeInfo } = useSelector((state: CombinedState) => state['qrCode']);
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState<number>();
  const hasQrCode = embeddedQrCodeInfo.some((item: EmbeddedQrCodeInfo) => item.isQrCode);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);

  const handlePageChange = (event: any, value: number) => {
    dispatch(qrCodeActions.setPageNumber(value));
  };

  const onGenerateQrCode = () => {
    dispatch(qrCodeActions.generateQrCode(currentPage));
  };

  const onRemoveQrCode = () => {
    dispatch(qrCodeActions.removeQrCode(currentPage));
  };

  const onBeforeCancel = () => {
    if (hasQrCode) {
      setShowCancelDialog(true);
    } else {
      handleCloseDialog();
    }
  };

  const onCancel = () => {
    setShowCancelDialog(false);
    handleCloseDialog();
  };

  return (
    <>
      <Dialog disableEscapeKeyDown={true} onClose={handleCloseDialog} open={isOpenDialog} fullScreen={true}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              QR コード位置調整
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={fetching}>
            <CircularProgress color='inherit' />
          </Backdrop>
          <PDFEdit file={file} pageNumber={currentPage} setNumPages={setNumPages} />
          <QRCodeImage />
        </DialogContent>
        <DialogActions sx={{ background: '#f8f9fa', padding: '20px 10% 20px 0' }}>
          <Stack spacing={2} alignItems='center'>
            <Pagination
              color='primary'
              variant='outlined'
              shape='rounded'
              count={numPages}
              page={currentPage}
              onChange={handlePageChange}
              classes={{ ul: classes.paginationItem }}
            />
          </Stack>
          <Button
            onClick={onGenerateQrCode}
            variant='outlined'
            sx={{ marginLeft: 5 }}
            startIcon={<QrIcon fontSize='medium' viewBox={'0 0 16 16'} />}
          >
            QR コード追加
          </Button>
          <Button
            onClick={onRemoveQrCode}
            variant='outlined'
            color='error'
            startIcon={<FileMinusIcon fontSize='medium' viewBox={'0 0 16 16'} />}
          >
            QR コード削除
          </Button>
          <Button onClick={onBeforeCancel} variant='outlined' sx={{ marginLeft: '24px !important' }}>
            キャンセル
          </Button>
          <Button onClick={handleSaveDialog} variant='contained'>
            保存
          </Button>
        </DialogActions>
      </Dialog>
      {showCancelDialog && (
        <ModalControl
          onClose={() => {
            setShowCancelDialog(false);
          }}
          onOk={() => onCancel()}
          okCancel={() => {
            setShowCancelDialog(false);
          }}
          title='確認'
          content={<Typography>キャンセルしてよろしいですか？</Typography>}
        ></ModalControl>
      )}
    </>
  );
};

export default EditQRCodeDialog;
