import { Box, Tab, Tabs } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect } from 'react';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      border: '1px solid',
      borderTop: 0,
      borderColor: (theme.palette.common as any).color.secondary,
    },
    tabFlexContainer: {
      borderBottom: `1px solid ${(theme.palette.common as any).color.secondary}`,
    },
    tabIndicator: {
      height: 0,
      display: 'none',
    },
    tabItem: {
      border: '1px solid',
      borderColor: 'transparent',
      '&.Mui-selected': {
        color: (theme.palette.common as any).color.active,
        marginBottom: -1,
        backgroundColor: theme.palette.primary.contrastText,
        borderColor: (theme.palette.common as any).color.secondary,
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem',
        borderBottomColor: 'transparent',
      },
      color: (theme.palette.common as any).color.primary,
      '&:hover': {
        borderColor: (theme.palette.common as any).color.secondary,
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem',
        borderBottomColor: 'transparent',
        isolation: 'isolate',
      },
    },
  }),
);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <Box
      className={classes.tabPanel}
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

interface TabProps {
  activeIndex: number;
  source: any;
  onChangeTab?: (tabIndex: number) => void;
}

function TabComponent(props: TabProps) {
  const classes = useStyles();
  const { activeIndex, onChangeTab } = props;
  const [value, setValue] = React.useState(activeIndex);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onChangeTab && onChangeTab(newValue);
  };

  useEffect(() => {
    setValue(activeIndex);
  }, [activeIndex]);

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        classes={{ flexContainer: classes.tabFlexContainer, indicator: classes.tabIndicator }}
        value={value}
        onChange={handleChange}
      >
        {props.source.map((x: any) => (
          <Tab classes={{ root: classes.tabItem }} key={x.tabIndex} label={x.tabLabel} />
        ))}
      </Tabs>
      {props.source.map((x: any) => (
        <TabPanel key={x.tabIndex} value={value} index={x.tabIndex}>
          {x.children}
        </TabPanel>
      ))}
    </Box>
  );
}

export const TabControl = React.memo(TabComponent);
