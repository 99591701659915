import { useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Typography,
  Backdrop,
  CircularProgress,
  Link,
  Box,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EcvoNavbar from '../../../common/nav-bar/ecvo-nav-bar';
import { PeopleIcon } from '../../../common';
import { CombinedState } from '../../../core';
import { QrAccessCsv } from '../data-access/states';
import { downloadCsvAsync, getQrAccessListCsvAsync } from '../data-access/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      minHeight: '50px',
    },
    content: {
      padding: '0 0 0 5%',
      marginTop: '80px',
    },
  }),
);

const DownloadCsvDialog = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpenDownloadCsv, closeDownloadCsv } = props;
  const { qrAccessCsv, qrAccessCsvFetching } = useSelector((state: CombinedState) => state['qrAccess']);

  useEffect(() => {
    if (isOpenDownloadCsv) {
      dispatch(getQrAccessListCsvAsync());
    }
  }, [dispatch, isOpenDownloadCsv]);

  const onDownloadCsv = (id: string) => {
    dispatch(downloadCsvAsync(id));
  }

  return (
    <>
      <Dialog disableEscapeKeyDown={true} onClose={closeDownloadCsv} open={isOpenDownloadCsv} fullScreen={true}>
        <AppBar>
          <Toolbar className={classes.header}>
            <EcvoNavbar />
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.content}>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={qrAccessCsvFetching}>
            <CircularProgress color='inherit' />
          </Backdrop>
          <Box display='flex' flexDirection='column'>
            <Grid container direction='row'>
              <Grid item xs={12} mb={1}>
                <Box display='flex' alignItems='center' mt={4}>
                  <PeopleIcon fontSize='small' viewBox={'0 0 16 16'} />
                  <Typography ml={1} variant='h6' fontWeight='500'>
                    資料集計
                  </Typography>
                </Box>
              </Grid>
              {qrAccessCsv.map((item: QrAccessCsv) => (
                <Grid item xs={12}>
                  <Link
                    component='button'
                    variant='body1'
                    key={item.qrAccessCountCsvId}
                    onClick={() => onDownloadCsv(String(item.qrAccessCountCsvId))}
                  >
                    {item.generateDatetime} 生成
                  </Link>
                </Grid>
              ))}
              <Grid item xs={12} mt={2} mb={2}>
                <Typography variant='subtitle1'>
                  生成された CSV は7日間保存されます。
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Link component='button' variant='body1' onClick={closeDownloadCsv}>
                  ＜ 前画面に戻る
                </Link>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DownloadCsvDialog;
