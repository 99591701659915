import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { PaginationControl } from '../../../common';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CombinedState, MENU_HREF, SearchCondition } from '../../../core';
import { getAccessListAsync } from '../data-access/actions';
import { HBAccessLog } from '../data-access/states';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textTitle: {
      fontSize: '1.5rem',
    },
    container: {
      minHeight: '25em',
    },
    tableRoot: {
      width: '100%',
      minWidth: '78em',
      tableLayout: 'fixed',
    },
    sortIcon: {
      color: (theme.palette.common as any).color.primary,
    },
    error: {
      color: (theme.palette.common as any).color.textError,
    },
    preview: {
      width: '100%',
      height: '100%',
    },
    newButton: {
      width: '8em',
      fontSize: '.875rem',
      backgroundColor: (theme.palette.common as any).color.primary,
      borderColor: (theme.palette.common as any).color.primary,
      '&:hover': {
        borderColor: (theme.palette.common as any).color.hover,
        backgroundColor: (theme.palette.common as any).color.hover,
        boxShadow: '0 0 0 0.25rem rgba(49,132,253,.5)',
      },
    },
    outlineButton: {
      fontSize: '.875rem',
      padding: '0.25rem 0.5rem',
      '&:active,&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    deleteButton: {
      marginTop: '0.5rem',
      fontSize: '.875rem',
      padding: '0.25rem 0.5rem',
      '&:active,&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    wrapPreview: {
      borderRadius: '0.25rem!important',
      borderColor: '#6c757d!important',
      border: '1px solid #dee2e6!important',
      width: '120px',
      height: '120px',
      overflow: 'hidden',
      padding: '0.5rem',
    },
    tableCell: {
      border: 'solid 1px #dee2e6',
    },
    firstTableCell: {
      border: 'solid 1px #dee2e6',
      borderBottomWidth: 0,
    },
    pagination: {
      '& ul': {
        justifyContent: 'center',
      },
    },
  }),
);

export const ListComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { qrAccessPagination } = useSelector((state: CombinedState) => state['qrAccess']);
  const ITEM_PER_PAGE = 10;
  const searchState = useMemo(() => (state as any)?.searchCondition || {}, [state]);
  const [page, setPage] = useState(1);

  const onChangePage = (page: number) => {
    setPage(page);
    const condition = new SearchCondition();
    condition.filter = { ...searchState, page: page };

    navigate(MENU_HREF.QR_ACCESS_COUNT, {
      replace: true,
      state: { ...state, searchCondition: condition.filter },
    });

    dispatch(getAccessListAsync(condition));
  };

  useEffect(() => {
    setPage(qrAccessPagination.page)
  }, [qrAccessPagination])

  return (
    <Stack spacing={2}>
      <TableContainer component={Box} classes={{ root: classes.container }}>
        {qrAccessPagination.count > 0 && (
          <Table classes={{ root: classes.tableRoot }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.firstTableCell} align='left' colSpan={2}>
                  <Typography className='lsc-table-header' component='span'>
                    担当者
                  </Typography>
                </TableCell>
                <TableCell className={classes.firstTableCell} align='left' colSpan={4}>
                  <Typography className='lsc-table-header' component='span'>
                    動物病院情報
                  </Typography>
                </TableCell>
                <TableCell className={classes.firstTableCell} align='left' colSpan={5}>
                  <Typography className='lsc-table-header' component='span'>
                    資料情報
                  </Typography>
                </TableCell>
                <TableCell className={classes.firstTableCell} align='left' colSpan={2}>
                  PO利用状況
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell} align='left'>
                  <Typography className='lsc-table-header' component='span'>
                    営業所名
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  <Typography className='lsc-table-header' component='span'>
                    担当者名
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  <Typography className='lsc-table-header' component='span'>
                    VICSコード
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  動物病院名
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  都道府県名
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  取引種別
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  資料ID
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  カテゴリ
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  資料名
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  ダウンロード状況
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  初回ダウンロード日時
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  総アクセス数
                </TableCell>
                <TableCell className={classes.tableCell} align='left'>
                  アクセス人数（概算）
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {qrAccessPagination.results?.map((row: HBAccessLog, index: number) => (
                <TableRow hover key={index}>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.postName}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.staffsName}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.vicsCode}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.hospitalName}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.address1Code}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.reserveFlag2}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row.hbId}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.hbCategoryName}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.title}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.isDownload}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.createdDatetime}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.accessCount}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='left'>
                    {row?.accessCountGrouped}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <Box mt={2} mb={2} className={classes.pagination}>
          {qrAccessPagination.count === 0 && <Typography>指定された条件では、検索結果がありませんでした。</Typography>}
          <PaginationControl
            totalRecord={qrAccessPagination.count}
            currentPage={page}
            itemPerPage={ITEM_PER_PAGE}
            onChange={onChangePage}
          />
        </Box>
      </TableContainer>
    </Stack>
  );
};
