import {
  Box,
  Button,
  CardActions,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLogLevel, getLogTitle, LogLevelIcon, LogMessage } from '../../../common';
import { CombinedState, LogInfo, MENU_HREF, SearchCondition } from '../../../core';
import { getLogsAsync } from '../data-access/actions';
import { CardDashboard } from './card-dashboard.component';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonOutlined: {
      '&:active, &:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: (theme.palette.common as any).color.outlined,
      },
    },
    cardHeader: {
      color: (theme.palette.common as any).color.textPrimary,
      backgroundColor: 'rgba(0,0,0,.03)',
      borderBottom: '1px solid rgba(0,0,0,.125)',
      borderRadius: 'calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0',
      padding: '0.5rem 1rem',
      textAlign: 'center',
      boxSizing: 'border-box',
    },
    cardTitle: {
      fontWeight: 700,
      fontSize: '120%',
    },
    card: {
      border: '1px solid rgba(0,0,0,.125)',
      boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)',
    },
    cardAction: {
      justifyContent: 'flex-end',
    },
    table: {
      minWidth: '55em',
      tableLayout: 'fixed',
      '& a': {
        color: (theme.palette.common as any).color.primary,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
  }),
);

const PURCHASE_LIMIT =
  '購入上限では、動物病院と商品体系４ごとに購入上限を設定可能です。\n\n 概要：\n LSC事業の中核をなすD-Line（ペットフード）は人気商品であるが病院の処方が必要な食事療法食である、販売元であるロイヤルカナンジャポン（以降、RC社）としては動物病院による処方が伴わない不正な転売を制限する立場から、動物病院あたりの購入上限を決定する対応を共立製薬側に求めている。\n「購入上限管理領域」は、上記内容を実現するために購入上限超過判断、及びLSC-ECに対して購入上限通知を行う役割を担う。';

const NUTRITION_GUIDANCE_FEE =
  '栄養指導料では、栄養指導料支払いデータの作成を行います。\n\n 概要：\n D-Line（ペットフード）品やUC（ユニ・チャーム）品をPO（ペットオーナー）が購入する際に、紹介元の動物病院からの指導があり動物病院に「栄養指導料」の名目で共立製薬より動物病院に対してインセンティブの支払がある。\n 「栄養指導料」では、サービスライン、動物病院、商品ごとに設定した栄養指導料率を元に栄養指導料を算出する役割を担う。\n 算出した栄養指導料については、LSC-EC に連携し動物病院に開示すると同時に、OBIC7 へ連携し銀行口座振込の形で動物病院向けに栄養指導料の支払いを行う。\n サービスライン：ここでは販売サイトを指す（VHD販売、KSオンライン販売、企業サイト販売など）';

const CAPTURE =
  'マスタデータや出荷実績、請求データなどの自動取り込みが設定されている取り込みを手動で任意のタイミングで実行可能です。なお、手動取り込みのタイミングによってはユーザーや連携先に影響が発生する可能性がありますので、操作については十分ご注意ください。';

export default function Dashboard() {
  const classes = useStyles();
  const { logs } = useSelector((state: CombinedState) => state['dashboard']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClick = (key: number) => {
    switch (key) {
      case 2:
        navigate(MENU_HREF.REBATE_AMOUNT);
        break;
      case 3:
        navigate(MENU_HREF.IMPORT);
        break;
      case 4:
        navigate(MENU_HREF.EXPORT);
        break;
      case 5:
        navigate(MENU_HREF.ACCESS_LOG);
        break;
      case 6:
      case 7:
        navigate(MENU_HREF.EVENT_LOG);
        break;
      default:
        navigate(MENU_HREF.PURCHASE_LIMIT);
        break;
    }
  };

  useEffect(() => {
    const condition = new SearchCondition();
    const ordering = '-log_id';
    condition.filter = { ...condition.filter, page: 1, ordering };
    dispatch(getLogsAsync(condition));
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <CardDashboard
            headerText='購入上限'
            content={PURCHASE_LIMIT}
            buttons={[{ label: '設定画面へ移動', event: onClick, key: 1 }]}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CardDashboard
            headerText='栄養指導料'
            content={NUTRITION_GUIDANCE_FEE}
            buttons={[{ label: '設定画面へ移動', event: onClick, key: 2 }]}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CardDashboard
            headerText='取り込み'
            content={CAPTURE}
            buttons={[{ label: '設定画面へ移動', event: onClick, key: 3 }]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={5}>
        <Grid item xs={6} md={4}>
          <CardDashboard
            headerText='書き出し'
            content='注文データの書き出しを行います。'
            buttons={[{ label: '設定画面へ移動', event: onClick, key: 4 }]}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CardDashboard
            headerText='設定'
            content='ログイン履歴や処理状況のログを確認できます。'
            buttons={[
              { label: 'アクセスログへ移動', event: onClick, key: 5 },
              { label: '処理状況ログへ移動', event: onClick, key: 6 },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={5}>
        <Grid item xs={12} md={8}>
          <Card className={classes.card}>
            <CardHeader classes={{ root: classes.cardHeader, title: classes.cardTitle }} title='処理状況'></CardHeader>
            <CardContent>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell width='150px'>日時</TableCell>
                      <TableCell align='left' width='30%'>
                        処理
                      </TableCell>
                      <TableCell align='left' width='150px'>
                        レベル
                      </TableCell>
                      <TableCell align='left'>概要</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs.map((row: LogInfo) => (
                      <StyledTableRow key={row.logId}>
                        <TableCell component='th' scope='row'>
                          {row.recordDatetime}
                        </TableCell>
                        <TableCell align='left'>{getLogTitle(row)}</TableCell>
                        <TableCell align='left'>
                          <Box className='lsc-table-icon' display='flex' alignItems='center'>
                            <LogLevelIcon status={row.logLevel} />
                            <Typography ml={1} component='span'>
                              {getLogLevel(row.logLevel)}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align='left'>
                          <LogMessage sender={MENU_HREF.DASHBOARD} log={row} />
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                {logs.length === 0 && <Typography mt={1}>ログの記録はありません。</Typography>}
              </TableContainer>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <Button
                variant='outlined'
                classes={{ outlined: classes.buttonOutlined }}
                onClick={() => onClick(7)}
                size='small'
              >
                確認画面へ移動
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
