import { DOWNLOAD_QR_FAILED_MSG, DOWNLOAD_QR_SUCCESS_MSG } from '../../../core/constants';
import { QrDownloadActionTypes, QrDownloadActions } from './actions';

interface QrDownloadState {
  message: string;
}

const defaultState: QrDownloadState = {
  message: '',
};

export const qrDownloadReducer = (state = defaultState, action: QrDownloadActions): QrDownloadState => {
  switch (action.type) {
    case QrDownloadActionTypes.DOWNLOAD_QR:
      return {
        ...state,
      };
    case QrDownloadActionTypes.DOWNLOAD_QR_SUCCESS:
      return {
        ...state,
        message: DOWNLOAD_QR_SUCCESS_MSG,
      };
    case QrDownloadActionTypes.DOWNLOAD_QR_FAILED:
      return {
        ...state,
        message: DOWNLOAD_QR_FAILED_MSG,
      };
    default:
      return state;
  }
};
